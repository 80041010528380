import React, { Component } from "react";
import { Button, Modal, Input, Icon } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import ReactTable, { ReactTableDefaults } from "react-table";

import Service from "./service";

import "./ExactSearch.scss";

class ExactSearch extends Component {
  state = {
    isSearchModalOpen: false,
    searchTerm: "",
    searchResults: null,
    isLoading: false,
    error: null,
  };

  handleOpenModal = () => {
    this.setState({ isSearchModalOpen: true });
  };

  handleCloseModal = () => {
    this.setState({ isSearchModalOpen: false, searchResults: null, searchTerm: "", error: null });
  };

  handleSearchTermChange = (_e, { value: searchTerm }) => {
    this.setState({ searchTerm });
  };

  handleSearch = () => {
    const { searchTerm } = this.state;

    if (!searchTerm || !searchTerm.trim()) return;

    this.setState({ isLoading: true, error: null }, async () => {
      try {
        const response = await Service.searchAccounts({ search: searchTerm, dealer_id: this.props.dealerId });
        this.setState({
          searchResults: response?.data?.data || [],
          isLoading: false,
        });
      } catch (error) {
        console.error("Error searching accounts:", error);
        this.setState({
          error: error?.response?.data?.errors?.[0] || "Failed to search accounts",
          isLoading: false,
          searchResults: [],
        });
      }
    });
  };

  linkHandler = id => {
    this.props.setExactAccountID(id);
    this.setState({ isSearchModalOpen: false });
  };

  renderSearchResultsTable = () => {
    const { searchResults, isLoading } = this.state;
    const { t } = this.props;

    if (!searchResults) return null;

    return (
      <div className="ExactSearchTable-container">
        <ReactTable
          className="ReactTable -floated-table -contained-large"
          data={searchResults}
          showPagination={false}
          showPageSizeOptions={false}
          minRows={0}
          resizable={false}
          sortable={false}
          loading={isLoading}
          noDataText={
            <div className="Table__no-results">
              <p>{t("no_accounts_found").message || "No accounts found"}</p>
            </div>
          }
          column={{
            ...ReactTableDefaults.column,
            headerClassName: "ReactTable__column-header",
            className: "ReactTable__column",
          }}
          columns={[
            {
              id: "code",
              Header: t("code").message || "Code",
              accessor: "Code",
              maxWidth: 120,
            },
            {
              id: "company",
              Header: t("exact_company_name").message || "Company (name)",
              accessor: "Name",
            },
            {
              id: "city",
              Header: t("city").message || "City",
              accessor: "City",
              maxWidth: 150,
            },
            {
              id: "address",
              Header: t("address").message || "Address",
              accessor: "AddressLine1",
            },
            {
              id: "email",
              Header: t("email").message || "Email",
              accessor: d => d.Contact?.Email || "",
            },
            {
              id: "actions",
              accessor: d => d,
              Cell: ({ value }) => (
                <div className="ExactSearch__action-cell">
                  <Button icon size="mini" onClick={() => this.linkHandler(value.ID)} title={t("link_account").message || "Link Account"}>
                    <Icon name="linkify" />
                  </Button>
                </div>
              ),
              width: 80,
            },
          ]}
        />
      </div>
    );
  };

  render() {
    const { isSearchModalOpen, searchTerm, error, isLoading } = this.state;
    const { t } = this.props;

    return (
      <div className="ExactSearch">
        <Button onClick={this.handleOpenModal} color="green">
          {t("search_account").message || "Search Account"}
        </Button>

        <Modal open={isSearchModalOpen} onClose={this.handleCloseModal} size="large" className="ExactSearch__modal">
          <Modal.Header>
            {t("search_account").message || "Search Account"}

            <Icon name="close" onClick={this.handleCloseModal} color="grey" />
          </Modal.Header>
          <Modal.Content scrolling>
            <div className="ExactSearch__search-container">
              <Input
                fluid
                placeholder={t("search_accounts_by_name_company_or_relation_id").message || "Search accounts by name, company or relation ID"}
                value={searchTerm}
                onChange={this.handleSearchTermChange}
                action={
                  <Button
                    color="green"
                    onClick={this.handleSearch}
                    disabled={isLoading || !searchTerm.trim()}
                    isLoading={isLoading}
                    content={t("search").message || "Search"}
                  />
                }
              />
            </div>

            {error && <div className="ExactSearch__error">{error}</div>}

            {this.renderSearchResultsTable()}
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

export default withTranslation()(ExactSearch);
