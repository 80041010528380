import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Checkbox, Form } from "semantic-ui-react";

import { PasswordInput, UserInput } from "../../components";

class OneiPlanningSettings extends Component {
  handleCheckboxChange = (_e, data) => this.props.onUpdateDealer({ ...this.props.dealer, [data.name]: data.checked });

  handleInputChange = (_e, data) => this.props.onUpdateDealer({ ...this.props.dealer, [data.name]: data.value });

  render() {
    const { dealer, t } = this.props;

    return (
      <Form>
        <Form.Field className="full-width-input">
          <Checkbox
            name="onei_planning_enabled"
            onChange={this.handleCheckboxChange}
            checked={dealer.onei_planning_enabled}
            label={t("onei_planning_enabled").message || "1i Planning enabled"}
          />
        </Form.Field>

        <Form.Field className="full-width-input">
          <label>{t("environment_guid").message || "Environment GUID"}</label>
          <UserInput
            name="onei_planning_environment_guid"
            onChange={this.handleInputChange}
            value={dealer.onei_planning_environment_guid}
            disabled={!dealer.onei_planning_enabled}
            placeholder={t("environment_guid").message || "Environment GUID"}
          />
        </Form.Field>

        <Form.Field className="full-width-input">
          <label>{t("onei_planning_api_key").message || "Api Key"}</label>
          <PasswordInput
            name="onei_planning_api_key"
            onChange={this.handleInputChange}
            value={dealer.onei_planning_api_key}
            disabled={!dealer.onei_planning_enabled}
            placeholder={t("api_key").message || "Api key"}
            showAsteriskPlaceholder={dealer.has_onei_planning_api_key}
          />
        </Form.Field>
      </Form>
    );
  }
}

export default withTranslation()(OneiPlanningSettings);
