import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Dropdown, Form, Header, Icon, Message, Segment } from "semantic-ui-react";
import { WARRANTY_TYPE } from "../../components/Pin/enum";

const dropdownOptionFallbackText = type => {
  return type
    .split("_")
    .map(word => word.charAt(0) + word.slice(1).toLowerCase())
    .join(" ");
};

class WarrantySettings extends Component {
  handleDropdownChange = (_e, { value }) => {
    const { onChange, dealer } = this.props;

    onChange({ ...dealer, enabled_warranty_types: value });
  };

  render() {
    const { dealer, missingField, t } = this.props;

    const dropdownOptions = Object.keys(WARRANTY_TYPE)
      .filter(type => WARRANTY_TYPE[type])
      .map(type => ({
        value: WARRANTY_TYPE[type],
        key: WARRANTY_TYPE[type],
        text: t(type.toLowerCase()).message || dropdownOptionFallbackText(type),
      }));

    return (
      <>
        <Header as="h5" className="warranty-settings-header">
          <Icon name="shield" />
          <Header.Content>{t("warranty_type_settings").message || "Warranty type settings"}</Header.Content>
        </Header>
        <Segment>
          <Form>
            <Form.Field className="full-width-input">
              <Dropdown
                placeholder={t("select_warranty_type").message || "Select warranty type"}
                selection
                fluid
                options={dropdownOptions}
                value={dealer.enabled_warranty_types}
                onChange={this.handleDropdownChange}
                multiple
              />
            </Form.Field>

            {missingField && dealer.enabled_warranty_types.length < 1 && (
              <Message negative>
                <p>{t("one_warranty_type_required_error_message").message || "At least one Warranty type option needs to be selected"}</p>
              </Message>
            )}
          </Form>
        </Segment>
      </>
    );
  }
}

export default withTranslation()(WarrantySettings);
