import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Dropdown, Popup, Icon } from "semantic-ui-react";
import ReactTable, { ReactTableDefaults } from "react-table";
import { withTranslation } from "react-i18next";
import _ from "lodash";

import { setAlert } from "../App/store";
import { SubHeader } from "./../../components";

import Service from "./service";

import "./Acl.scss";

class Acl extends Component {
  state = {
    roles: [],
    originalAclList: [],
    aclList: [],
    isLoadingRoles: false,
    selectedRole: null,
    isLoading: false,
  };

  componentDidMount() {
    this.getRoles();
    this.getAcl();
  }

  getRoles = () => {
    if (this.state.isLoadingRoles) return;

    this.setState({ isLoadingRoles: true }, () => {
      Service.getRoles()
        .then(result => {
          const roles = result?.data?.data ? result.data.data.roles : [];

          this.setState({
            roles: roles.map(r => {
              r.id = parseInt(r.id | 0, 10);
              return r;
            }),
            isLoadingRoles: false,
          });
        })
        .catch(error => {
          console.error("Error getting roles.", error);
          this.setState({ roles: [], isLoadingRoles: false });
          this.props.setAlert({
            type: "error",
            title: error.message,
          });
        });
    });
  };

  getAcl = () => {
    this.setState({ isLoading: true }, () => {
      Service.getAcl()
        .then(result => {
          const acl = result?.data?.data ? result.data.data.acls : [];
          const originalAclList = acl.map(a => {
            if (a.roles) {
              a.roles = a.roles.map(r => {
                r.id = parseInt(r.id | 0, 10);
                return r;
              });
            }
            return a;
          });

          this.setState({
            originalAclList,
            aclList: [...originalAclList],
            isLoading: false,
          });
        })
        .catch(error => {
          console.error("Error getting acl.", error);
          this.setState({ acl: [], isLoading: false });
          this.props.setAlert({
            type: "error",
            title: error.message,
          });
        });
    });
  };

  handleRoleChange = (e, { value }) => {
    const { roles, originalAclList } = this.state;
    if (value === -1) {
      this.setState({ aclList: [...originalAclList], selectedRole: null });
    } else {
      const selectedRole = roles.find(r => r.id === value);

      this.setState({ aclList: originalAclList.filter(acl => acl.roles.some(r => r.id === selectedRole.id)), selectedRole });
    }
  };

  renderAclTable = () => {
    const { aclList, roles, isLoading } = this.state;
    const { t } = this.props;

    const resourceList = _(aclList).sortBy("resource").value();

    const rolesColumns = roles.map(role => ({
      Header: <Popup trigger={<div>{t(role.name).message || role.name}</div>} content={<div>{t(role.name).message || role.name}</div>} />,
      id: role.id,
      accessor: d => (d.roles.find(r => r.id === role.id) ? <Icon color="green" name="check" /> : <Icon color="red" name="close" />),
    }));

    const tableColumns = [
      {
        Header: t("resource").message || "Resource",
        id: "resource",
        accessor: d => (
          <Popup trigger={<div className="-text-ellipsis">{t(d.resource).message || d.resource}</div>} content={<div>{t(d.resource).message || d.resource}</div>} />
        ),
        width: 160,
      },

      {
        Header: t("activity").message || "Activity",
        id: "activity",
        accessor: d => (
          <Popup trigger={<div className="-text-ellipsis">{t(d.activity).message || d.activity}</div>} content={<div>{t(d.activity).message || d.activity}</div>} />
        ),
        width: 120,
      },

      ...rolesColumns,
    ];

    if (isLoading)
      return (
        <div className="Table__loading Loader-Placeholder" style={{ minHeight: "100vh" }}>
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
          <section>{t("loading").message || "Loading"}</section>
        </div>
      );

    return (
      <ReactTable
        className="Acl-table -floated-table -contained-large"
        data={resourceList}
        pageSize={resourceList.length}
        columns={tableColumns}
        sortable={false}
        resizable={false}
        filterable={false}
        showPagination={false}
        noDataText={t("no_data").message || "No data"}
        headerStyle={{ width: "100%" }}
        column={{
          ...ReactTableDefaults.column,
          headerClassName: "ReactTable__column-header -text-ellipsis",
          className: "ReactTable__column",
        }}
      />
    );
  };

  render() {
    const { isLoadingRoles, roles, selectedRole } = this.state;
    const { t } = this.props;

    let rolesOptions = [{ text: t("all").message || "All", value: -1, key: -1 }];
    if (roles?.length) rolesOptions = rolesOptions.concat(...roles.map((a, key) => ({ text: a.name, value: a.id, key })));

    return (
      <div className="page_inner_wrapper">
        <SubHeader>
          <Grid.Column width={10}>
            <h1>{t("access_control_list").message || "Access-control list"}</h1>
          </Grid.Column>
          <Grid.Column width={6} className="acl-filter-dropdown-container">
            <div className="filter-dropdown-label">{t("filter_on_role").message || "Filter on Role"}</div>
            <Dropdown
              placeholder={t("roles").message || "Roles"}
              options={rolesOptions}
              loading={isLoadingRoles}
              selectOnBlur={false}
              selection
              value={selectedRole?.id || -1}
              onChange={this.handleRoleChange}
              className="filter-dropdown"
            />
          </Grid.Column>
        </SubHeader>
        <div style={{ marginTop: "20px" }}>{this.renderAclTable()}</div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setAlert: alertOptions => dispatch(setAlert(alertOptions)),
  };
};

export default withTranslation()(connect(null, mapDispatchToProps)(Acl));
