import React from "react";
import { Button } from "semantic-ui-react";
import ReactTable, { ReactTableDefaults } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/pro-regular-svg-icons";
import { saveAs } from "file-saver";
import moment from "moment";

const KioskOverview = ({ report, t }) => {
  if (!Array.isArray(report.report_data) || report.report_data.length < 1) {
    return (
      <>
        <h3 className="report-heading">{t(report.report_name).message || report.report_name}</h3>
        <p className="no-data">{t("no_data").message || "No data"}</p>
      </>
    );
  }

  const data = report.report_data.map(d => JSON.parse(d)).sort((a, b) => new Date(b.wo_date) - new Date(a.wo_date));

  const exportData = async () => {
    let csv = "";
    let header = (t("location").message || "Location") + ", ";
    header += (t("wo_count").message || "WO count") + ", ";
    header += (t("started").message || "Started") + ", ";
    header += (t("completed").message || "Completed") + ", ";
    header += t("remarks").message || "Remarks";

    let values = data.map(d => d.location + "," + d.wo_count + "," + d.started + "," + d.completed + "," + d.remarks).join("\n");

    csv += header + "\n" + values;
    saveAs(new File([csv], { type: "text/csv;charset=utf-8" }), "KioskOverview_" + moment().format("YYYYMMDD") + ".csv");
  };

  return (
    <>
      <h3 className="report-heading">
        {t(report.report_name).message || report.report_name}
        <Button color="green" floated="right" basic onClick={() => exportData()}>
          <span style={{ marginRight: "10px" }}>{t("export").message || "Export"}</span>
          <FontAwesomeIcon icon={faFileExport} />
        </Button>
      </h3>
      <div className="dealer-reports-table-container">
        <ReactTable
          className="ReactTable -floated-table no-overflow all-data-table -initial-margin hasLongRemark"
          data={data}
          showPagination={data?.length > 99}
          showPageSizeOptions={false}
          nextText={t("next").message || "Next"}
          previousText={t("previous").message || "Previous"}
          pageText={t("page").message || "Page"}
          ofText={t("of").message || "of"}
          sortable={false}
          minRows={0}
          onPageChange={() => document.querySelector(".ReactTable.hasLongRemark .rt-tbody").scrollTo(0, 0)}
          style={{ maxHeight: "600px" }}
          resizable={false}
          pageSize={data?.length > 99 ? 100 : data?.length || 0}
          noDataText={
            <div className="Table__no-results">
              <p>{t("no_data").message || "No data"}</p>
            </div>
          }
          column={{
            ...ReactTableDefaults.column,
            headerClassName: "ReactTable__column-header dealer-table-header",
            className: "ReactTable__column",
          }}
          columns={[
            {
              Header: <strong>{t("location").message || "Location"}</strong>,
              accessor: "location",
            },
            {
              Header: <strong>{t("wo_count").message || "WO count"}</strong>,
              accessor: "wo_count",
            },
            {
              Header: <strong>{t("started").message || "Started"}</strong>,
              accessor: "started",
            },
            {
              Header: <strong>{t("completed").message || "Completed"}</strong>,
              accessor: "completed",
            },
            {
              Header: <strong>{t("remarks").message || "Remarks"}</strong>,
              accessor: "remarks",
            },
          ]}
        />
      </div>
    </>
  );
};

export { KioskOverview };
