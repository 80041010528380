import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import moment from "moment";
import _ from "lodash";
import { Grid, Icon, Search } from "semantic-ui-react";
import Loader from "react-loader-spinner";

import { setAlert } from "../App/store.js";
import { SubHeader, UserMenuActionsPortal, SearchPortal, StatusFilter, PinTable, PinFilters, FloatingActions } from "../../components";
import { PinOptions, initStatuses } from "../../components/PinTable";
import { ROLES } from "../Users/roles.js";
import { isRealStatus } from "../Appointments/common.js";
import { MANUFACTURER_REQUEST_STATUSES } from "./request_statuses";
import NewManufacturerRequestModal from "./NewManufacturerRequestModal";

import Service from "./service";

import "./ManufacturerDashboard.css";

class ManufacturerDashboard extends React.Component {
  constructor(props) {
    super(props);

    const appStatuses = initStatuses(props.globalState?.selectedLocation?.statuses);
    const { dealerNames, locationNames } = this.getDealerAndLocationNames();

    this.state = {
      page: 0,
      nb_pages: null,
      dealer_ids: [],
      location_ids: [],
      isLoading: false,
      isLoadingManufacturerRequests: true,
      isLoadingPinOptions: false,
      searchTerm: "",
      originalItems: [],
      selectedMechanicFixed: null,
      selectedPinType: null,
      selectedWarrantyType: null,
      selectedPinStatus: null,
      selectedQuestionStatus: null,
      selectedManufacturerRequest: null,
      pinTypesOptions: [],
      warrantyTypeOptions: [],
      pinStatusOptions: [],
      items: [],
      appStatuses: appStatuses.filter(s => isRealStatus(s.identifier)),
      isNewManufacturerRequestModalVisible: false,
      dealerNames,
      locationNames,
    };
  }

  getDealerAndLocationNames = () => {
    const dealerNames = {},
      locationNames = {};

    this.props.globalState.dealers.forEach(dealer => {
      dealerNames[dealer.id] = dealer.name;

      if (dealer.locations)
        dealer.locations.forEach(location => {
          locationNames[location.id] = location.name;
        });
    });

    return {
      dealerNames,
      locationNames,
    };
  };

  componentDidMount() {
    PinOptions.getPinOptions()
      .then(result => {
        this.setState({ ...result });
      })
      .catch(error => {
        console.log("Error getting pin options", error);
        this.props.setAlert({ type: "error", title: "Cannot load pin options" });
      });
  }

  componentDidUpdate = prevProps => {
    const { globalState } = this.props;

    if (globalState.selectedLocation.id !== prevProps.globalState.selectedLocation.id) {
      this.setState({ appStatuses: initStatuses(globalState.selectedLocation.statuses) });
    } else if (prevProps.globalState.dealers !== globalState.dealers) {
      const { dealerNames, locationNames } = this.getDealerAndLocationNames();
      this.setState({ dealerNames, locationNames });
    }
  };

  getManufacturerRequests = page => {
    if (!this.props.globalState.selectedLocation?.id) return;

    if (!page) page = 1;
    else if (page === this.state.page || this.state.isLoadingManufacturerRequests) return;

    this.setState({ isLoadingManufacturerRequests: true }, () => {
      let {
        dealer_ids,
        location_ids,
        searchTerm,
        appStatuses,
        selectedPinStatus,
        selectedPinType,
        selectedWarrantyType,
        selectedQuestionStatus,
        selectedMechanicFixed,
        selectedManufacturerRequest,
      } = this.state;

      let params = {
        page: page,
        dealer_ids: dealer_ids.length ? dealer_ids : null,
        location_ids: location_ids.length ? location_ids : null,
        mechanic_fixed: selectedMechanicFixed !== "" ? selectedMechanicFixed : null,
        request_status: selectedManufacturerRequest ? selectedManufacturerRequest : null,
        pin_type_id: selectedPinType ? selectedPinType : null,
        pin_status_id: selectedPinStatus ? selectedPinStatus : null,
        warranty_type_id: selectedWarrantyType ? selectedWarrantyType : null,
        status: selectedQuestionStatus ? selectedQuestionStatus : null,
        search_term: searchTerm,
        appointment_statuses: appStatuses.filter(s => s.isActive).map(s => s.identifier),
      };

      Service.listRequests(params)
        .then(result => {
          const data = result?.data?.data ? result?.data?.data : { manufacturer_requests: [], nb_pages: 0 };
          let { manufacturer_requests, nb_pages } = data;

          manufacturer_requests.sort((a, b) => (a.updated_on > b.updated_on ? -1 : 1));

          const items = manufacturer_requests.map(manufacturerRequest => {
            manufacturerRequest.pin_history.sort((a, b) => (b.created_on > a.created_on ? 1 : -1));
            const pin = { ...manufacturerRequest.pin_history[0], history: manufacturerRequest.pin_history, manufacturer_request: manufacturerRequest };
            manufacturerRequest.pin_history = null;
            return pin;
          });

          this.setState({ items, nb_pages, page, originalItems: items, isLoadingManufacturerRequests: false });
        })
        .catch(error => {
          console.log("Error getting manufacturer requests", error);
          this.setState({ isLoadingManufacturerRequests: false });
          this.props.setAlert({ type: "error", title: "Cannot load manufacturer requests" });
        });
    });
  };

  handleRefresh = () => {
    this.getManufacturerRequests();
  };

  handleChangeMechanicFixed = selectedMechanicFixed => {
    this.setState({ selectedMechanicFixed }, () => this.getManufacturerRequests());
  };

  handleChangePinType = selectedPinType => {
    let { selectedWarrantyType, selectedPinStatus } = this.state;
    if (selectedPinType === 1) selectedWarrantyType = selectedPinStatus = null;

    this.setState({ selectedPinType, selectedWarrantyType, selectedPinStatus }, () => this.getManufacturerRequests());
  };

  handleChangeQuestionStatus = selectedQuestionStatus => {
    this.setState({ selectedQuestionStatus }, () => this.getManufacturerRequests());
  };

  handleChangeWarrantyType = selectedWarrantyType => {
    this.setState({ selectedWarrantyType }, () => this.getManufacturerRequests());
  };

  handleChangePinStatus = selectedPinStatus => {
    this.setState({ selectedPinStatus }, () => this.getManufacturerRequests());
  };

  handleChangeManufacturerRequest = selectedManufacturerRequest => {
    this.setState({ selectedManufacturerRequest }, () => this.getManufacturerRequests());
  };

  handleSearchChange = (evt, data) => {
    this.setState({ searchTerm: data.value }, () => this.getManufacturerRequests());
  };

  handleStatusClick = status => {
    const appStatuses = this.state.appStatuses.map(s => (s.id === status.id ? { ...s, isActive: !s.isActive } : s));

    this.setState({ appStatuses }, () => this.getManufacturerRequests());
  };

  handleLogChanged = updatedPinLog => {
    let { items } = this.state;

    const updatedItem = updatedPinLog[0];

    items = items.map(i => {
      if ((i.question_result_id && i.question_result_id === updatedItem.question_result_id) || (i.intervention_id && i.intervention_id === updatedItem.intervention_id)) {
        return { ...i, ...updatedItem, history: updatedPinLog };
      }

      return i;
    });

    this.setState({ items });
  };

  handlePinDeleted = groupedItem => {
    const items = this.state.items.map(i => {
      if ((i.question_result_id && i.question_result_id === groupedItem.question_result_id) || (i.intervention_id && i.intervention_id === groupedItem.intervention_id)) {
        i.history = [];
        i.pin_status_id = i.warranty_type_id = i.pin_type_id = i.updated_on = null;
        i.support_nr = i.ref_nr = i.claim_nr = i.note = "";
        if (i.question_result) i.question_result.pinned = false;
        else if (i.intervention) i.intervention.pinned = false;
      }

      return i;
    });

    this.setState({ items });
  };

  handleShowNewManufacturerRequestModal = () => {
    this.setState({ isNewManufacturerRequestModalVisible: true });
  };

  handleUpdateManufacturerRequest = (newStatus, selectedManufacturerRequestID, decline_reason) => {
    const items = this.state.items.map(item => {
      if (item.manufacturer_request.id === selectedManufacturerRequestID) {
        item.manufacturer_request.status = newStatus;
        if (newStatus === MANUFACTURER_REQUEST_STATUSES.DECLINED) item.manufacturer_request.decline_reason = decline_reason;
        else if (newStatus === MANUFACTURER_REQUEST_STATUSES.APPROVED) item.manufacturer_request.expire_on = moment().utc().format("YYYY-MM-DDTHH:mm:ssZ");
      }

      return item;
    });

    this.setState({ items });
  };

  handleNewRequestAdded = request => {
    const { dealer_ids, location_ids } = this.state;

    this.setState({ isNewManufacturerRequestModalVisible: false }, () => {
      if ((!dealer_ids.length && !location_ids.length) || dealer_ids.includes(request.dealer_id) || location_ids.includes(request.dealer_location_id)) {
        this.getManufacturerRequests();
      }
    });
  };

  handleChangeDealerLocation = ({ dealer_ids, location_ids }) => {
    this.setState({ location_ids, dealer_ids }, () => this.getManufacturerRequests());
  };

  render() {
    const {
      nb_pages,
      page,
      items,
      isLoading,
      isLoadingManufacturerRequests,
      selectedWarrantyType,
      selectedQuestionStatus,
      selectedPinStatus,
      selectedMechanicFixed,
      selectedPinType,
      selectedManufacturerRequest,
      pinTypesOptions,
      warrantyTypeOptions,
      pinStatusOptions,
      appStatuses,
      isLoadingPinOptions,
      isNewManufacturerRequestModalVisible,
      dealerNames,
      locationNames,
    } = this.state;

    const { t, globalState, authState } = this.props;

    return (
      <div className="ManufacturerDashboard">
        <SearchPortal>
          <Search
            minCharacters={4}
            className="-large-search"
            input={{
              icon: "search",
              iconPosition: "left",
              placeholder: t("search_bar_warranty_dashboard_placeholder").message || "Search on Carreg - Vin - WO - Support - Claim - ref",
            }}
            loading={false}
            showNoResults={false}
            onSearchChange={_.debounce(this.handleSearchChange, 500)}
            fluid
          />
        </SearchPortal>
        <UserMenuActionsPortal>
          <Icon name="refresh" onClick={this.handleRefresh} />
        </UserMenuActionsPortal>
        <SubHeader>
          <Grid stackable className="SubHeader_content_filters -contained-large">
            <Grid.Column width={10} className="-no-padding">
              <h1 style={{ display: "inline-block" }}>{t("manufacturer_dashboard").message || "Manufacturer Dashboard"}</h1>
            </Grid.Column>

            {appStatuses.length > 0 && (
              <Grid.Column width={6} className="-no-padding">
                <StatusFilter
                  statuses={appStatuses}
                  onStatusClick={this.handleStatusClick}
                  diagnose_overview_enabled={globalState.selectedLocation.diagnose_overview_enabled}
                  online_check_in_enabled={globalState.selectedLocation.online_check_in_enabled}
                />
              </Grid.Column>
            )}
          </Grid>

          <Grid.Column width={16}>
            <PinFilters
              filterManufacturerRequestStatuses
              selectedManufacturerRequest={selectedManufacturerRequest}
              onChangeManufacturerRequest={this.handleChangeManufacturerRequest}
              isLoadingPinOptions={isLoadingPinOptions}
              selectedPinType={selectedPinType}
              pinTypesOptions={pinTypesOptions}
              onChangePinType={this.handleChangePinType}
              selectedWarrantyType={selectedWarrantyType}
              warrantyTypeOptions={warrantyTypeOptions}
              onChangeWarrantyType={this.handleChangeWarrantyType}
              selectedMechanicFixed={selectedMechanicFixed}
              onChangeMechanicFixed={this.handleChangeMechanicFixed}
              selectedQuestionStatus={selectedQuestionStatus}
              onChangeQuestionStatus={this.handleChangeQuestionStatus}
              selectedPinStatus={selectedPinStatus}
              pinStatusOptions={pinStatusOptions}
              onChangePinStatus={this.handleChangePinStatus}
              showDealerLocationDropdown
              onChangeDealerLocationFilter={this.handleChangeDealerLocation}
              dealer={globalState.selectedDealer}
            />
          </Grid.Column>
        </SubHeader>
        <PinTable
          showRegNr
          externalLink
          page={page}
          pages={nb_pages}
          loading={isLoadingManufacturerRequests}
          onFetchData={this.getManufacturerRequests}
          onPinDeleted={this.handlePinDeleted}
          onLogChanged={this.handleLogChanged}
          warrantyTypeOptions={warrantyTypeOptions}
          pinStatusOptions={pinStatusOptions}
          pinTypesOptions={pinTypesOptions}
          questionStatus={selectedQuestionStatus}
          items={items}
          onAppointmentClick={this.handleAppointmentClick}
          onCarClick={this.handleCarClick}
          selectedPinType={selectedPinType}
          onUpdateManufacturerRequest={this.handleUpdateManufacturerRequest}
          dealerNames={dealerNames}
          locationNames={locationNames}
        />

        {(isLoading || isLoadingManufacturerRequests) && (
          <div className="Loader">
            <Loader type="Oval" color="#46923d" height="100" width="100" />
          </div>
        )}

        {authState.user.role_id === ROLES.MANUFACTURER && <FloatingActions items={[]} onRootClick={this.handleShowNewManufacturerRequestModal} />}

        {isNewManufacturerRequestModalVisible && (
          <NewManufacturerRequestModal
            dealerNames={dealerNames}
            locationNames={locationNames}
            pinStatusOptions={pinStatusOptions}
            pinTypesOptions={pinTypesOptions}
            warrantyTypeOptions={warrantyTypeOptions}
            onNewRequest={this.handleNewRequestAdded}
            onCancel={() => this.setState({ isNewManufacturerRequestModalVisible: false })}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { globalState: state.global, authState: state.auth };
};

const mapDispatchToProps = dispatch => {
  return {
    setAlert: alertOptions => dispatch(setAlert(alertOptions)),
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ManufacturerDashboard));
