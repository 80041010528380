import axios from "axios";

import ENV from "../../util/env-config";
import { getSessionCookie } from "../../util/common";

class Service {
  static requestConfig() {
    return {
      headers: {
        Authorization: `Bearer ${getSessionCookie()}`,
        Pragma: "no-cache",
      },
    };
  }

  static getSettings(requestData) {
    return axios.post(ENV.dealerBaseUrl + "/communication/settings/read", requestData, this.requestConfig());
  }

  static setSettings(requestData) {
    return axios.post(ENV.dealerBaseUrl + "/communication/settings/update", requestData, this.requestConfig());
  }

  static uploadLogo(requestData) {
    return axios.post(ENV.dealerBaseUrl + "/communication/logo/upload", requestData, this.requestConfig());
  }

  static uploadWelcomePic(requestData) {
    return axios.post(ENV.dealerBaseUrl + "/communication/welcome_pic/upload", requestData, this.requestConfig());
  }

  static uploadDeskCheckinImage(requestData) {
    return axios.post(ENV.deskComBase + "/settings/welcome_pic/upload", requestData, this.requestConfig());
  }

  static getReportTemplates(requestData) {
    return axios.post(ENV.dealerBaseUrl + "/report_templates/comm/list", requestData, this.requestConfig());
  }
}

export default Service;
