import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Button, Grid } from "semantic-ui-react";

import { ScrollGrid } from "../../components";
import { setAlert, updateDealer as globalDealerUpdate } from "../App/store";
import AppService from "../App/service";
import DealerEdit from "./DealerEdit";

import { SMS_GATEWAYS } from "../../util/common";

import "./Dealers.css";

class DealerOverview extends Component {
  state = {
    missingField: false,
    isLoading: false,
    dealer: { ...this.props.globalState.selectedDealer },
  };

  componentDidUpdate(prevProps) {
    if (prevProps.globalState.selectedDealer.id !== this.props.globalState.selectedDealer.id) {
      this.setState({ dealer: { ...this.props.globalState.selectedDealer } });
    }
  }

  updateDealerInvalidRequest = dealer => {
    if (!dealer || !dealer.name) return true;

    if (
      ![
        dealer.is_snooze_type_next_visit_customer_enabled,
        dealer.is_snooze_type_remind_customer_enabled,
        dealer.is_snooze_type_make_new_appointment_enabled,
        dealer.is_snooze_type_make_offer_enabled,
      ].some(Boolean)
    ) {
      return true;
    }

    if (!dealer.enabled_warranty_types.length) return true;

    if (dealer.planit_planning_enabled && ["planit_planning_user_name"].some(field => !dealer[field])) return true;

    switch (dealer.sms_gateway_id) {
      case SMS_GATEWAYS.CHATSHIPPER:
        return ["chatshipper_email"].some(field => !dealer[field]);

      case SMS_GATEWAYS.TWILIO:
        return ["twilio_account_sid", "twilio_api_key", "twilio_phone_number", "twilio_monthly_dollar_limit"].some(field => !dealer[field]);

      default:
        return false;
    }
  };

  handleDealerSave = dealer => {
    if (this.updateDealerInvalidRequest(dealer)) {
      this.setState({ missingField: true });
      return;
    }

    this.setState({ isLoading: true, missingField: false }, () => {
      let updatedDealer = { ...dealer };
      if (!updatedDealer.planit_planning_enabled) updatedDealer = { ...updatedDealer, planit_planning_password: "", has_planit_planning_password: false };
      if (!updatedDealer.onei_planning_enabled) updatedDealer = { ...updatedDealer, onei_planning_api_key: "", has_onei_planning_api_key: false };

      AppService.updateDealer(updatedDealer)
        .then(() => {
          this.props.globalDealerUpdate(updatedDealer);
          this.setState({ isLoading: false });
        })
        .catch(error => {
          error.response?.data?.errors?.length && this.props.setAlert({ type: "error", title: error.response.data.errors[0] });
          console.error("Error updating dealer.", error);
          this.setState({ isLoading: false });
        });
    });
  };

  handleUpdateDealer = dealer => {
    this.setState({ dealer });
  };

  renderLoading = () => {
    return (
      <div className="Table__loading Loader-Placeholder">
        <div className="bounce1" />
        <div className="bounce2" />
        <div className="bounce3" />
        <section>{this.props.t("loading").message || "Loading"}</section>
      </div>
    );
  };

  render() {
    const { dealer, isLoading, missingField } = this.state;

    return (
      <ScrollGrid
        containerClassName="DealerOverview"
        headerHeight={51}
        header={
          <Grid className="SubHeader_content_filters -no-padding -no-margin">
            <Grid.Row className="-no-padding -no-margin">
              <Grid.Column width={6} className="titleSelector">
                <h2>{this.props.t("edit_dealer").message || "Edit Dealer"}</h2>
              </Grid.Column>
              <Grid.Column width={10}>
                <Button floated="right" color="green" onClick={() => this.handleDealerSave(dealer)} loading={isLoading} disabled={isLoading}>
                  {this.props.t("save").message || "SAVE"}
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        }
      >
        <Grid className="-contained-large">
          <Grid.Row className="-no-padding -no-margin">
            <Grid.Column className="-no-padding -no-margin">
              <DealerEdit missingField={missingField} isLoading={isLoading} dealer={dealer} user={this.props.authState.user} onUpdateDealer={this.handleUpdateDealer} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </ScrollGrid>
    );
  }
}

const mapStateToProps = state => {
  return { globalState: state.global, authState: state.auth };
};

const mapDispatchToProps = dispatch => {
  return {
    setAlert: alertOptions => dispatch(setAlert(alertOptions)),
    globalDealerUpdate: dealer => dispatch(globalDealerUpdate(dealer)),
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(DealerOverview));
