import React, { Component } from "react";
import AlertContainer from "react-alert";
import { Grid, Icon } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import Service from "./service";
import WebhooksTable from "./WebhooksTable";
import WebhooksModal from "./WebhooksModal";
import WebhooksErrorLog from "./WebhooksErrorLog";
import WebhooksTestModal from "./WebhooksTestModal";
import { setAlert } from "../App/store";
import { FloatingActions, SubHeader, UserMenuActionsPortal } from "../../components";

import "./Webhooks.css";

class Webhooks extends Component {
  state = {
    locations: [],
    hooks: [],
    triggers: [],
    dealerChecklistOptions: [],
    locationsChecklistOptions: [],
    hook: {},
    testWebhookId: null,
    showWebhooksErrorLog: null,
    isTestSubmitLoading: false,
    isModalVisible: false,
    isSavingHook: false,
    isLoadingHooks: false,
    isLoadingTriggers: false,
    detailViewMode: "",
    errorMessage: "",
  };

  componentDidMount() {
    this.setLocations();
    this.getListOfTriggers();
    this.getWebhooks();
    this.loadChecklistsOptions();
  }

  componentDidUpdate(prevProps) {
    const { selectedDealer } = this.props.globalState;

    if (prevProps.globalState.selectedDealer.id !== selectedDealer.id) {
      this.setLocations();
      this.getWebhooks();
    }
  }

  setLocations = () => {
    const { globalState } = this.props;
    const locations =
      globalState.dealers.find(d => d.id === globalState.selectedDealer.id)?.locations?.map(l => ({ text: l.name, value: l.id, key: l.id, detail: l })) || [];

    this.setState({ locations });
  };

  getWebhooks = () => {
    const { selectedDealer } = this.props.globalState;

    this.setState({ isLoadingHooks: true }, () => {
      Service.getWebhooks(selectedDealer.id)
        .then(response => {
          const hooks = response?.data?.data?.webhooks || [];
          hooks.length > 0 && hooks.sort((a, b) => a.id - b.id);
          this.setState({ hooks, isLoadingHooks: false });
        })
        .catch(err => {
          this.setState({ isLoadingHooks: false });
          this.props.setAlert({ type: "error", title: err.message });
          console.error("Error getting webhooks.", err);
        });
    });
  };

  getListOfTriggers = () => {
    this.setState({ isLoadingTriggers: true }, () => {
      Service.getTriggers()
        .then(response => {
          const triggers = response?.data?.data?.webhook_triggers || [];
          this.setState({ triggers, isLoadingTriggers: false });
        })
        .catch(err => {
          this.setState({ isLoadingTriggers: false });
          this.props.setAlert({ type: "error", title: err.message });
          console.error("Error getting triggers.", err);
        });
    });
  };

  loadChecklistsOptions = async () => {
    const { id, locations } = this.props.globalState.selectedDealer;

    try {
      const dealerChecklistsPromise = Service.getDealerChecklists({ id });
      const locationChecklistsPromises = locations?.map(({ id }) => Service.getLocationsChecklists({ id }));

      const promisesList = locationChecklistsPromises ? [dealerChecklistsPromise, ...locationChecklistsPromises] : [dealerChecklistsPromise];

      const results = await Promise.all(promisesList);

      const data = results?.map(result => result?.data?.data) || [];

      const [dealerChecklistData, ...locationsChecklistData] = data;

      const dealerChecklistOptions = dealerChecklistData?.checklists?.map(({ id, name }) => ({ value: id, key: id, text: name })) || [];
      const locationsChecklistOptions = [];

      locationsChecklistData?.length &&
        locationsChecklistData.forEach(data => {
          locationsChecklistOptions[data.id] = data?.checklists?.map(({ id, name }) => ({ value: id, key: id, text: name })) || [];
        });

      this.setState({ dealerChecklistOptions, locationsChecklistOptions });
    } catch (error) {
      console.error("Error getting checklist", error);
    }
  };

  handleValidation = () => {
    const { name, webhook_trigger_id, url, headers } = this.state.hook;
    const { t } = this.props;

    let errorMessage = "";

    if (!name || !webhook_trigger_id || !url) errorMessage = t("fill_in_all_the_required_fields").message || "Fill in all the required fields.";
    else if (headers.some(h => !h.name || !h.value)) errorMessage = t("empty_headers_error").message || "Headers cannot contain empty values.";

    this.setState({ errorMessage });

    return !errorMessage;
  };

  handleSaveHook = () => {
    const { hook, hooks } = this.state;
    const { selectedDealer } = this.props.globalState;
    const {
      user: { first_name, last_name },
    } = this.props.authState;

    if (!this.handleValidation()) return;

    const hookReqData = {
      ...hook,
      checklist_id: hook.checklist_id || null,
    };

    this.setState({ isSavingHook: true }, () => {
      if (this.state.detailViewMode === "edit") {
        Service.updateWebhook(hookReqData, selectedDealer.id)
          .then(response => {
            const hookIndex = hooks.findIndex(h => h.id === hookReqData.id);
            if (hookIndex !== -1) hooks[hookIndex] = hookReqData;
            this.setState({ hooks, isSavingHook: false }, () => this.handleCloseModal());
          })
          .catch(err => {
            const error = err.response?.data?.errors?.length ? err.response.data.errors[0] : "Error Occurred";
            console.error("Error updating webhook.", error);
            this.setState({ isSavingHook: false, errorMessage: error });
          });
      } else {
        Service.createWebhook(hookReqData, selectedDealer.id)
          .then(response => {
            if (response?.data?.data.webhook_id) {
              hookReqData.id = response.data.data.webhook_id;
              hookReqData.user = { first_name, last_name };
              const updatedHooks = hooks.concat(hookReqData);
              this.setState({ hooks: updatedHooks, isSavingHook: false }, () => this.handleCloseModal());
            }
          })
          .catch(err => {
            const error = err.response?.data?.errors?.length ? err.response.data.errors[0] : "Error Occurred";
            console.error("Error creating webhook.", error);
            this.setState({ isSavingHook: false, errorMessage: error });
          });
      }
    });
  };

  handleDeleteHook = id => {
    const hooks = this.state.hooks.filter(hook => hook.id !== id);
    this.setState({ hooks });
  };

  handleHookChange = hook => {
    this.setState({ hook, errorMessage: "" });
  };

  handleShowModal = ({ detailViewMode, selectedHook }) => {
    this.setState({ isModalVisible: true, detailViewMode, hook: { ...selectedHook }, errorMessage: "" });
  };

  handleToggleErrorLog = (e, id) => {
    if (e) e.stopPropagation();
    this.setState({ showWebhooksErrorLog: id ? id : null });
  };

  handleCloseModal = () => {
    this.setState({
      isModalVisible: false,
      hook: {},
      errorMessage: "",
    });
  };

  handleOpenTestModal = (e, testWebhookId) => {
    e.stopPropagation();
    this.setState({ testWebhookId });
  };

  handleCloseTestModal = () => this.setState({ testWebhookId: null });

  handleSubmitTest = payload => {
    this.setState({ isTestSubmitLoading: true }, async () => {
      try {
        Object.keys(payload).forEach(k => {
          if (payload[k] === "") payload[k] = null;
          else if (["appointment_id", "dealer_location_id"].includes(k)) payload[k] = Number(payload[k]);
        });

        payload.dealer_id = this.props.globalState.selectedDealer.id;

        await Service.testWebhook({ webhook_id: this.state.testWebhookId, payload });

        this.setState({ testWebhookId: null, isTestSubmitLoading: false });
      } catch (err) {
        const error = err?.response?.data?.errors?.length
          ? err?.response?.data?.errors[0]
          : this.props.t("failed_error_message").message || "Something went wrong, please try again";
        this.msg.show(error, { type: "error" });
        this.setState({ isTestSubmitLoading: false });
      }
    });
  };

  renderTestErrorAlert = () => {
    return <AlertContainer ref={a => (this.msg = a)} position="top center" theme="light" time={4000} transition="fade" />;
  };

  renderHooksTable = () => {
    const { hooks, triggers, isLoadingHooks, isLoadingTriggers, locations } = this.state;
    const { user } = this.props.authState;

    if (isLoadingHooks || isLoadingTriggers) {
      return (
        <div className="Table__loading Loader-Placeholder">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
          <section>{this.props.t("loading_webhooks").message || "Loading Webhooks"}</section>
        </div>
      );
    } else {
      return (
        <WebhooksTable
          hooks={hooks}
          triggers={triggers}
          authUser={user}
          onRowClick={this.handleShowModal}
          onOpenTestModal={this.handleOpenTestModal}
          onToggleErrorLog={this.handleToggleErrorLog}
          locations={locations}
        />
      );
    }
  };

  render() {
    const {
      hook,
      triggers,
      detailViewMode,
      isModalVisible,
      isLoadingHooks,
      isLoadingTriggers,
      isSavingHook,
      errorMessage,
      locations,
      dealerChecklistOptions,
      locationsChecklistOptions,
      testWebhookId,
      showWebhooksErrorLog,
      isTestSubmitLoading,
    } = this.state;
    const { t } = this.props;

    return (
      <div>
        <UserMenuActionsPortal>
          <Icon name="refresh" onClick={this.getWebhooks} />
        </UserMenuActionsPortal>

        <SubHeader>
          <Grid.Column>
            <h1>{t("webhooks").message || "Webhooks"}</h1>
          </Grid.Column>
        </SubHeader>

        {this.renderHooksTable()}
        {isModalVisible && (
          <WebhooksModal
            hook={hook}
            triggers={triggers}
            mode={detailViewMode}
            isLoading={isLoadingHooks || isLoadingTriggers}
            isOpen={isModalVisible}
            isSaving={isSavingHook}
            errorMessage={errorMessage}
            locations={locations}
            dealerChecklistOptions={dealerChecklistOptions}
            locationsChecklistOptions={locationsChecklistOptions}
            onSave={this.handleSaveHook}
            onClose={this.handleCloseModal}
            onDelete={this.handleDeleteHook}
            onChange={this.handleHookChange}
          ></WebhooksModal>
        )}

        <WebhooksTestModal
          webhookId={testWebhookId}
          onSubmit={this.handleSubmitTest}
          onCancel={this.handleCloseTestModal}
          isLoading={isTestSubmitLoading}
          renderAlert={this.renderTestErrorAlert}
        />

        {showWebhooksErrorLog && <WebhooksErrorLog showWebhooksErrorLog={showWebhooksErrorLog} onToggleErrorLog={this.handleToggleErrorLog} />}

        <FloatingActions
          items={[]}
          onRootClick={() =>
            !isLoadingHooks &&
            !isLoadingTriggers &&
            this.handleShowModal({
              detailViewMode: "create",
              selectedHook: { name: "", url: "", headers: [], webhook_trigger_id: 0 },
            })
          }
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { globalState: state.global, authState: state.auth };
};

const mapDispatchToProps = dispatch => {
  return {
    setAlert: alertOptions => dispatch(setAlert(alertOptions)),
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Webhooks));
