import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Checkbox, Form, Header, Icon, Segment } from "semantic-ui-react";
import UserInput from "../UserInput";

class PonRecallConfiguration extends Component {
  handleCheckboxChange = (e, { name, checked }) => {
    const { location, onLocationChange } = this.props;

    onLocationChange({ ...location, [name]: checked });
  };

  handleInputChange = e => {
    const { location, onLocationChange } = this.props;

    onLocationChange({ ...location, [e.target.name]: e.target.value });
  };

  render() {
    const { t, location } = this.props;

    return (
      <Segment>
        <Header as="h5">
          <Icon name="settings" />
          <Header.Content>{t("pon_recall_configuration").message || "PON Recall Configuration'"}</Header.Content>
        </Header>
        <Segment>
          <Form>
            <Form.Field>
              <Checkbox
                checked={location.is_pon_recall_enabled}
                name="is_pon_recall_enabled"
                label={t("pon_recall_enabled").message || "PON Recall Enabled"}
                onChange={this.handleCheckboxChange}
              />
            </Form.Field>

            <Form.Field>
              <label htmlFor="pon_location_number">{t("pon_location_number").message || "pon_location_number"}</label>
              <UserInput name="pon_location_number" value={location.pon_location_number} onChange={this.handleInputChange} />
            </Form.Field>
          </Form>
        </Segment>
      </Segment>
    );
  }
}

export default withTranslation()(PonRecallConfiguration);
