import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button, Checkbox, Dropdown, Form, Icon, Modal, Segment, Label } from "semantic-ui-react";
import Service from "./service";
import "./EditQuestion.css";
import Can from "../Can";
import Element from "./Elements/Element";
import CopyQuestion from "./CopyQuestion";
import AlertContainer from "react-alert";
import { CustomConfirm, UserInput } from "../../components";

class EditQuestion extends Component {
  state = {
    question: {
      title: "",
      active: false,
      evhc_labor_percent: 0,
      evhc_part_percent: 0,
      evhc_tire_percent: 0,
      evhc_external_percent: 0,
    },
    evhcPercentValueExceededError: false,
    selectedTags: [],
    addedElements: [],
    showDeleteConfirmation: false,
    isCopyQuestionOpened: false,
    loadingQuestion: false,
    isDeleting: false,
    isCopying: false,
    deleteQuestionError: "",
  };

  componentDidMount() {
    this.getQuestion();
  }

  error = msg => this.msg.show(msg, { type: "error" });

  renderAlert = () => <AlertContainer ref={a => (this.msg = a)} {...this.props.alertOptions} />;

  getQuestion = () => {
    this.setState({ loadingQuestion: true }, () => {
      Service.getQuestion(this.props.questionID)
        .then(result => {
          if (result && result.data && result.data.data) {
            let question = result.data.data.question;
            let addedElements = [];
            if (question.question_elements && question.question_elements.length > 0)
              addedElements = question.question_elements.sort((a, b) => (a.order > b.order ? 1 : a.order < b.order ? -1 : 0));

            let selectedTags = [];
            if (question.tags && question.tags.length > 0) selectedTags = question.tags.map(item => item.id);

            this.setState({ loadingQuestion: false, question, addedElements, selectedTags });
          } else this.setState({ loadingQuestion: false });
        })
        .catch(error => {
          this.error(error.message);
          console.error("error while loading question: ", error);
          this.setState({ loadingQuestion: false });
        });
    });
  };

  handleTagsUpdate = (e, data) => {
    let question = this.state.question;
    question.tag_ids = data.value;
    this.setState({ question, selectedTags: data.value });
  };

  handleUpdateActive = (e, data) => {
    let question = this.state.question;
    question.active = data.checked;
    this.setState({ question });
  };

  handleUpdateCanDuplicate = (e, data) => {
    let question = this.state.question;
    question.can_duplicate = data.checked;
    this.setState({ question });
  };

  handleUpdateOptionFields = (e, data) => {
    const question = this.state.question;
    const { template_field, checked } = data;

    const offset = question.question_options.findIndex(qo => qo.name === template_field);
    question.question_options[offset].value = checked;
    question.question_options = [...question.question_options];

    this.setState({ question });
  };

  onDragStart = (ev, question_element) => {
    ev.dataTransfer.setData("Text", JSON.stringify(question_element));
  };

  onDragOver = ev => {
    ev.stopPropagation();
    ev.preventDefault();
  };

  onDrop = ev => {
    let { addedElements } = this.state;
    const element = JSON.parse(ev.dataTransfer.getData("Text"));

    const hasSignature = element?.template.includes("signature") && addedElements.some(element => element.template.includes("signature"));
    if (hasSignature) return;

    element.order = addedElements.length;
    addedElements = addedElements.concat(element);

    this.setState({ addedElements });
  };

  handleRemoveElement = index => {
    let addedElements = this.state.addedElements;
    addedElements.splice(index, 1);

    this.setState({ addedElements });
  };

  handleMoveUpElement = index => {
    let addedElements = this.state.addedElements;

    if (index - 1 >= 0) {
      let temp = addedElements[index - 1];
      addedElements[index - 1] = addedElements[index];
      addedElements[index] = temp;

      let counter = 0;
      /* eslint-disable array-callback-return */
      addedElements.map(item => {
        item.order = counter;
        counter++;
      });

      this.setState({ addedElements });
    }
  };

  handleMoveDownElement = index => {
    let addedElements = this.state.addedElements;

    if (addedElements.length > index + 1) {
      let temp = addedElements[index + 1];
      addedElements[index + 1] = addedElements[index];
      addedElements[index] = temp;

      let counter = 0;
      /* eslint-disable array-callback-return */
      addedElements.map(item => {
        item.order = counter;
        counter++;
      });

      this.setState({ addedElements });
    }
  };

  handleShowConfirmation = () => {
    this.setState({ showDeleteConfirmation: true });
  };

  handleDeleteQuestion = () => {
    this.setState({ isDeleting: true, showDeleteConfirmation: false, deleteQuestionError: "" }, () => {
      Service.deleteQuestion(this.state.question.id)
        .then(result => {
          this.props.delete(this.state.question.id);
        })
        .catch(error => {
          this.error("Question is still assigned to checklist");
          console.error("Error while deleting question: ", error);
          this.setState({
            isDeleting: false,
            deleteQuestionError: error.response.data.errors && error.response.data.errors.length ? error.response.data.errors[0] : "Error occurred",
          });
        });
    });
  };

  handleCopyModalOpen = () => {
    this.setState({ isCopyQuestionOpened: true });
  };

  handleCopyModalClose = () => {
    this.setState({ isCopyQuestionOpened: false });
  };

  handleCopyDealer = question => {
    this.setState({ isCopying: true }, () => {
      question.title = question.title + "( " + (this.props.t("copy").message || "Copy") + " )";
      question.dealer_id = this.props.dealerID;
      Service.storeQuestion(question)
        .then(result => {
          if (result?.data?.data?.question) {
            question.id = result.data.data.question.id;
            this.setState({ isCopying: false, isCopyQuestionOpened: false, question, addedElements: question.question_elements });
          }
        })
        .catch(error => {
          this.error(error.message);
          console.error("error while copying question: ", error);
          this.setState({ isCopying: false });
        });
    });
  };

  handleCopyDefault = question => {
    this.setState({ isCopying: true }, () => {
      question.title = question.title + "( " + (this.props.t("copy").message || "Copy") + " )";
      delete question.dealer_id;
      Service.storeQuestion(question)
        .then(result => {
          if (result?.data?.data?.question) {
            question.id = result.data.data.question.id;
            this.setState({ isCopying: false, isCopyQuestionOpened: false, question, addedElements: question.question_elements });
          }
        })
        .catch(error => {
          this.error(error.message);
          console.error("error while copying question: ", error);
          this.setState({ isCopying: false });
        });
    });
  };

  handleQuestionNameChange = e => {
    let question = this.state.question;
    question.title = e.target.value;
    this.setState({ question });
  };

  handleChangeEVHC = e => {
    let { name, value } = e.target;

    if (Number(value) < 0 || isNaN(Number(value))) return;
    if ([0, "0"].includes(this.state.question[name]) && value !== "") value = Number(value);

    this.setState({ evhcPercentValueExceededError: false }, () => {
      const { evhc_labor_percent, evhc_part_percent, evhc_tire_percent, evhc_external_percent } = this.state.question;

      const basePercentValue =
        Number(evhc_labor_percent) + Number(evhc_part_percent) + Number(evhc_tire_percent) + Number(evhc_external_percent) - this.state.question[name];
      const newPercentValue = basePercentValue + Number(value);

      if (newPercentValue > 100) {
        this.setState({ evhcPercentValueExceededError: true });
      } else {
        this.setState(({ question }) => ({ question: { ...question, [name]: value } }));
      }
    });
  };

  handleQuestionElementUpdate = (index, question_element) => {
    const { addedElements } = this.state;

    if (index > -1 && index < addedElements.length) {
      addedElements[index].default_configuration = JSON.stringify(question_element);
      this.setState({ addedElements });
    }
  };

  handleQuestionUpdate = () => {
    const { question, addedElements } = this.state;

    const numberTypeFields = ["evhc_labor_percent", "evhc_part_percent", "evhc_tire_percent", "evhc_external_percent"];
    numberTypeFields.forEach(field => (question[field] = Number(question[field])));

    question.question_elements = addedElements;
    question.tag_ids = this.state.selectedTags;

    this.props.update(question);
  };

  renderLoading = () => {
    return (
      <div className="Table__loading Loader-Placeholder">
        <div className="bounce1" />
        <div className="bounce2" />
        <div className="bounce3" />
        <section>{this.props.t("loading").message || "Loading"}</section>
      </div>
    );
  };

  render() {
    const { question, selectedTags, addedElements, isCopyQuestionOpened, loadingQuestion, isDeleting, isCopying, evhcPercentValueExceededError } = this.state;
    const { tags, questionElements, isLoading } = this.props;

    let loading = isLoading || loadingQuestion || isDeleting || isCopying;

    const tagsOptions =
      tags && tags.length > 0
        ? tags.map((a, key) => {
            return { text: a.name, value: a.id, key };
          })
        : [];

    let qElements =
      questionElements && questionElements.length > 0
        ? questionElements.map((item, key) => {
            return (
              <Segment draggable onDragStart={e => this.onDragStart(e, item)} onDragOver={e => this.onDragOver(e)} className="QuestionElement" key={key} color="blue">
                {item.name}
                <hr />
                <strong>{item.template}</strong>
              </Segment>
            );
          })
        : [];

    let droppedElements =
      addedElements && addedElements.length > 0
        ? addedElements.map((item, key) => {
            return (
              <Element
                key={key}
                index={key}
                name={item.name}
                template={item.template}
                remove_element={() => {
                  this.handleRemoveElement(key);
                }}
                move_down={() => {
                  this.handleMoveDownElement(key);
                }}
                move_up={() => {
                  this.handleMoveUpElement(key);
                }}
                default_configuration={JSON.parse(item.default_configuration)}
                handleQuestionElementUpdate={this.handleQuestionElementUpdate}
              />
            );
          })
        : [];

    let checklistUsages =
      question.checklist && question.checklist.length > 0
        ? question.checklist.map((item, key) => {
            return (
              <Label key={key} size="large" color="blue">
                {item.name}
              </Label>
            );
          })
        : [];

    return (
      <div>
        {isCopyQuestionOpened && (
          <CopyQuestion
            handleCopyDefault={() => {
              this.handleCopyDefault(question);
            }}
            handleCopyDealer={() => {
              this.handleCopyDealer(question);
            }}
            question={this.state.question}
            discard={this.handleCopyModalClose}
          />
        )}
        <CustomConfirm
          type="danger"
          isOpen={this.state.showDeleteConfirmation}
          handleCancel={() => {
            this.setState({ showDeleteConfirmation: false });
          }}
          handleConfirm={this.handleDeleteQuestion}
          confirmMsg={this.props.t("confirm_delete_message").message || "Are you sure that you want to delete this? You can't undo this action."}
          error={this.state.deleteQuestionError}
        />

        <Modal open={true} onClose={this.props.cancel} closeOnDimmerClick={false} className="EditQuestion">
          {this.props.renderAlert()}
          <Modal.Header>
            {this.props.t("edit_question").message || "Edit question"}

            <div className="-pull-right">
              <Can I="delete" the="questions">
                <Button loading={loading} disabled={loading} color="red" onClick={this.handleShowConfirmation}>
                  <Icon name="trash" size="small" className="-no-margin" />
                </Button>
              </Can>
              <Button loading={loading} disabled={loading} onClick={this.handleCopyModalOpen} color="yellow">
                <Icon name="copy" />
              </Button>
              <Button loading={loading} disabled={loading} onClick={this.props.cancel}>
                {this.props.t("discard").message || "Discard"}
              </Button>
              <Button loading={loading} disabled={loading} color="green" onClick={this.handleQuestionUpdate}>
                {this.props.t("confirm").message || "Confirm"}
              </Button>
            </div>
            {this.renderAlert()}
          </Modal.Header>
          <Modal.Content scrolling>
            {loading && this.renderLoading()}
            {!loading && (
              <Modal.Description>
                <Form>
                  <Form.Field>
                    <UserInput placeholder={this.props.t("question").message || "Question"} value={question.title} onChange={this.handleQuestionNameChange} />
                    {this.state.errorMessage}
                  </Form.Field>

                  <Form.Group widths="equal">
                    <Form.Field>
                      <Form.Field>
                        <label>{this.props.t("tags").message || "Tags"}</label>
                        <Dropdown
                          placeholder={this.props.t("tags").message || "Tags"}
                          selection
                          fluid
                          search
                          options={tagsOptions}
                          value={selectedTags}
                          multiple={true}
                          onChange={this.handleTagsUpdate}
                        />
                      </Form.Field>

                      <h5 className="-margin-bottom-5">{this.props.t("evhc_percents").message || "EVHC percents"}</h5>
                      <Form.Group width="equal">
                        <Form.Field>
                          <label>{this.props.t("labor").message || "Labor"} %</label>
                          <UserInput
                            value={question.evhc_labor_percent}
                            name="evhc_labor_percent"
                            type="number"
                            placeholder={this.props.t("evhc_labor_percent").message || "EVHC labor percent"}
                            onChange={this.handleChangeEVHC}
                          />
                        </Form.Field>

                        <Form.Field>
                          <label>{this.props.t("part").message || "Part"} %</label>
                          <UserInput
                            value={question.evhc_part_percent}
                            name="evhc_part_percent"
                            type="number"
                            placeholder={this.props.t("evhc_part_percent").message || "EVHC part percent"}
                            onChange={this.handleChangeEVHC}
                          />
                        </Form.Field>

                        <Form.Field>
                          <label>{this.props.t("tire").message || "Tire"} %</label>
                          <UserInput
                            value={question.evhc_tire_percent}
                            name="evhc_tire_percent"
                            type="number"
                            placeholder={this.props.t("evhc_tire_percent").message || "EVHC tire percent"}
                            onChange={this.handleChangeEVHC}
                          />
                        </Form.Field>

                        <Form.Field>
                          <label>{this.props.t("external").message || "External"} %</label>
                          <UserInput
                            value={question.evhc_external_percent}
                            name="evhc_external_percent"
                            type="number"
                            placeholder={this.props.t("evhc_external_percent").message || "EVHC external percent"}
                            onChange={this.handleChangeEVHC}
                          />
                        </Form.Field>
                      </Form.Group>

                      <div className="-margin-bottom-10">
                        {evhcPercentValueExceededError && (
                          <Label color="red">{this.props.t("ehvc_percent_value_exceeded").message || "The EHCV percent values must not exceed 100%"}</Label>
                        )}
                      </div>

                      <Form.Field className="QuestionSettings">
                        <label>{this.props.t("question_settings").message || "Question settings"}</label>
                        <div className="QuestionSettings__checkboxes">
                          <Checkbox
                            className="template-checkbox -margin-bottom-10 "
                            toggle
                            label={this.props.t("active").message || "Active"}
                            template_field="active"
                            checked={question.active}
                            onChange={this.handleUpdateActive}
                          />
                          <Checkbox
                            className="template-checkbox -margin-bottom-10 "
                            template_field="can_duplicate"
                            toggle
                            label={this.props.t("can_duplicate").message || "Can duplicate"}
                            checked={question.can_duplicate}
                            onChange={this.handleUpdateCanDuplicate}
                          />
                          {question.question_options &&
                            question.question_options.map(qo => {
                              return (
                                <Checkbox
                                  className="template-checkbox -margin-bottom-10"
                                  toggle
                                  key={"option" + qo.id}
                                  label={this.props.t(qo.name).message}
                                  template_field={qo.name}
                                  checked={qo.value}
                                  onChange={this.handleUpdateOptionFields}
                                />
                              );
                            })}
                        </div>
                      </Form.Field>
                    </Form.Field>

                    <Form.Field>
                      <label>{this.props.t("checklist_usages").message || "Checklist usages"}</label>
                      {checklistUsages}
                    </Form.Field>
                  </Form.Group>

                  {droppedElements}

                  <Form.Field>
                    <UserInput
                      className="drop-off-zone"
                      onDragOver={e => this.onDragOver(e)}
                      onDrop={e => {
                        this.onDrop(e);
                      }}
                      readOnly
                      value={this.props.t("drop_elements_here").message || "Drop elements here"}
                    />
                  </Form.Field>

                  <Form.Field>{qElements}</Form.Field>
                </Form>
              </Modal.Description>
            )}
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

export default withTranslation()(EditQuestion);
