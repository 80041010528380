import React, { Component, createRef } from "react";
import AlertContainer from "react-alert";
import { Button, Form, Header, Icon, Segment } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

import Service from "./service";

export const REPORT_COLUMNS = {
  WO_HANDLED: 1,
  CHECK_AANNAME: 2,
  CHECK_COMMERCIAL: 3,
  CHECK_MEDIA: 4,
  CHECK_COUNT: 5,
  STATUS_QUALITY: 6,
  CC_EXTRA_SERVICES: 7,
};

export const REPORT_COLUMN_TYPE = {
  NUMBER: 1,
};

const defaultReportThresholds = Object.values(REPORT_COLUMNS).map(dealer_report_column => ({
  dealer_report_column,
  low_value: null,
  high_value: null,
  report_column_type: REPORT_COLUMN_TYPE.NUMBER,
}));

class DealerThresholdsSettings extends Component {
  state = {
    thresholds: [],
    isLoading: false,
    validationError: false,
  };

  msg = createRef();

  componentDidMount() {
    this.getThresholds();
  }

  getThresholds = () => {
    this.setState({ isLoading: true }, () => {
      Service.listThresholds(this.props.dealer.id)
        .then(response => {
          const thresholds = defaultReportThresholds.map(defaultThreshold => {
            const threshold = response?.data?.data?.dealer_report_thresholds?.find(threshold => threshold.dealer_report_column === defaultThreshold.dealer_report_column);
            return threshold || defaultThreshold;
          });

          this.setState({ thresholds, isLoading: false });
        })
        .catch(error => {
          const errorMsg = typeof error.response?.data === "string" ? error.response?.data : error.response?.data?.errors?.[0] ?? "Failed to load thresholds";
          this.msg.show(errorMsg, { type: "error" });
          this.setState({ isLoading: false, thresholds: [] });
        });
    });
  };

  updateThresholds = () => {
    const { thresholds } = this.state;

    if (thresholds.some(threshold => threshold.high_value < threshold.low_value && threshold.high_value !== null && threshold.low_value !== null)) {
      this.setState({ validationError: true });
      return;
    }

    this.setState({ isLoading: true }, () => {
      const requestData = {
        dealer_id: this.props.dealer.id,
        dealer_report_thresholds: thresholds
          .filter(threshold => threshold.high_value !== null || threshold.low_value !== null)
          .map(threshold => ({
            ...threshold,
            dealer_id: this.props.dealer.id,
          })),
      };

      Service.updateThresholds(requestData)
        .then(() => {
          this.setState({ validationError: false, isLoading: false });
        })
        .catch(error => {
          const errorMsg = typeof error.response?.data === "string" ? error.response.data : error.response.data?.errors?.[0] ?? "Failed to update thresholds";
          this.msg.show(errorMsg, { type: "error" });
          this.setState({ validationError: true, isLoading: false });
        });
    });
  };

  handleUpdateThresholdValue = (value, name, columnId) => {
    if (value < 0 || (isNaN(value) && value !== "")) return;

    const updatedThresholds = this.state.thresholds.map(threshold => {
      if (threshold.dealer_report_column === columnId) {
        return {
          ...threshold,
          [name]: value ? Number(value) : null,
        };
      }
      return threshold;
    });

    this.setState({ thresholds: updatedThresholds, validationError: false });
  };

  renderAlert = () => {
    const props = {
      offset: 20,
      position: "top right",
      theme: "light",
      time: 10000,
      transition: "fade",
    };

    return (
      <div className="react-alert-container">
        <AlertContainer ref={a => (this.msg = a)} {...props} />
      </div>
    );
  };

  render() {
    const { thresholds, validationError, isLoading } = this.state;
    const { t } = this.props;

    return (
      <>
        {this.renderAlert()}
        {thresholds.length > 0 && (
          <Segment placeholder style={{ minHeight: "auto" }} className="DealerThresholdsSettings">
            <Header as="h5">
              <Icon name="balance scale" />
              <Header.Content>{t("default_thresholds").message || "Default Thresholds"}</Header.Content>
            </Header>
            <Segment>
              <Header as="h5">
                <Header.Content>{t("user_report").message || "User Report"}</Header.Content>
              </Header>
              <Form>
                {Object.keys(REPORT_COLUMNS).map(key => {
                  const columnId = REPORT_COLUMNS[key];
                  const threshold = thresholds?.find(threshold => threshold.dealer_report_column === columnId);
                  const hasError = validationError && threshold?.high_value < threshold?.low_value && threshold?.high_value !== null && threshold?.low_value !== null;

                  return (
                    <Form.Group key={key} className="threshold-input-group">
                      <Form.Input
                        className="threshold-input-low"
                        error={hasError}
                        value={threshold?.low_value}
                        name="low_value"
                        onChange={(_, { value, name }) => this.handleUpdateThresholdValue(value, name, columnId)}
                      />
                      <Form.Input
                        className="threshold-input-high"
                        error={hasError}
                        value={threshold?.high_value}
                        name="high_value"
                        onChange={(_, { value, name }) => this.handleUpdateThresholdValue(value, name, columnId)}
                      />
                      <label>{t(key.toLowerCase()).message || key.toLowerCase()}</label>
                    </Form.Group>
                  );
                })}

                <div className="thresholds-button-container">
                  <Button color="green" onClick={this.updateThresholds} disabled={isLoading} loading={isLoading}>
                    {t("save_thresholds").message || "Save thresholds"}
                  </Button>
                </div>
              </Form>
            </Segment>
          </Segment>
        )}
      </>
    );
  }
}

export default withTranslation()(DealerThresholdsSettings);
