import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Button, Grid, Icon, Search, Form, Checkbox } from "semantic-ui-react";

import { deselectAppointment, getAppointment, getChecks, handleUpdateAppointments, APPOINTMENTS_ACTION_TYPES } from "./../Appointments/store";
import AppointmentDetail from "./../Appointments/AppointmentDetail";
import { getCar, deselectCar, CARS_ACTION_TYPES } from "./../Cars/store";
import { setAlert } from "../App/store";
import CarDetail from "./../Cars/CarDetail";
import CustomConfirm from "../../components/CustomConfirm";
import { SearchPortal, SubHeader, UserMenuActionsPortal } from "./../../components";
import CustomersTable from "./CustomersTable.js";
import CustomerModal from "./CustomerModal";
import { getDealerAndLocationById, getLocationById, isAdmin } from "../../util/common";

import Service from "./service";

import "./Customers.css";

class Customers extends Component {
  searchTimer = null;

  state = {
    appointment: {},
    customers: [],
    customerID: null,
    customersRequestPageLocation: 0,
    customersSearchRequestOffset: 0,
    err_get_customers: "",
    hasMoreCustomers: true,
    hasSearchMoreCustomers: true,
    isAppointmentDetailVisible: false,
    isCarDetailVisible: false,
    loadingAppointment: false,
    loadingCustomerDetail: false,
    loadingCustomers: false,
    isSearchLoading: false,
    isUpdatingCustomer: false,
    searchTerm: "",
    searchResults: [],
    selectedAppointment: {},
    selectedCarInfo: {},
    selectedCustomer: {},
    deleteCustomerConfirmationVisible: false,
    deleteSearchedCustomerConfirmationVisible: false,
    customerToDelete: "",
    isSearching: false,
    deleteCustomerError: "",
    deleteSearchedCustomerError: "",
    isDeletingCustomer: false,
    isDeletingSearchedCustomer: false,
    selectedLeaseCompany: false,
    isSearchResultsOpen: false,
  };

  escapeModal = evt => {
    if (evt.keyCode === 27) {
      this.handleCloseCustomerModal();
    }
  };

  componentDidMount() {
    this.getCustomers();
    document.addEventListener("keydown", this.escapeModal, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escapeModal, false);
  }

  componentWillReceiveProps = nextProps => {
    if (this.props.appointmentsState.actionType !== nextProps.appointmentsState.actionType) {
      if (nextProps.appointmentsState.actionType === APPOINTMENTS_ACTION_TYPES.GET_APPOINTMENT_FAIL) {
        this.props.setAlert({
          type: "error",
          title: this.props.t("can_not_load_appointment").message || "Cannot load this appointment",
        });
      }

      if (nextProps.appointmentsState.actionType === APPOINTMENTS_ACTION_TYPES.GET_APPOINTMENT_SUCCESS) {
        this.setState({ isAppointmentDetailVisible: true });
      }
    }

    if (this.props.carsState.actionType !== nextProps.carsState.actionType) {
      if (nextProps.carsState.actionType === CARS_ACTION_TYPES.GET_CAR_SUCCESS) {
        if (!nextProps.carsState.selectedCar) {
          return;
        }
        this.setState({
          selectedCarInfo: {
            car: nextProps.carsState.selectedCar,
            customer: nextProps.carsState.selectedCustomer,
            appointmentHistory: nextProps.carsState.selectedCarAppointments,
          },
          isAppointmentDetailVisible: false,
          isCarDetailVisible: true,
        });
      }
    }
  };

  getCustomers = () => {
    if (this.state.loadingCustomers) {
      return;
    }

    const currentUser = this.props.authState.user;

    const REQUEST_DATA = {
      page: this.state.customersRequestPageLocation,
      dealer_id: isAdmin(currentUser.role_id) ? null : currentUser.dealer_id,
      is_lease_company: this.state.selectedLeaseCompany || null,
    };

    this.setState(
      {
        loadingCustomers: true,
      },
      () => {
        Service.getCustomers(REQUEST_DATA)
          .then(response => {
            let t;
            let o = this.state.customers;
            let n = response.data.data.items;
            let h = this.state.hasMoreCustomers;

            if (n && n.length > 0) {
              if (this.state.customersRequestPageLocation === 0) t = n;
              else t = o.concat(n);
            } else {
              t = this.state.customers;
              h = false;
            }

            this.setState({
              loadingCustomers: false,
              customers: t,
              err_get_customers: "",
              hasMoreCustomers: h,
            });
          })
          .catch(error => {
            this.setState({ loadingCustomers: false, err_get_customers: "Problem loading customers." });
          });
      }
    );
  };

  searchCustomers = () => {
    if (this.state.isSearchLoading) {
      return;
    }

    const currentUser = this.props.authState.user;

    const REQUEST_DATA = {
      term: this.state.searchTerm,
      dealer_id: isAdmin(currentUser.role_id) ? null : currentUser.dealer_id,
      page: this.state.customersSearchRequestOffset,
    };

    this.setState(
      {
        isSearchLoading: true,
      },
      () => {
        Service.searchCustomers(REQUEST_DATA)
          .then(response => {
            const res = response.data.data.items;

            let t;
            let o = this.state.searchResults;
            let n = res;
            let h = this.state.hasSearchMoreCustomers;

            if (n && n.length > 0) {
              t = o.concat(n);
            } else {
              t = this.state.searchResults;
              h = false;
            }

            let nextPage = this.state.customersSearchRequestOffset + 1;
            this.setState({ searchResults: t, customersSearchRequestOffset: nextPage, hasSearchMoreCustomers: h, isSearchLoading: false, isSearchResultsOpen: true });
          })
          .catch(error => {
            this.setState({ isSearchLoading: false });
            console.log("Error searching customers ", error);
          });
      }
    );
  };

  handleRefresh = () => {
    this.setState(
      {
        customers: [],
        customersRequestPageLocation: 0,
      },
      () => {
        this.getCustomers();
      }
    );
  };

  handleRowClick = (columnId, rowInfo) => {
    if (!rowInfo.original.id) {
      this.props.setAlert({ type: "error", title: "Problem selecting this customer" });
      return;
    }

    this.setState(
      {
        selectedCustomer: rowInfo.original,
      },
      () => {
        this.handleOpenCustomerModal(this.state.selectedCustomer.id);
      }
    );
  };

  handleOpenCustomerModal = customerID => {
    this.setState({ customerID });
  };

  handleCloseCustomerModal = () => {
    this.setState({ customerID: null });
  };

  handleRegistrationClick = carID => {
    this.props.getCar(carID);
  };

  handleCloseAppointmentDetail = () => {
    this.setState({ isAppointmentDetailVisible: false });
    this.props.deselectAppointment();
  };

  handleCloseCarDetail = () => {
    this.setState({ isCarDetailVisible: false, selectedCarInfo: {}, isAppointmentDetailVisible: true });
    this.props.deselectCar();
  };

  handleSearchChange = (event, data) => {
    this.setState(
      {
        searchTerm: data.value,
        customersSearchRequestOffset: 0,
        searchResults: [],
      },
      () => {
        const { searchTerm } = this.state;
        if (!searchTerm) {
          if (this.state.isSearchResultsOpen) {
            this.setState({ isSearchResultsOpen: false, isSearchLoading: false });
          }
          return;
        }
        if (searchTerm && searchTerm.trim().length > 3) {
          if (this.searchTimer) {
            window.clearTimeout(this.searchTimer);
            this.searchTimer = null;
          }

          this.searchTimer = window.setTimeout(() => {
            this.searchCustomers();
          }, 400);
        }
      }
    );
  };

  handleCloseSearchResults = () => {
    this.setState({ searchTerm: "", isSearchResultsOpen: false, customersSearchRequestOffset: 0 });
  };

  handleShowAppointment = appID => {
    window.open("#!/appointments/" + appID);
  };

  handleShowCar = carKey => {
    window.open("#!/appointments/car/" + carKey);
  };

  handleDeleteCustomer = () => {
    const { customerToDelete } = this.state;
    this.setState(
      {
        isDeletingCustomer: true,
        deleteCustomerError: "",
      },
      () => {
        Service.deleteCustomer(customerToDelete)
          .then(result => {
            const { customers } = this.state;
            const helpArr = customers.filter(customer => {
              return customer.id !== customerToDelete;
            });
            this.setState({
              deleteCustomerConfirmationVisible: false,
              customers: [...helpArr],
              customerToDelete: "",
              deleteCustomerError: "",
              isDeletingCustomer: false,
            });
          })
          .catch(err => {
            this.setState({
              deleteCustomerConfirmationVisible: false,
              customerToDelete: "",
              deleteCustomerError: err.response.data.errors && err.response.data.errors.length > 0 ? err.response.data.errors[0] : "Error Occurred",
              isDeletingCustomer: false,
            });
          });
      }
    );
  };

  handleDeleteSearchedCustomer = () => {
    const { customerToDelete } = this.state;
    this.setState(
      {
        isDeletingSearchedCustomer: true,
      },
      () => {
        Service.deleteCustomer(customerToDelete)
          .then(result => {
            const { searchResults, customers, customerToDelete } = this.state;
            const helpArr = searchResults.filter(customer => {
              return customer.id !== customerToDelete;
            });

            const helpArr2 = customers.filter(customer => {
              return customer.id !== customerToDelete;
            });
            this.setState({
              deleteSearchedCustomerConfirmationVisible: false,
              searchResults: [...helpArr],
              customers: [...helpArr2],
              customerToDelete: "",
              deleteSearchedCustomerError: "",
              isDeletingSearchedCustomer: false,
            });
          })
          .catch(err => {
            this.setState({
              deleteSearchedCustomerConfirmationVisible: false,
              customerToDelete: "",
              deleteSearchedCustomerError: err.response.data.errors && err.response.data.errors.length > 0 ? err.response.data.errors[0] : "Error Occurred",
              isDeletingSearchedCustomer: false,
            });
          });
      }
    );
  };

  handleShowDeleteCustomerConfirmation = (id, isSearching = false) => {
    this.setState({ deleteCustomerConfirmationVisible: true, customerToDelete: id, isSearching });
  };

  handleShowDeleteSearchedCustomerConfirmation = (id, isSearching = false) => {
    this.setState({ deleteSearchedCustomerConfirmationVisible: true, customerToDelete: id, isSearching });
  };

  renderCustomersTable = () => {
    if (this.state.customers.length > 0) {
      return (
        <CustomersTable
          user={this.props.authState.user}
          customers={this.state.customers}
          onRowClick={this.handleRowClick}
          loadingCustomers={this.state.loadingCustomers}
          deleteCustomer={id => {
            this.handleShowDeleteCustomerConfirmation(id);
          }}
          onGetMore={() => this.setState({ customersRequestPageLocation: this.state.customersRequestPageLocation + 1 }, () => this.getCustomers())}
        />
      );
    } else if (this.state.loadingCustomers) {
      return <div className="Table__loading">{this.props.t("loading_customers").message || "Loading customers"}</div>;
    } else if (!this.state.loadingCustomers && this.state.err_get_customers) {
      return (
        <div className="results-msg">
          <p>{this.state.err_get_customers}</p>
        </div>
      );
    } else {
      return (
        <div className="results-msg">
          <p>No customers.</p>
        </div>
      );
    }
  };

  renderMoreCustomersLoader = () => {
    if (this.state.customers.length > 0 && this.state.loadingCustomers) {
      return <div className="Table__loading">{this.props.t("loading_customers").message || "Loading customers"}</div>;
    }
  };

  renderCustomerDetailsLoader = () => {
    if (this.state.loadingCustomerDetail) {
      return <div className="Table__loading">{this.props.t("loading_customers").message || "Loading customers"}</div>;
    }
  };

  renderCustomerModal = () => {
    const { customerID } = this.state;

    if (!customerID) return null;

    return <CustomerModal customerID={customerID} onClose={this.handleCloseCustomerModal} location={this.props.globalState.selectedLocation} />;
  };

  renderAppointmentDetail = () => {
    if (!this.state.isAppointmentDetailVisible || !this.props.appointmentsState.selectedAppointment) return null;

    const { authState, appointmentsState, globalState, getChecks, handleUpdateAppointments } = this.props;
    const { dealer, location } = getDealerAndLocationById(globalState.dealers, appointmentsState.selectedAppointment.dealer_location_id);

    return (
      <AppointmentDetail
        detailPageOnlineUsers={appointmentsState.detailPageOnlineUsers}
        appointment={appointmentsState.selectedAppointment}
        appointmentChecks={appointmentsState.selectedAppointmentChecks}
        location={location}
        dealer={dealer}
        onRegistrationClick={this.handleRegistrationClick}
        showAppointment={this.handleShowAppointment}
        showCar={this.handleShowCar}
        getChecks={getChecks}
        currentUser={authState.user}
        onClose={this.handleCloseAppointmentDetail}
        onHandleUpdateAppointments={handleUpdateAppointments}
      />
    );
  };

  renderCarDetail = () => {
    const { selectedCarInfo } = this.state;
    const { globalState } = this.props;

    const location = getLocationById(globalState.dealers, selectedCarInfo.car.dealer_location_id);

    return (
      <CarDetail
        visible={this.state.isCarDetailVisible}
        car={selectedCarInfo.car}
        location={location}
        customer={selectedCarInfo.customer}
        appointmentHistory={selectedCarInfo.appointmentHistory}
        onHide={() => this.handleCloseCarDetail()}
        onSelectAppointment={id => this.handleShowAppointmentFromCarDetail(id)}
      />
    );
  };

  searchResultsRenderer = ({ result, handler }) => {
    if (!result) {
      return "";
    }

    const streetAndHouseNr = result.street ? `${result.street} ${result.house_nr}` : "-";

    return (
      <Grid key={result.id}>
        <Grid.Column width={1} className="-text-ellipsis">
          {result.dms_nr || "-"}
        </Grid.Column>
        <Grid.Column width={4} className="-text-ellipsis">
          {result.title + result.firstname + " " + result.surname}
        </Grid.Column>
        <Grid.Column width={2} className="-text-ellipsis">
          {result.is_lease_company && (
            <span className="lease-company-tag">
              <Icon name="building" />
            </span>
          )}
          {result.company || "-"}
        </Grid.Column>
        <Grid.Column width={1} className="-text-ellipsis">
          {result.place || "-"}
        </Grid.Column>
        <Grid.Column width={2} className="-text-ellipsis">
          {streetAndHouseNr}
        </Grid.Column>
        <Grid.Column width={1} className="-text-ellipsis">
          {result.postcode || "-"}
        </Grid.Column>
        <Grid.Column width={2} className="-text-ellipsis">
          {result.email_private || result.email_business}
        </Grid.Column>
        <Grid.Column width={1} className="-text-ellipsis">
          {result.tel_private_nr || result.tel_business_nr}
        </Grid.Column>
        <Grid.Column width={1} className="-text-ellipsis">
          {result.tel_mobile_private || result.tel_mobile_business}
        </Grid.Column>
        <Grid.Column width={1} className="-text-ellipsis">
          <Icon
            name="trash alternate outline"
            size="large"
            color="red"
            onClick={e => {
              e.stopPropagation();
              handler(this.state.searchResults[result.id].id, true);
            }}
          />
        </Grid.Column>
      </Grid>
    );
  };

  handleChangeIsLeaseCompany = (e, data) => {
    this.setState({ selectedLeaseCompany: data.checked, customersRequestPageLocation: 0 }, () => this.getCustomers());
  };

  render() {
    return (
      <div className="Customers">
        <CustomConfirm
          type="danger"
          isOpen={this.state.deleteCustomerConfirmationVisible}
          confirmMsg={this.props.t("confirm_delete_message").message || "Are you sure that you want to delete this? You can't undo this action."}
          error={this.state.deleteCustomerError}
          isLoading={this.state.isDeletingCustomer}
          handleCancel={() => {
            this.setState({ deleteCustomerConfirmationVisible: false });
          }}
          handleConfirm={this.handleDeleteCustomer}
        />

        <CustomConfirm
          type="danger"
          isOpen={this.state.deleteSearchedCustomerConfirmationVisible}
          confirmMsg={this.props.t("confirm_delete_message").message || "Are you sure that you want to delete this? You can't undo this action."}
          error={this.state.deleteSearchedCustomerError}
          isLoading={this.state.isDeletingSearchedCustomer}
          handleCancel={() => {
            this.setState({ deleteSearchedCustomerConfirmationVisible: false });
          }}
          handleConfirm={this.handleDeleteSearchedCustomer}
        />

        <SearchPortal>
          <Search
            minCharacters={4}
            className="-fullscreen -results-with-header"
            input={{
              icon: "search",
              iconPosition: "left",
              placeholder: this.props.t("start_searching_customers").message || "Start searching customers...",
            }}
            loading={this.state.isSearchLoading}
            showNoResults={true}
            noResultsMessage={this.props.t("no_results").message || "No results found"}
            onResultSelect={(event, data) => {
              console.log(event, data);
              this.handleOpenCustomerModal(data.result.id);
            }}
            onSearchChange={this.handleSearchChange}
            open={this.state.isSearchResultsOpen}
            results={this.state.searchResults}
            resultRenderer={result => this.searchResultsRenderer({ result, handler: this.handleShowDeleteSearchedCustomerConfirmation })}
            value={this.state.searchTerm}
            fluid
          />{" "}
          {/*  */}
          {this.state.isSearchResultsOpen && <Icon className="ExtendedSearchIcon" name="close" onClick={this.handleCloseSearchResults} />}
          {this.state.isSearchResultsOpen && <div className="SearchResultsHeader">{resultsHeaderRenderer(this.props.t)}</div>}
          {this.state.isSearchResultsOpen && this.state.searchResults && this.state.searchResults.length > 0 && (
            <div
              className="SearchResultsFooter"
              style={{
                position: "fixed",
                bottom: "10px",
                zIndex: 999,
                width: "10%",
                left: "45%",
              }}
            >
              {resultsFooterRenderer({ onLoadMore: this.searchCustomers, t: this.props.t })}
            </div>
          )}
        </SearchPortal>
        <UserMenuActionsPortal>
          <Icon name="refresh" onClick={this.handleRefresh} />
        </UserMenuActionsPortal>
        <SubHeader>
          <Grid.Column width={12} className="SubHeader_title -no-padding">
            {this.props.t("customer_overview").message || "Customer overview"}
          </Grid.Column>
          <Grid.Column width={4}>
            <Form>
              <Form.Field className="lease-toggle">
                <Icon name="building" />
                <label>{this.props.t("lease_company").message || "Lease company"}</label>
                <Checkbox checked={this.state.selectedLeaseCompany} toggle onChange={this.handleChangeIsLeaseCompany} />
              </Form.Field>
            </Form>
          </Grid.Column>
        </SubHeader>
        <div
          className="table"
          style={{
            width: "100%",
            height: "100%",
            overflowY: "hidden",
          }}
        >
          {this.renderCustomersTable()}
          {this.renderMoreCustomersLoader()}
        </div>

        {this.state.isAppointmentDetailVisible && this.props.appointmentsState.selectedAppointment && this.renderAppointmentDetail()}
        {this.state.isCarDetailVisible && this.renderCarDetail()}
        {this.renderCustomerModal()}
        {this.renderCustomerDetailsLoader()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { authState: state.auth, globalState: state.global, appointmentsState: state.appointments, carsState: state.cars };
};

const mapDispatchToProps = dispatch => {
  return {
    deselectAppointment: () => dispatch(deselectAppointment()),
    deselectCar: () => dispatch(deselectCar()),
    getAppointment: (id, options) => dispatch(getAppointment(id, options)),
    getCar: carID => dispatch(getCar(carID)),
    getChecks: appID => dispatch(getChecks(appID)),
    handleUpdateAppointments: appointment => dispatch(handleUpdateAppointments(appointment)),
    setAlert: alertOptions => dispatch(setAlert(alertOptions)),
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Customers));

const resultsHeaderRenderer = t => (
  <Grid>
    <Grid.Column width={1} className="-text-ellipsis">
      {t("dms_nr").message || "DMS#"}
    </Grid.Column>
    <Grid.Column width={4} className="-text-ellipsis">
      {t("name").message || "Name"}
    </Grid.Column>
    <Grid.Column width={2} className="-text-ellipsis">
      {t("company_name").message || "Company name"}
    </Grid.Column>
    <Grid.Column width={1} className="-text-ellipsis">
      {t("location").message || "Location"}
    </Grid.Column>
    <Grid.Column width={2} className="-text-ellipsis">
      {t("street").message || "Street"}
    </Grid.Column>
    <Grid.Column width={1} className="-text-ellipsis">
      {t("postcode").message || "Postcode"}
    </Grid.Column>
    <Grid.Column width={2} className="-text-ellipsis">
      {t("email").message || "Email"}
    </Grid.Column>
    <Grid.Column width={1} className="-text-ellipsis">
      {t("phone").message || "Phone"}
    </Grid.Column>
    <Grid.Column width={1} className="-text-ellipsis">
      {t("mobile").message || "Mobile"}
    </Grid.Column>
    <Grid.Column width={1} className="-text-ellipsis"></Grid.Column>
  </Grid>
);

const resultsFooterRenderer = ({ onLoadMore, t }) => (
  <Button
    fluid
    color="green"
    style={{
      borderRadius: "3px",
    }}
    onClick={onLoadMore}
  >
    {t("load_more").message || "Load more"}
  </Button>
);
