import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Checkbox, Form, Header, Icon, Message, Segment } from "semantic-ui-react";

class SnoozeSettings extends Component {
  handleCheckboxChange = (_e, data) => {
    this.props.onUpdateDealer({ ...this.props.dealer, [data.name]: data.checked });
  };

  render() {
    const { dealer, missingField, t } = this.props;

    const showError =
      ![
        dealer.is_snooze_type_next_visit_customer_enabled,
        dealer.is_snooze_type_remind_customer_enabled,
        dealer.is_snooze_type_make_new_appointment_enabled,
        dealer.is_snooze_type_make_offer_enabled,
      ].some(Boolean) && missingField;

    return (
      <Segment placeholder style={{ minHeight: "auto" }}>
        <Segment>
          <Header as="h5" className="snooze-type-header">
            <Icon name="clock" />
            <Header.Content>{t("snooze_type").message || "Snooze type"}</Header.Content>
          </Header>
          <Form>
            <Form.Field className="full-width-input">
              <Checkbox
                checked={dealer.is_snooze_type_next_visit_customer_enabled}
                name="is_snooze_type_next_visit_customer_enabled"
                label={t("next_visit_customer").message || "Next visit customer"}
                onChange={this.handleCheckboxChange}
              />
            </Form.Field>

            <Form.Field className="full-width-input">
              <Checkbox
                checked={dealer.is_snooze_type_remind_customer_enabled}
                name="is_snooze_type_remind_customer_enabled"
                label={t("remind_customer").message || "Remind customer"}
                onChange={this.handleCheckboxChange}
              />
            </Form.Field>

            <Form.Field className="full-width-input">
              <Checkbox
                checked={dealer.is_snooze_type_make_new_appointment_enabled}
                name="is_snooze_type_make_new_appointment_enabled"
                label={t("make_new_appointment").message || "Make new appointment"}
                onChange={this.handleCheckboxChange}
              />
            </Form.Field>

            <Form.Field className="full-width-input">
              <Checkbox
                checked={dealer.is_snooze_type_make_offer_enabled}
                name="is_snooze_type_make_offer_enabled"
                label={t("make_an_offer").message || "Make an offer"}
                onChange={this.handleCheckboxChange}
              />
            </Form.Field>

            {showError && (
              <Message negative>
                <p>{t("snooze_type_enabled_error_message").message || "At least one snooze type option needs to be enabled"}</p>
              </Message>
            )}

            <Header as="h5" className="snooze-segment-header">
              <Icon name="clock" />
              <Header.Content>{t("snooze_settings").message || "Snooze settings"}</Header.Content>
            </Header>

            <Form.Field className="full-width-input">
              <Checkbox
                checked={dealer.is_snooze_status_enabled}
                name="is_snooze_status_enabled"
                label={t("snooze_status").message || "Snooze status"}
                onChange={this.handleCheckboxChange}
              />
            </Form.Field>

            <Form.Field className="full-width-input">
              <Checkbox
                checked={dealer.is_snooze_department_enabled}
                name="is_snooze_department_enabled"
                label={t("department").message || "Department"}
                onChange={this.handleCheckboxChange}
              />
            </Form.Field>
          </Form>
        </Segment>
      </Segment>
    );
  }
}

export default withTranslation()(SnoozeSettings);
