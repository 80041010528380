import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Segment, Form, Dropdown, Header, Button } from "semantic-ui-react";
import AlertContainer from "react-alert";

import Can from "../Can";
import { CustomConfirm, PasswordInput, UserInput } from "../../components";
import { SMS_GATEWAYS } from "../../util/common";
import { updateDealer as globalDealerUpdate } from "../App/store";

import Service from "./service";

class SMSClientConfiguration extends Component {
  state = {
    isLoading: false,
    isCreatingSubaccount: false,
    isCreatingAPIKey: false,
    isBuyingPhoneNumber: false,
    showConfirmation: false,
    errorMsg: null,
  };

  handleInputChange = e => {
    const { dealer, onUpdateDealer } = this.props;
    if (Object.keys(dealer).length === 0) return;

    onUpdateDealer({ ...dealer, [e.target.name]: e.target.value });
  };

  handleNumberInputChange = e => {
    const { dealer, onUpdateDealer } = this.props;
    if (Object.keys(dealer).length === 0 || e.target.value < 0 || isNaN(e.target.value)) return;

    onUpdateDealer({ ...dealer, [e.target.name]: e.target.value });
  };

  handleDropdownChange = (_, { name, value }) => {
    const { dealer, onUpdateDealer } = this.props;
    if (Object.keys(dealer).length === 0) return;

    onUpdateDealer({ ...dealer, [name]: value });
  };

  toggleConfirmation = () => {
    this.setState(({ showConfirmation }) => ({ showConfirmation: !showConfirmation }));
  };

  renderConfirmation = () => {
    const { showConfirmation, isBuyingPhoneNumber, errorMsg } = this.state;
    const { t } = this.props;

    return (
      <CustomConfirm
        type="warning"
        isOpen={showConfirmation}
        handleConfirm={this.buyPhoneNumber}
        handleCancel={this.toggleConfirmation}
        confirmMsg={t("twilio_buy_phone_cost").message || "This will cost 6$ per month. Do you want to proceed?"}
        isLoading={isBuyingPhoneNumber}
        error={errorMsg}
      />
    );
  };

  createTwilioSubaccount = () => {
    const { dealer, onUpdateDealer } = this.props;

    this.setState({ isLoading: true, isCreatingSubaccount: true }, async () => {
      try {
        const response = await Service.createTwilioSubaccount(dealer.id);

        if (response?.data?.data) {
          const { account_sid, auth_token } = response.data.data;

          onUpdateDealer({ ...dealer, twilio_account_sid: account_sid, twilio_auth_token: auth_token, is_twilio_sub_account_managed: true });
        }
        this.setState({ isLoading: false, isCreatingSubaccount: false });
      } catch (error) {
        const errorMsg =
          typeof error.response?.data === "string"
            ? error.response.data
            : error.response.data.errors
            ? error.response.data.errors[0]
            : "Failed to create Twilio subaccount";
        this.msg.show(errorMsg, { type: "error" });
        this.setState({ isLoading: false, isCreatingSubaccount: false });
      }
    });
  };

  buyPhoneNumber = () => {
    const { dealer, onUpdateDealer } = this.props;

    this.setState({ isLoading: true, isBuyingPhoneNumber: true }, async () => {
      try {
        const response = await Service.buyTwilioNumber(dealer.id);

        if (response?.data?.data) {
          const { phone_number } = response.data.data;

          onUpdateDealer({ ...dealer, twilio_phone_number: phone_number, is_twilio_phone_number_managed: true });
        }
        this.setState({ isLoading: false, isBuyingPhoneNumber: false, showConfirmation: false });
      } catch (error) {
        const errorMsg =
          typeof error.response?.data === "string" ? error.response.data : error.response.data.errors ? error.response.data.errors[0] : "Failed to buy a phone number";
        this.msg.show(errorMsg, { type: "error" });
        this.setState({ isLoading: false, isBuyingPhoneNumber: false, errorMsg });
      }
    });
  };

  createAPIKey = () => {
    const { dealer, onUpdateDealer } = this.props;

    this.setState({ isLoading: true, isCreatingAPIKey: true }, async () => {
      try {
        const response = await Service.generateTwilioAPIKey(dealer.id);

        if (response?.data?.data) {
          const { api_key, api_secret } = response.data.data;

          onUpdateDealer({ ...dealer, twilio_api_key: api_key, twilio_api_secret: api_secret, is_twilio_api_key_managed: true });
        }
        this.setState({ isLoading: false, isCreatingAPIKey: false });
      } catch (error) {
        const errorMsg =
          typeof error.response?.data === "string"
            ? error.response.data
            : error.response.data.errors
            ? error.response.data.errors[0]
            : "Failed to create a twilio API key";
        this.msg.show(errorMsg, { type: "error" });
        this.setState({ isLoading: false, isCreatingAPIKey: false });
      }
    });
  };

  setMonthlyDollarLimit = () => {
    const { t, dealer, globalDealerUpdate } = this.props;
    const { twilio_monthly_dollar_limit, id: dealer_id } = dealer;

    if (!twilio_monthly_dollar_limit) return;

    const monthly_price = parseFloat(twilio_monthly_dollar_limit);

    this.setState({ isLoading: true }, async () => {
      try {
        await Service.setMonthlyDollarLimit(monthly_price, dealer_id);
        globalDealerUpdate(dealer);

        this.setState({ isLoading: false });
      } catch (error) {
        const errorMsg =
          typeof error.response?.data === "string"
            ? error.response.data
            : error.response.data.errors
            ? error.response.data.errors[0]
            : t("failed_to_set_monthly_dollar_limit").message || "Failed to set monthly dollar limit";
        this.msg.show(errorMsg, { type: "error" });
        this.setState({ isLoading: false });
      }
    });
  };

  setMonthlySMSLimit = () => {
    const { t, dealer, globalDealerUpdate } = this.props;
    const { twilio_monthly_sms_limit, id: dealer_id } = dealer;

    if (!twilio_monthly_sms_limit) return;

    const monthly_sms = parseInt(twilio_monthly_sms_limit);

    this.setState({ isLoading: true }, async () => {
      try {
        await Service.setMonthlySmsLimit(monthly_sms, dealer_id);
        globalDealerUpdate(dealer);
        this.setState({ isLoading: false });
      } catch (error) {
        const errorMsg =
          typeof error.response?.data === "string"
            ? error.response.data
            : error.response.data.errors
            ? error.response.data.errors[0]
            : t("failed_to_set_monthly_sms_limit").message || "Failed to set monthly SMS limit";
        this.msg.show(errorMsg, { type: "error" });
        this.setState({ isLoading: false });
      }
    });
  };

  renderAlert = () => {
    const props = {
      offset: 20,
      position: "top right",
      theme: "light",
      time: 2000,
      transition: "fade",
    };

    return <AlertContainer ref={a => (this.msg = a)} {...props} />;
  };

  renderSMSClientConfig = () => {
    const { dealer, missingField, t } = this.props;
    const { isLoading, isCreatingSubaccount, isCreatingAPIKey } = this.state;

    const { sms_gateway_id } = dealer;

    switch (sms_gateway_id) {
      case SMS_GATEWAYS.CHATSHIPPER:
        return (
          <>
            <Header as="h5">
              <Header.Content>{t("chatshipper_settings").message || "Chatshipper settings"}</Header.Content>
            </Header>
            <Segment>
              <Form>
                <Form.Field className="full-width-input" error={missingField && !dealer.chatshipper_email}>
                  <label>{t("email").message || "Email"}</label>
                  <UserInput
                    placeholder={t("chatshipper_email").message || "Chatshipper email"}
                    name="chatshipper_email"
                    onChange={this.handleInputChange}
                    value={dealer.chatshipper_email ? dealer.chatshipper_email : ""}
                  />
                </Form.Field>
                <Form.Field className="full-width-input" error={missingField && !dealer.chatshipper_password && !dealer.has_chatshipper_password}>
                  <label>{t("password").message || "Password"}</label>
                  <PasswordInput
                    placeholder={t("chatshipper_password").message || "Chatshipper password"}
                    name="chatshipper_password"
                    onChange={this.handleInputChange}
                    value={dealer.chatshipper_password ? dealer.chatshipper_password : ""}
                    showAsteriskPlaceholder={dealer.has_chatshipper_password}
                  />
                </Form.Field>
              </Form>
            </Segment>
          </>
        );

      case SMS_GATEWAYS.TWILIO:
        return (
          <>
            <Header as="h5">
              <Header.Content>{t("twilio_settings").message || "Twilio settings"}</Header.Content>
            </Header>
            <Segment>
              <Form>
                <Can I="create" the="twilio-sub-account">
                  <Button
                    onClick={this.createTwilioSubaccount}
                    disabled={isLoading || dealer.twilio_account_sid}
                    size="tiny"
                    color="green"
                    floated="left"
                    className="-margin-bottom-10"
                    loading={isCreatingSubaccount}
                  >
                    {t("create_sub_account").message || "Create sub-account"}
                  </Button>
                </Can>
                <Form.Field className="full-width-input" error={missingField && !dealer.twilio_account_sid}>
                  <label>{t("twilio_account_sid").message || "Twilio account SID"}</label>
                  <UserInput
                    placeholder={t("twilio_account_sid").message || "Twilio account SID"}
                    name="twilio_account_sid"
                    onChange={this.handleInputChange}
                    value={dealer.twilio_account_sid ? dealer.twilio_account_sid : ""}
                    disabled={isLoading || dealer.is_twilio_sub_account_managed}
                  />
                </Form.Field>

                <Form.Field className="full-width-input">
                  <label>{t("twilio_auth_token").message || "Twilio auth token"}</label>
                  <PasswordInput
                    placeholder={t("twilio_auth_token").message || "Twilio auth token"}
                    name="twilio_auth_token"
                    onChange={this.handleInputChange}
                    value={dealer.twilio_auth_token ? dealer.twilio_auth_token : ""}
                    disabled={isLoading || dealer.is_twilio_sub_account_managed}
                    showAsteriskPlaceholder={dealer.has_twilio_auth_token}
                  />
                </Form.Field>

                <Can I="create" the="twilio-sub-account">
                  <Button
                    onClick={this.createAPIKey}
                    disabled={isLoading || dealer.twilio_api_key}
                    size="tiny"
                    color="green"
                    floated="left"
                    className="-margin-bottom-10"
                    loading={isCreatingAPIKey}
                  >
                    {t("create_api_key").message || "Create API key"}
                  </Button>
                </Can>

                <Form.Field className="full-width-input" error={missingField && !dealer.twilio_api_key}>
                  <label>{t("twilio_api_key").message || "Twilio API key SID"}</label>
                  <UserInput
                    placeholder={t("twilio_api_key").message || "Twilio API key SID"}
                    name="twilio_api_key"
                    onChange={this.handleInputChange}
                    value={dealer.twilio_api_key ? dealer.twilio_api_key : ""}
                    disabled={isLoading || dealer.is_twilio_api_key_managed}
                  />
                </Form.Field>

                <Form.Field className="full-width-input">
                  <label>{t("twilio_api_secret").message || "Twilio API secret"}</label>
                  <PasswordInput
                    placeholder={t("twilio_api_secret").message || "Twilio API secret"}
                    name="twilio_api_secret"
                    onChange={this.handleInputChange}
                    value={dealer.twilio_api_secret ? dealer.twilio_api_secret : ""}
                    disabled={isLoading || dealer.is_twilio_api_key_managed}
                    showAsteriskPlaceholder={dealer.has_twilio_api_secret}
                  />
                </Form.Field>

                <Can I="create" the="twilio-sub-account">
                  <Button
                    onClick={this.toggleConfirmation}
                    disabled={isLoading || !!dealer.twilio_phone_number}
                    size="tiny"
                    color="green"
                    floated="left"
                    className="-margin-bottom-10"
                  >
                    {t("buy_phone_number").message || "Buy phone number"}
                  </Button>
                </Can>

                <Form.Field className="full-width-input" error={missingField && !dealer.twilio_phone_number}>
                  <label>{t("twilio_phone_number").message || "Twilio phone number"}</label>
                  <UserInput
                    placeholder={t("twilio_phone_number").message || "Twilio phone number"}
                    name="twilio_phone_number"
                    onChange={this.handleInputChange}
                    value={dealer.twilio_phone_number ? dealer.twilio_phone_number : ""}
                    disabled={isLoading || dealer.is_twilio_phone_number_managed}
                  />
                </Form.Field>

                <Can I="create" the="twilio-sub-account">
                  <div className="twilio-input-fields-container">
                    <div>
                      <Form.Field className="full-width-input" error={missingField && !dealer.twilio_monthly_dollar_limit}>
                        <label>{t("monthly_dollar_limit").message || "Monthly dollar limit"}</label>
                        <UserInput
                          type="number"
                          step="0.01"
                          placeholder={t("monthly_dollar_limit").message || "Monthly dollar limit"}
                          name="twilio_monthly_dollar_limit"
                          onChange={this.handleNumberInputChange}
                          value={dealer.twilio_monthly_dollar_limit}
                          disabled={isLoading || !dealer.twilio_account_sid}
                        />

                        <Button
                          onClick={this.setMonthlyDollarLimit}
                          disabled={isLoading || !dealer.twilio_monthly_dollar_limit || !dealer.twilio_account_sid}
                          loading={isLoading}
                          size="tiny"
                          color="green"
                          className="-margin-top-10"
                          style={{ marginLeft: "0px" }}
                        >
                          {t("set_monthly_dollar_limit").message || "Set monthly dollar limit"}
                        </Button>
                      </Form.Field>
                    </div>

                    <div>
                      <Form.Field className="full-width-input" error={missingField && !dealer.twilio_monthly_sms_limit}>
                        <label>{t("monthly_sms_limit").message || "Monthly SMS limit"}</label>
                        <UserInput
                          type="number"
                          placeholder={t("monthly_sms_limit").message || "Monthly SMS limit"}
                          name="twilio_monthly_sms_limit"
                          onChange={this.handleNumberInputChange}
                          value={dealer.twilio_monthly_sms_limit}
                          disabled={isLoading || !dealer.twilio_account_sid}
                        />

                        <Button
                          onClick={this.setMonthlySMSLimit}
                          disabled={isLoading || !dealer.twilio_monthly_sms_limit || !dealer.twilio_account_sid}
                          loading={isLoading}
                          size="tiny"
                          color="green"
                          className="-margin-top-10"
                          style={{ marginLeft: "0px" }}
                        >
                          {t("set_monthly_sms_limit").message || "Set monthly SMS limit"}
                        </Button>
                      </Form.Field>
                    </div>
                  </div>
                </Can>
              </Form>
            </Segment>
          </>
        );

      default:
        return null;
    }
  };

  render() {
    const { dealer, t } = this.props;

    return (
      <>
        <Form>
          <Form.Field className="full-width-input">
            <Dropdown
              value={dealer.sms_gateway_id}
              name="sms_gateway_id"
              options={Object.keys(SMS_GATEWAYS).map(key => ({ key, text: t(key.toLowerCase()).message || key, value: SMS_GATEWAYS[key] }))}
              selection
              selectOnBlur={false}
              onChange={this.handleDropdownChange}
            />
          </Form.Field>
        </Form>
        {this.renderSMSClientConfig()}
        {this.renderConfirmation()}
        {this.renderAlert()}
      </>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    globalDealerUpdate: dealer => dispatch(globalDealerUpdate(dealer)),
  };
};

export default withTranslation()(connect(null, mapDispatchToProps)(SMSClientConfiguration));
