import axios from "axios";
import ENV from "../../util/env-config";
import { getSessionCookie } from "../../util/common";

class Service {
  static requestConfig() {
    return {
      headers: {
        Authorization: `Bearer ${getSessionCookie()}`,
      },
    };
  }

  static getTags() {
    return axios.get(ENV.systemBase + "/tags/list", this.requestConfig());
  }

  static getDefaultQuestions(tags) {
    let data = {};
    if (tags && tags.length > 0) {
      data.tags = tags;
    }
    return axios.post(ENV.dealerBaseUrl + "/questions/default", data, this.requestConfig());
  }

  static getDealerQuestions(dealer_id) {
    return axios.post(ENV.dealerBaseUrl + "/questions/search", { id: dealer_id }, this.requestConfig());
  }

  static storeQuestion(question) {
    return axios.post(ENV.dealerBaseUrl + "/questions/create", question, this.requestConfig());
  }

  static getQuestion(question_id) {
    return axios.post(ENV.dealerBaseUrl + "/questions/view", { id: question_id }, this.requestConfig());
  }

  static updateQuestion(question) {
    return axios.post(ENV.dealerBaseUrl + "/questions/update", question, this.requestConfig());
  }

  static getQuestionElements() {
    return axios.get(ENV.dealerBaseUrl + "/question_elements/list", this.requestConfig());
  }

  static deleteQuestion(question_id) {
    return axios.post(ENV.dealerBaseUrl + "/questions/delete", { id: question_id }, this.requestConfig());
  }
}

export default Service;
