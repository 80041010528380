import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import _ from "lodash";

import { SubHeader, FloatingActions } from "./../../components";
import { setAlert } from "../App/store";

import Service from "./service";
import TireTable from "./TireTable";
import TireDetail from "./TireDetail";

import "./index.scss";

const newTire = {
  id: 0,
  manufacturer: "",
  description: "",
  carry_capacity: "",
  width: "",
  height: "",
  size: "",
  speed: "",
  season: 0,
  ean: "",
  run_flat_tire: false,
};

class Tyres extends Component {
  state = {
    tire: {},
    tires: [],
    page: 0,
    nb_items: 0,
    nb_pages: 0,
    detailViewMode: null,
    isLoadingTires: false,
    isTireDetailVisible: false,
    isSavingTire: false,
    errorMessage: "",
    filterTires: {
      manufacturer: null,
      description: null,
      carry_capacity: null,
      width: null,
      height: null,
      size: null,
      speed: null,
      season: null,
      ean: null,
    },
  };

  componentDidMount() {
    this.getTires();
  }

  getTires = page => {
    if (!page) page = 1;
    else if (this.state.isLoadingTires || page === this.state.page) return;

    this.setState({ isLoadingTires: true, page }, () => {
      Service.getTires({ page, ...this.state.filterTires })
        .then(response => {
          if (response?.data?.data) {
            const { items, nb_items, nb_pages } = response.data.data;
            this.setState({ tires: items || [], nb_items, nb_pages, isLoadingTires: false });
          } else throw Error(this.props.t("error_getting_tires").message || "Error getting tyres.");
        })
        .catch(err => {
          console.error("Error getting tyres.", err);
          this.setState({ isLoadingTires: false });
          this.props.setAlert({ type: "error", title: err.message });
        });
    });
  };

  handleSaveTire = () => {
    const { tire, tires, detailViewMode, isLoadingTires } = this.state;

    if (!isLoadingTires) {
      this.setState({ isSavingTire: true, errorMessage: "" }, () => {
        if (detailViewMode === "create") {
          Service.createTire(tire)
            .then(response => {
              this.setState({ tires: [...tires, { ...tire, id: response.data.data.tyre_id }], tire: newTire, isSavingTire: false, isTireDetailVisible: false });
            })
            .catch(err => {
              console.error("Error creating tire.", err);
              this.setState({ isSavingTire: false, errorMessage: err.message });
              this.props.setAlert({ type: "error", title: err.message });
            });
        } else {
          Service.updateTire(tire, tire.id)
            .then(response => {
              let index = tires.findIndex(t => t.id === tire.id);
              if (index > -1) {
                tires[index] = tire;
                this.setState({ tires: [...tires], tire: newTire, isSavingTire: false, isTireDetailVisible: false });
              }
            })
            .catch(err => {
              console.error("Error updating tire.", err);
              this.setState({ isSavingTire: false, isTireDetailVisible: false, errorMessage: err.message });
              this.props.setAlert({ type: "error", title: err.message });
            });
        }
      });
    }
  };

  handleTireChange = tire => {
    this.setState({ tire });
  };

  handleShowTireDetail = ({ mode, selectedTire }) => {
    this.setState({ isTireDetailVisible: true, detailViewMode: mode, tire: { ...selectedTire }, errorMessage: "" });
  };

  handleCloseTireDetail = () => {
    this.setState({ isTireDetailVisible: false, tire: newTire, errorMessage: "" });
  };

  handleFilterTires = (id, value) => {
    if (["carry_capacity", "width", "height"].includes(id)) value = parseInt(value);
    if (id === "size") value = parseFloat(value);

    value = value !== null && value !== "" ? value : null;

    this.setState(
      state => ({ filterTires: { ...state.filterTires, [id]: value } }),
      () => this.getTires()
    );
  };

  handleSeasonChange = (_e, selection) => {
    const season = selection.value !== "" ? selection.value : null;

    this.setState(
      state => ({ filterTires: { ...state.filterTires, season } }),
      () => this.getTires()
    );
  };

  renderTires() {
    const { tires, isLoadingTires, page, nb_items, nb_pages } = this.state;

    return (
      <TireTable
        tires={tires}
        loading={isLoadingTires}
        setTiresPage={this.setTiresPage}
        nb_items={nb_items}
        nb_pages={nb_pages}
        page={page}
        onRowClick={this.handleShowTireDetail}
        onGetTires={this.getTires}
        onSeasonChange={this.handleSeasonChange}
        onFilterTires={_.debounce(this.handleFilterTires, 500)}
      />
    );
  }

  render() {
    const { tire, isTireDetailVisible, isSavingTire, detailViewMode, errorMessage } = this.state;
    const { t } = this.props;

    return (
      <div className="tires-container">
        <SubHeader>
          <Grid.Column width={16} style={{ paddingLeft: "1.5rem" }}>
            <h1>{t("tyres").message || "Tyres"}</h1>
          </Grid.Column>
        </SubHeader>

        <Grid className="-contained-large">
          <Grid.Column width={16}>{this.renderTires()}</Grid.Column>
        </Grid>

        {isTireDetailVisible && (
          <TireDetail
            tire={tire}
            isOpen={isTireDetailVisible}
            isLoading={isSavingTire}
            onSave={this.handleSaveTire}
            onClose={this.handleCloseTireDetail}
            handleChange={this.handleTireChange}
            errorMessage={errorMessage}
            mode={detailViewMode}
          />
        )}

        <FloatingActions
          items={[]}
          onRootClick={() =>
            this.handleShowTireDetail({
              mode: "create",
              selectedTire: newTire,
            })
          }
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setAlert: alertOptions => dispatch(setAlert(alertOptions)),
  };
};

export default withTranslation()(connect(null, mapDispatchToProps)(Tyres));
