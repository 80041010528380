import axios from "axios";

import ENV from "../../util/env-config";
import { getSessionCookie } from "../../util/common";

class Service {
  static requestConfig() {
    return {
      headers: {
        Authorization: `Bearer ${getSessionCookie()}`,
        Pragma: "no-cache",
      },
    };
  }

  static exportSnoozed(requestData) {
    return axios.post(ENV.appointmentApiBase + "/leads/export/snoozed", requestData, this.requestConfig());
  }

  static exportNotSnoozed(requestData) {
    return axios.post(ENV.appointmentApiBase + "/leads/export/not_snoozed", requestData, this.requestConfig());
  }

  static exportCarLeads(requestData) {
    return axios.post(ENV.appointmentApiBase + "/leads/export/cars", requestData, this.requestConfig());
  }

  static getTags() {
    return axios.get(ENV.systemBase + "/tags/list", this.requestConfig());
  }

  static getChecklists(requestData) {
    return axios.post(ENV.dealerBaseUrl + "/checklists/list", requestData, this.requestConfig());
  }

  static getSnoozedLeads(requestData) {
    return axios.post(ENV.appointmentApiBase + "/leads/snoozed", requestData, this.requestConfig());
  }

  static getNotSnoozedLeads(requestData) {
    return axios.post(ENV.appointmentApiBase + "/leads/not_snoozed", requestData, this.requestConfig());
  }

  static getCarLeads(requestData) {
    return axios.post(ENV.appointmentApiBase + "/leads/cars", requestData, this.requestConfig());
  }

  static getTiresLeads(requestData) {
    return axios.post(ENV.appointmentApiBase + "/leads/tires", requestData, this.requestConfig());
  }

  static exportTiresLeads(requestData) {
    return axios.post(ENV.appointmentApiBase + "/leads/export/tires", requestData, this.requestConfig());
  }

  static getTiresWidths(requestData) {
    return axios.post(ENV.carBaseUrl + "/tyres/widths_by_size", requestData, this.requestConfig());
  }

  static getTiresHeight(requestData) {
    return axios.post(ENV.carBaseUrl + "/tyres/heights_by_size_and_width", requestData, this.requestConfig());
  }

  static getTiresSpeeds() {
    return axios.get(ENV.carBaseUrl + "/tyres/speeds/list", this.requestConfig());
  }

  static getTiresSize() {
    return axios.get(ENV.carBaseUrl + "/tyres/sizes/list", this.requestConfig());
  }

  static getTiresBrands() {
    return axios.get(ENV.carBaseUrl + "/tyres/brands/list", this.requestConfig());
  }
}

export default Service;
