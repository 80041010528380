import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Icon, Grid, Search, Dropdown, Button, Modal, Message } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import _ from "lodash";
import moment from "moment";
import DatePicker from "react-datepicker";

import { isAdmin } from "../../util/common";
import { setAlert } from "../App/store";
import { SearchPortal, SubHeader, UserMenuActionsPortal, FloatingActions } from "./../../components";
import UsersTable from "./UsersTable";
import UserDetail from "./UserDetail";
import Can from "../Can";

import Service from "./service";
import AppService from "../App/service";
import { ROLES } from "./roles";

import "./Users.css";

const DPO_ROLE_VALUE = -99;

const USER_STATUS = {
  ALL: 0,
  ACTIVE: 1,
  INACTIVE: 2,
  DELETED: 3,
  ARCHIVED: 4,
};

const USER_PAIR_OPTIONS = {
  ONEI: 1,
  PLANIT: 2,
  DMS: 3,
};

class Users extends Component {
  state = {
    originalUsers: [],
    users: [],
    mechanics: [],
    user: null,
    dealers: [],
    dealersArray: [],
    locationsArray: [],
    roles: [],
    rolesArray: [],
    userMetrics: [],
    userDevices: [],
    webVersions: [],
    mobileVersions: [],
    cameraVersions: [],
    loadingUsers: false,
    loadingUser: false,
    loadingRoles: false,
    loadingVersions: false,
    loadingUserMetrics: false,
    loadingUserDevices: false,
    selectedUser: {},
    isUserDetailVisible: false,
    isSubmittingDetail: false,
    isSubmittingRole: false,
    isSubmittingDealerAndLocation: false,
    isSearchLoading: false,
    isDPOselected: false,
    detailViewMode: "",
    searchTerm: "",
    isUserAdmin: false,
    selectedStatus: USER_STATUS.ACTIVE,
    selectedRoles: [],
    selectedDealer: this.props.globalState.selectedDealer.id,
    selectedLocation: this.props.globalState.selectedLocation.id,
    selectedWebVersions: [],
    selectedMobileVersions: [],
    selectedCameraVersions: [],
    selectedActiveDateFrom: null,
    selectedActiveDateTo: null,
    selectedUserPairOption: null,
    errorMessage: "",
    isReinstatingUser: false,
    isDeletingUser: false,
    deletingUserError: "",
    isSettingUserAsDPO: false,
    displayedUsers: [],
    loadingBrands: false,
    brandsList: [],
    oneiPlanningMechanics: [],
    oneiPlanningMechanicsOptions: [],
    planItMechanics: [],
    planItMechanicsOptions: [],
    pairedUsers: [],
    dmsUsers: [],
    dmsUsersOptions: [],
    loadingDMSUsers: false,
    loadingPlanningMechanicsLists: false,
    loadingUserPairOptions: false,
    savingPairedUsers: false,
    usersToPair: [],
    newPairedUser: {
      user_id: null,
      pair_to: null,
    },
    pairUsersError: "",
    isCombiUserSelected: "all",
  };

  componentDidMount() {
    const currentUser = this.props.authState.user;
    const isUserAdmin = isAdmin(currentUser.role_id);
    this.setState({ isUserAdmin });

    this.getVersions();
    this.getRoles();
    this.getDealers();
    this.searchUsers();
    this.getBrands();
  }

  getOneiPlanningMechanicList = async (selectedDealer, locationId) => {
    if (!selectedDealer.onei_planning_enabled) return;

    Service.getOneiPlanningMechanicList(locationId)
      .then(res => {
        const oneiPlanningMechanicsOptions =
          res?.data?.data?.onei_planning_monteurs?.map(data => ({
            text: `${data.onei_monteur_name} - ${data.onei_code}`,
            value: data.onei_planning_monteur_id,
            key: data.onei_planning_monteur_id,
            disabled: !!data.onei_user_id,
          })) || [];

        this.setState({ oneiPlanningMechanicsOptions, oneiPlanningMechanics: res?.data?.data?.onei_planning_monteurs || [], loadingUserPairOptions: false });
      })
      .catch(err => {
        console.error("Error while getting one i planning mechanics", err);
        this.props.setAlert({
          type: "error",
          title: err?.response?.data?.errors?.length ? err?.response?.data?.errors[0] : "Error getting one i mechanics",
        });
        this.setState({ loadingUserPairOptions: false });
      });
  };

  getPlanItMechanicList = async (selectedDealer, locationId) => {
    if (!selectedDealer.planit_planning_enabled) return;

    Service.getPlanItMechanicList(locationId)
      .then(res => {
        const planItMechanicsOptions =
          res?.data?.data?.planit_planning_mechanics?.map(data => ({
            text: `${data.planit_mechanic_name} - ${data.planit_code}`,
            value: data.planit_planning_mechanic_id,
            key: data.planit_planning_mechanic_id,
            disabled: !!data.planit_user_id,
          })) || [];

        this.setState({ planItMechanicsOptions, planItMechanics: res?.data?.data?.planit_planning_mechanics || [], loadingUserPairOptions: false });
      })
      .catch(err => {
        console.error("Error while getting plan it mechanics", err);
        this.props.setAlert({
          type: "error",
          title: err?.response?.data?.errors?.length ? err?.response?.data?.errors[0] : "Error getting plan it mechanics",
        });
        this.setState({ loadingUserPairOptions: false });
      });
  };

  getMechanicsPlanningLists = (dealer_id, dealer_location_id) => {
    if (![ROLES.MECHANIC].includes(this.state.selectedUser.role_id)) return;

    this.setState({ loadingPlanningMechanicsLists: true }, async () => {
      const { dealers } = this.state;

      const selectedDealer = dealers?.find(dealer => dealer.id === dealer_id);

      await this.getOneiPlanningMechanicList(selectedDealer, dealer_location_id);
      await this.getPlanItMechanicList(selectedDealer, dealer_location_id);

      this.setState({ loadingPlanningMechanicsLists: false });
    });
  };

  getDMSUsers = dealer_location_id => {
    this.setState({ loadingDMSUsers: true, loadingPlanningMechanicsLists: true }, async () => {
      try {
        const response = await Service.getDMSUsers(dealer_location_id);

        this.setState({
          loadingDMSUsers: false,
          loadingPlanningMechanicsLists: false,
          loadingUserPairOptions: false,
          dmsUsersOptions: response?.data?.data?.dms_users?.map(user => ({ value: user.dms_nr, text: user.name, disabled: !!user.user_id })) || [],
          dmsUsers: response?.data?.data?.dms_users || [],
        });
      } catch (err) {
        this.props.setAlert({
          type: "error",
          title: err.message,
        });
        this.setState({ loadingDMSUsers: false, loadingPlanningMechanicsLists: false, loadingUserPairOptions: false });
      }
    });
  };

  getRoles = () => {
    this.setState(
      {
        loadingRoles: true,
      },
      () => {
        Service.getACLRoles({})
          .then(response => {
            const roles = response && response.data.data.roles ? response.data.data.roles : [];
            const userRole = parseInt(this.props.authState.user.role_id, 10);
            /* eslint-disable array-callback-return */
            const groups = roles.filter(g => {
              if (g.id === undefined) {
                g.id = 0;
              } else {
                g.id = parseInt(g.id, 10);
              }

              // Check if user's role should see this group
              if (userRole === ROLES.CONSULTANT && g.id <= ROLES.CONSULTANT) {
                return;
              }

              // Hide roles from dealer admin
              if (userRole === ROLES.DEALER_ADMIN && g.id <= ROLES.DEALER_ADMIN) {
                return;
              }

              return g;
            });

            groups.unshift({
              id: DPO_ROLE_VALUE,
              is_admin: false,
              name: "DPO",
            });

            let rolesArray = [];
            for (let i = 0; i < groups.length; i++) rolesArray[groups[i].id] = groups[i].name;

            this.setState({ loadingRoles: false, roles: groups, rolesArray: rolesArray });
          })
          .catch(err => {
            this.props.setAlert({
              type: "error",
              title: err.message,
            });
            console.error("Error getting roles.", err);
            this.setState({ loadingRoles: false });
          });
      }
    );
  };

  getBrands = () => {
    this.setState({ loadingBrands: true }, () => {
      Service.getBrands()
        .then(response => {
          const brandsList = response?.data?.data?.brands || [];
          this.setState({ brandsList, loadingBrands: false });
        })
        .catch(err => {
          this.setState({ loadingBrands: false }, () => {
            this.props.setAlert({
              type: "error",
              title: err.message,
            });
          });
        });
    });
  };

  getDealers = () => {
    this.setState(
      {
        loadingDealers: true,
      },
      () => {
        AppService.getDealers()
          .then(response => {
            let dealers = response && response.data && response.data.data && response.data.data.dealers ? response.data.data.dealers : [];

            dealers.sort((a, b) => {
              return a.name < b.name ? -1 : 1;
            });

            let dealersArray = [];
            let locationsArray = [];
            for (let i = 0; i < dealers.length; i++) {
              dealersArray[dealers[i].id] = dealers[i].name;
              if (!dealers[i].locations || !dealers[i].locations.length) continue;

              for (let j = 0; j < dealers[i].locations.length; j++) locationsArray[dealers[i].locations[j].id] = dealers[i].locations[j].name;
            }

            this.setState({ loadingDealers: false, dealers, dealersArray, locationsArray });
          })
          .catch(err => {
            this.props.setAlert({
              type: "error",
              title: err.message,
            });
            console.error("Error getting dealers.", err);
            this.setState({ loadingDealers: false });
          });
      }
    );
  };

  getMetrics = id => {
    if (id) {
      this.setState(
        {
          loadingUserMetrics: true,
        },
        () => {
          Service.getUserMetrics(id)
            .then(response => {
              const metrics = response?.data?.data?.network_metrics ? response.data.data.network_metrics : [];
              this.setState({ loadingUserMetrics: false, userMetrics: metrics });
            })
            .catch(err => {
              this.props.setAlert({
                type: "error",
                title: err.message,
              });
              console.error("Error getting user metrics.", err);
              this.setState({ loadingUserMetrics: false });
            });
        }
      );
    }
  };

  getDevices = id => {
    if (id) {
      this.setState(
        {
          loadingUserDevices: true,
        },
        () => {
          Service.getDevicesByUser({ user_id: id })
            .then(response => {
              const devices = response.data?.data?.devices || [];
              this.setState({ loadingUserDevices: false, userDevices: devices });
            })
            .catch(err => {
              this.props.setAlert({
                type: "error",
                title: err.message,
              });
              console.error("Error getting user devices.", err);
              this.setState({ loadingUserDevices: false });
            });
        }
      );
    }
  };

  getVersions = () => {
    this.setState({ loadingVersions: true }, () => {
      Service.getVersions()
        .then(res => {
          let mobileVersions = [],
            cameraVersions = [],
            webVersions = [];

          if (res?.data?.data) {
            mobileVersions = res.data.data.app_versions?.map(v => ({ text: v, key: v, value: v }));
            cameraVersions = res.data.data.camera_versions?.map(v => ({ text: v, key: v, value: v }));
            webVersions = res.data.data.web_versions?.map(v => ({ text: v, key: v, value: v }));
          }

          this.setState({ mobileVersions, cameraVersions, webVersions, loadingVersions: false });
        })
        .catch(err => {
          console.error("Failed to load versions", err);
          this.setState({ loadingVersions: false }, () => {
            this.props.setAlert({ type: "error", title: err.message });
          });
        });
    });
  };

  searchUsers = () => {
    const {
      loadingUsers,
      searchTerm,
      selectedDealer,
      isDPOselected,
      selectedLocation,
      selectedRoles,
      selectedStatus,
      selectedWebVersions,
      selectedMobileVersions,
      selectedCameraVersions,
      selectedActiveDateFrom,
      selectedActiveDateTo,
      isCombiUserSelected,
    } = this.state;

    if (loadingUsers) return;

    this.setState({ isSearchLoading: !!searchTerm, loadingUsers: true }, () => {
      const roles = isDPOselected ? selectedRoles.filter(role => role !== DPO_ROLE_VALUE) : selectedRoles;

      const requestData = {
        term: searchTerm,
        dealer_id: selectedDealer,
        roles,
        status: selectedStatus,
        web_versions: selectedWebVersions.length ? selectedWebVersions : null,
        mobile_versions: selectedMobileVersions.length ? selectedMobileVersions : null,
        camera_versions: selectedCameraVersions.length ? selectedCameraVersions : null,
        active_date_from: selectedActiveDateFrom,
        active_date_to: selectedActiveDateTo,
        is_combi_user: isCombiUserSelected === "all" ? null : isCombiUserSelected,
      };

      Service.searchUsers(requestData)
        .then(response => {
          let res = response?.data?.data?.users || [];

          const mechanics = res
            .filter(user => user.role_id === ROLES.MECHANIC && user.dealer_location_id === selectedLocation)
            .map(u => ({ key: u.id, value: u.id, text: `${u.first_name} ${u.last_name}` }));
          const usersToPair = res.map(u => ({ key: u.id, value: u.id, text: `${u.first_name} ${u.last_name}` }));

          this.setState(
            {
              loadingUsers: false,
              searchResults: res,
              isSearchLoading: false,
              users: res,
              displayedUsers: res,
              mechanics,
              usersToPair,
              isSearchResultsOpen: searchTerm !== "",
            },
            () => this.filterLocations(selectedLocation)
          );
        })
        .catch(err => {
          this.setState({ isSearchLoading: false, isSearchResultsOpen: false, loadingUsers: false });
          console.error("Error searching users ", err);
          this.props.setAlert({
            type: "error",
            title: err.message,
          });
        });
    });
  };

  handleRefresh = () => {
    this.setState(
      {
        users: [],
        displayedUsers: [],
        originalUsers: [],
        searchTerm: "",
        isSearchLoading: false,
        selectedRoles: [],
        isDPOSelected: false,
        selectedDealer: this.props.globalState.selectedDealer.id,
        selectedLocation: this.props.globalState.selectedLocation.id,
        selectedWebVersions: [],
        selectedMobileVersions: [],
        selectedCameraVersions: [],
        selectedStatus: USER_STATUS.ACTIVE,
      },
      () => {
        this.searchUsers();
      }
    );
  };

  handleSearchChange = (e, data) => {
    if (this.state.lastSearchTimeout) clearTimeout(this.state.lastSearchTimeout);

    this.setState({
      searchTerm: data.value,
      searchResults: [],
      lastSearchTimeout: setTimeout(() => {
        this.performSearch();
      }, 500),
    });
  };

  performSearch = () => {
    const { searchTerm } = this.state;
    if (!searchTerm || this.state.searchTerm.trim().length < 3) {
      return;
    }

    this.searchUsers();
  };

  handleCloseSearchResults = () => {
    if (this.state.selectedStatus === USER_STATUS.ALL && this.state.selectedDealer === -1 && !this.state.selectedRoles?.length)
      this.setState({
        searchTerm: "",
        users: this.state.originalUsers,
        displayedUsers: this.state.originalUsers,
        isSearchResultsOpen: false,
      });
    else
      this.setState({ searchTerm: "", isSearchResultsOpen: false }, () => {
        this.searchUsers();
      });
  };

  handleRowClick = (columnId, rowInfo) => {
    if (!this.canEditDeleteUsers()) return;

    if (!rowInfo.original.id) {
      this.props.setAlert({ type: "error", title: this.props.t("problem_selecting_user").message || "Problem selecting this user" });
      return;
    }

    this.setState({ loadingUser: true }, () => {
      Service.getUser(rowInfo.original.id)
        .then(response => {
          const user = response?.data?.data ? response.data.data : null;
          this.setState({ loadingUser: false, user, detailViewMode: "edit", isUserDetailVisible: true, errorMessage: "", selectedUser: _.cloneDeep(user) });
        })
        .catch(err => {
          this.props.setAlert({
            type: "error",
            title: err.message,
          });
          console.error("Error getting users.", err);
          this.setState({ loadingUser: false, isUserDetailVisible: false });
        });
    });
  };

  handleNewUser = () => {
    this.setState({
      detailViewMode: "create",
      isUserDetailVisible: true,
      selectedUser: {
        username: "",
        first_name: "",
        last_name: "",
        email: "",
        address1: "",
        address2: "",
        phone: "",
        mobile: "",
        multi_device_login_enabled: false,
        status: 1,
        dealer_id: this.props.globalState.selectedDealer.id,
        dealer_location_id: this.props.globalState.selectedLocation.id,
      },
      errorMessage: "",
    });
  };

  handleStatusChange = (event, data) => {
    this.setState(
      {
        selectedStatus: data.value,
      },
      () => {
        this.searchUsers();
      }
    );
  };

  handleRoleChange = (event, data) => {
    this.setState(
      {
        selectedRoles: data.value,
        isDPOselected: data.value.includes(DPO_ROLE_VALUE),
      },
      () => {
        this.searchUsers();
      }
    );
  };

  handleDealerChange = (event, data) => {
    this.setState(
      {
        selectedDealer: data.value,
        selectedLocation: -1,
      },
      () => {
        this.searchUsers();
      }
    );
  };

  handleLocationChange = (event, data) => {
    this.filterLocations(data.value);
  };

  handleVersionChange = (_event, data) => {
    this.setState({ [data.name]: data.value }, () => this.searchUsers());
  };

  handleChangeActiveDateFrom = date => {
    let selectedActiveDateFrom = null;

    if (date) selectedActiveDateFrom = moment(date).format("YYYY-MM-DDTHH:mm:ssZ");

    this.setState({ selectedActiveDateFrom }, () => this.searchUsers());
  };

  handleChangeActiveDateTo = date => {
    let selectedActiveDateTo = null;

    if (date) selectedActiveDateTo = moment(date).format("YYYY-MM-DDTHH:mm:ssZ");

    this.setState({ selectedActiveDateTo }, () => this.searchUsers());
  };

  filterLocations = location_id => {
    this.setState({ loadingUsers: true }, () => {
      let displayedUsers = [...this.state.users];

      if (location_id !== -1) {
        displayedUsers = displayedUsers.filter(user => user.dealer_location_id === location_id);
      }

      if (this.state.isDPOselected) {
        displayedUsers = displayedUsers.filter(user => user.dpo);
      }

      this.setState({ displayedUsers, selectedLocation: location_id, loadingUsers: false });
    });
  };

  handleReinstateUser = id => {
    this.setState({ isReinstatingUser: true }, () => {
      Service.reinstateUser({ user_id: id })
        .then(() => {
          this.handleHideDetailView();
          this.setState({ isReinstatingUser: false });
        })
        .catch(err => {
          this.props.setAlert({
            type: "error",
            title: err.message,
          });
          console.error("Error reinstating user.", err);
          this.setState({ isReinstatingUser: false });
        });
    });
  };

  handleDeleteUser = id => {
    this.setState(
      {
        isDeletingUser: true,
        deletingUserError: "",
      },
      () => {
        Service.deleteUser(id)
          .then(res => {
            this.setState(
              {
                isDeletingUser: false,
                deletingUserError: "",
              },
              () => {
                this.handleHideDetailView();
              }
            );
          })
          .catch(err => {
            this.setState({
              isDeletingUser: false,
              deletingUserError: err.response.data && err.response.data.errors ? err.response.data.errors[0] : "Unknown error occured",
            });
          });
      }
    );
  };

  handleCancelDeleteUser = () => {
    this.setState({ deletingUserError: "" });
  };

  handleSetDealerDataOfficer = ddo => {
    this.setState({ isSettingUserAsDPO: true }, () => {
      Service.setDealerDataOfficer(ddo)
        .then(() => {
          this.handleHideDetailView();
          this.setState({ isSettingUserAsDPO: false });
        })
        .catch(err => {
          this.props.setAlert({
            type: "error",
            title: err.message,
          });
          console.error("Error setting Dealer Data Officer.", err);
          this.setState({ isSettingUserAsDPO: false });
        });
    });
  };

  handleHideDetailView = () => {
    this.setState({ isUserDetailVisible: false, detailViewMode: "", selectedUser: null, errorMessage: "" }, () => {
      this.searchUsers();
    });
  };

  handleSave = user => {
    this.setState({ isSubmittingDetail: true }, () => {
      if (this.state.detailViewMode === "create") {
        Service.createUser(user)
          .then(result => {
            if (result?.data?.data) {
              const { users } = this.state;
              user.id = result.data.data;
              users.push(user);

              this.setState({ users, displayedUsers: users }, () => {
                this.handleHideDetailView();
              });
            }

            this.setState({ isSubmittingDetail: false });
          })
          .catch(error => {
            console.error("Error on submitting new user.", error);
            this.setState({ isSubmittingDetail: false });

            if (error.response?.data?.errors?.length) this.setState({ errorMessage: error.response.data.errors[0] });
          });
      } else {
        Service.updateUser(user)
          .then(result => {
            const users = [...this.state.users];
            const i = users.findIndex(u => u.id === user.id);
            if (i !== -1) {
              users[i] = user;

              this.setState({ users, displayedUsers: users }, () => {
                this.handleHideDetailView();
              });
            } else this.handleHideDetailView();

            this.setState({ isSubmittingDetail: false });
          })
          .catch(error => {
            console.error("Error on updating user.", error);
            this.setState({ isSubmittingDetail: false });

            if (error.response?.data?.errors?.length) this.setState({ errorMessage: error.response.data.errors[0] });
          });
      }
    });
  };

  handleCloseUserPairModal = () => {
    this.setState({ selectedUserPairOption: null, pairedUsers: [], newPairedUser: { user_id: null, pair_to: null }, pairUsersError: "" });
  };

  handleSelectPairOption = (_e, data) => {
    const { selectedLocation, dealers, selectedDealer } = this.state;

    const dealer = dealers.find(d => d.id === selectedDealer);

    this.setState({ selectedUserPairOption: data.value, loadingUserPairOptions: true }, () => {
      switch (data.value) {
        case USER_PAIR_OPTIONS.PLANIT:
          this.getPlanItMechanicList(dealer, selectedLocation);
          break;
        case USER_PAIR_OPTIONS.ONEI:
          this.getOneiPlanningMechanicList(dealer, selectedLocation);
          break;
        case USER_PAIR_OPTIONS.DMS:
          this.getDMSUsers(selectedLocation);
          break;
        default:
          break;
      }
    });
  };

  handleAddPairOption = () => {
    const { pairedUsers, newPairedUser } = this.state;

    if (!newPairedUser.user_id || !newPairedUser.pair_to) return;

    this.setState({ pairedUsers: [...pairedUsers, { ...newPairedUser }], newPairedUser: { user_id: null, pair_to: null } });
  };

  handleDeletePairedUser = param => {
    if (typeof param === "number") {
      const pairedUsers = [...this.state.pairedUsers];

      pairedUsers.splice(param, 1);

      this.setState({ pairedUsers });
    } else {
      Service.updateUser(param)
        .then(() => {
          const users = [...this.state.users];
          const i = users.findIndex(u => u.id === param.id);
          if (i !== -1) {
            users[i] = param;

            this.setState({ users, displayedUsers: users }, () => {
              this.handleHideDetailView();
            });
          } else this.handleHideDetailView();

          this.setState({ isSubmittingDetail: false });
        })
        .catch(error => {
          console.error("Error on updating user.", error);
          this.setState({ isSubmittingDetail: false });

          if (error.response?.data?.errors?.length) this.setState({ errorMessage: error.response.data.errors[0] });
        });
    }
  };

  handleChangeNewPairUser = (_e, data) => {
    this.setState({ newPairedUser: { ...this.state.newPairedUser, [data.name]: data.value } });
  };

  handleSavePairUsers = () => {
    const { selectedUserPairOption } = this.state;

    if (!selectedUserPairOption) return;

    this.setState({ savingPairedUsers: true, pairUsersError: "" }, async () => {
      try {
        switch (selectedUserPairOption) {
          case USER_PAIR_OPTIONS.ONEI:
            await this.handlePairOneiUsers();
            break;
          case USER_PAIR_OPTIONS.PLANIT:
            await this.handlePairPlanitUsers();
            break;
          case USER_PAIR_OPTIONS.DMS:
            await this.handlePairDMSUsers();
            break;
          default:
            break;
        }

        this.setState({ savingPairedUsers: false }, () => {
          this.handleCloseUserPairModal();
          this.searchUsers();
        });
      } catch (err) {
        console.error("Error saving paired users", err);
        const pairUsersError =
          typeof err.response?.data === "string"
            ? err.response.data
            : err.response.data.errors
            ? err.response.data.errors[0]
            : this.props.t("error_pairing_users").message || "Failed to pair users";

        this.setState({ savingPairedUsers: false, pairUsersError });
      }
    });
  };

  handlePairOneiUsers = async () => {
    const { pairedUsers, oneiPlanningMechanics } = this.state;

    const requestData = pairedUsers.map(user => {
      const pairedUser = oneiPlanningMechanics.find(m => m.onei_planning_monteur_id === user.pair_to);

      if (pairedUser) return { ...pairedUser, onei_user_id: user.user_id };
    });

    return await Service.updateOneiPairing({ onei_planning_monteurs: requestData });
  };

  handlePairPlanitUsers = async () => {
    const { pairedUsers, planItMechanics } = this.state;

    const requestData = pairedUsers.map(user => {
      const pairedUser = planItMechanics.find(m => m.planit_planning_mechanic_id === user.pair_to);

      if (pairedUser) return { ...pairedUser, planit_user_id: user.user_id };
    });

    return await Service.updatePlanitPairing({ planit_planning_mechanics: requestData });
  };

  handlePairDMSUsers = async () => {
    const { pairedUsers, dmsUsers } = this.state;

    const requestData = pairedUsers.map(user => {
      const pairedUser = dmsUsers.find(m => m.dms_nr === user.pair_to);

      if (pairedUser) return { ...pairedUser, user_id: user.user_id };
    });

    return await Service.updateDMSPairing({ dms_users: requestData });
  };

  getUserOptionText = value => {
    switch (value) {
      case USER_PAIR_OPTIONS.ONEI:
        return "1i Automotive";
      case USER_PAIR_OPTIONS.PLANIT:
        return "PlanIT";
      case USER_PAIR_OPTIONS.DMS:
        return "DMS";
      default:
        return "";
    }
  };

  renderUserPairModal = () => {
    const { t } = this.props;
    const {
      mechanics,
      usersToPair,
      selectedUserPairOption,
      savingPairedUsers,
      pairedUsers,
      newPairedUser,
      planItMechanicsOptions,
      oneiPlanningMechanicsOptions,
      dmsUsersOptions,
      loadingUserPairOptions,
      pairUsersError,
    } = this.state;

    if (!selectedUserPairOption) return;

    const preparedOptions =
      selectedUserPairOption === USER_PAIR_OPTIONS.ONEI
        ? [...oneiPlanningMechanicsOptions]
        : selectedUserPairOption === USER_PAIR_OPTIONS.PLANIT
        ? [...planItMechanicsOptions]
        : selectedUserPairOption === USER_PAIR_OPTIONS.DMS
        ? [...dmsUsersOptions]
        : [];

    const pairField =
      selectedUserPairOption === USER_PAIR_OPTIONS.ONEI
        ? "onei_planning_monteur_id"
        : selectedUserPairOption === USER_PAIR_OPTIONS.PLANIT
        ? "planit_planning_mechanic_id"
        : selectedUserPairOption === USER_PAIR_OPTIONS.DMS
        ? "dms_nr"
        : [];

    return (
      <Modal className="PairUsersModal" size="small" open={!!selectedUserPairOption} onClose={this.handleCloseUserPairModal}>
        <Modal.Header>
          {t("pair_users").message || "Pair users"} {this.getUserOptionText(selectedUserPairOption)}
        </Modal.Header>

        <Modal.Content scrolling>
          {this.state.displayedUsers.map((user, index) => {
            if (!preparedOptions.some(po => po.value === user[pairField])) return null;

            return (
              <div className="PairUsersModal_row" key={index}>
                <Button icon="trash" onClick={() => this.handleDeletePairedUser({ ...user, [pairField]: null })} color="red" />

                <div className="PairUsersModal_row-dropdowns">
                  <Dropdown
                    options={this.state.displayedUsers.map(user => ({ key: user.id, value: user.id, text: `${user.first_name} ${user.last_name}` }))}
                    value={user.id}
                    selection
                    disabled
                    fluid
                  />
                  <Dropdown options={preparedOptions} value={user[pairField]} selection disabled fluid />
                </div>

                <Icon name="checkmark" color="green" />
              </div>
            );
          })}

          {pairedUsers.map((user, index) => {
            return (
              <div className="PairUsersModal_row" key={index}>
                <Button icon="trash" onClick={() => this.handleDeletePairedUser(index)} color="red" />

                <div className="PairUsersModal_row-dropdowns">
                  <Dropdown options={selectedUserPairOption === USER_PAIR_OPTIONS.DMS ? usersToPair : mechanics} value={user.user_id} selection disabled fluid />
                  <Dropdown options={preparedOptions} value={user.pair_to} selection disabled fluid />
                </div>

                <Icon name="checkmark" color="green" />
              </div>
            );
          })}

          <div className="PairUsersModal_row">
            <div className="PairUsersModal_row-dropdowns">
              <Dropdown
                options={
                  selectedUserPairOption === USER_PAIR_OPTIONS.DMS
                    ? usersToPair.filter(user => !pairedUsers.some(u => u.user_id === user.value))
                    : mechanics.filter(
                        mechanic =>
                          (selectedUserPairOption === USER_PAIR_OPTIONS.ONEI ? !mechanic.planit_user_id : !mechanic.onei_user_id) &&
                          !pairedUsers.some(u => u.user_id === mechanic.value)
                      )
                }
                noResultsMessage={t("no_options_available").message || "No options available"}
                name="user_id"
                placeholder={t("select_user").message || "Select User"}
                value={newPairedUser.user_id}
                onChange={this.handleChangeNewPairUser}
                selectOnBlur={false}
                clearable
                selection
                fluid
                search
              />
              <Dropdown
                options={preparedOptions.filter(option => !pairedUsers.some(u => u.pair_to === option.value))}
                noResultsMessage={t("no_options_available").message || "No options available"}
                name="pair_to"
                placeholder={t("pair_to").message || "Pair to"}
                loading={loadingUserPairOptions}
                value={newPairedUser.pair_to}
                onChange={this.handleChangeNewPairUser}
                selectOnBlur={false}
                clearable
                selection
                fluid
                search
              />
            </div>

            <Button onClick={this.handleAddPairOption} color="green" disabled={!newPairedUser.user_id || !newPairedUser.pair_to}>
              <Icon name="plus" />
              {t("pair_user").message || "Pair User"}
            </Button>
          </div>

          {pairUsersError && (
            <Message negative>
              <p>{pairUsersError}</p>
            </Message>
          )}
        </Modal.Content>

        <Modal.Actions>
          <Button onClick={this.handleCloseUserPairModal} className="PairUsersModal_close-btn" color="red">
            {t("close").message || "Close"}
          </Button>

          <Button onClick={this.handleSavePairUsers} loading={savingPairedUsers} className="PairUsersModal_save-btn" color="green" disabled={!pairedUsers.length}>
            {t("save").message || "Save"}
            <Icon name="checkmark" />
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  renderUsersTable = () => {
    const { displayedUsers, loadingUsers, rolesArray, dealersArray, locationsArray } = this.state;
    if (loadingUsers) {
      return (
        <div className="Table__loading Loader-Placeholder">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
          <section>{this.props.t("loading_users").message || "Loading users"}</section>
        </div>
      );
    } else {
      return <UsersTable users={displayedUsers} dealersArray={dealersArray} locationsArray={locationsArray} rolesArray={rolesArray} onRowClick={this.handleRowClick} />;
    }
  };

  canEditDeleteUsers = () => {
    const { user } = this.props.authState;

    if (isAdmin(user.role_id)) return true;

    return user.role_id === ROLES.DEALER_ADMIN && this.props.globalState.selectedDealer.can_add_users;
  };

  handleIsCombiUserChange = (_, { value: isCombiUserSelected }) => {
    this.setState({ isCombiUserSelected }, () => {
      this.searchUsers();
    });
  };

  render() {
    const {
      isSearchLoading,
      isUserAdmin,
      selectedDealer,
      selectedRoles,
      selectedStatus,
      selectedWebVersions,
      selectedMobileVersions,
      selectedCameraVersions,
      selectedActiveDateFrom,
      selectedActiveDateTo,
      roles,
      dealers,
      isSearchResultsOpen,
      isUserDetailVisible,
      detailViewMode,
      selectedUser,
      isSubmittingDetail,
      isSubmittingRole,
      isSubmittingDealerAndLocation,
      loadingUser,
      loadingUserMetrics,
      loadingUserDevices,
      loadingBrands,
      loadingVersions,
      webVersions,
      mobileVersions,
      cameraVersions,
      brandsList,
      userMetrics,
      userDevices,
      selectedLocation,
      loadingPlanningMechanicsLists,
      oneiPlanningMechanicsOptions,
      planItMechanicsOptions,
      loadingDMSUsers,
      dmsUsersOptions,
      isCombiUserSelected,
    } = this.state;

    const { t, authState } = this.props;

    let statusOptions = [
      {
        text: t("user_status_all").message || "All",
        value: 0,
      },
      {
        text: t("user_status_active").message || "Active",
        value: 1,
      },
      {
        text: t("user_status_inactive").message || "Inactive",
        value: 2,
      },
    ];

    const dealer = dealers?.find(d => d.id === selectedDealer);

    const pairUsersOptions = [
      {
        key: 1,
        text: t("pair_to_1i_automotive").message || "Pair to 1i Automotive",
        value: USER_PAIR_OPTIONS.ONEI,
        disabled: !dealer?.onei_planning_enabled,
      },
      {
        key: 2,
        text: t("pair_to_planit").message || "Pair to PlanIT",
        value: USER_PAIR_OPTIONS.PLANIT,
        disabled: !dealer?.planit_planning_enabled,
      },
      {
        key: 3,
        text: t("pair_to_dms").message || "Pair to DMS",
        value: USER_PAIR_OPTIONS.DMS,
      },
    ];

    const roleOptions =
      roles && roles.length > 0
        ? roles.map((a, key) => {
            return { text: a.name, value: a.id, key };
          })
        : [];

    let dealerOptions = [{ text: t("all_dealers").message || "All dealers", value: -1, key: "showAll" }];
    dealerOptions.push(
      ...(dealers && dealers.length > 0
        ? dealers.map((a, key) => {
            return { text: a.name, value: a.id, key };
          })
        : [])
    );

    let locationOptions = [{ text: t("all_locations").message || "All locations", value: -1, key: -1 }];

    if (this.state.selectedDealer === -1) {
      dealers.forEach(
        dealer => (locationOptions = locationOptions.concat(dealer.locations ? dealer.locations.map(l => ({ text: l.name, value: l.id, key: l.id })) : []))
      );
    } else {
      const dealer = dealers.find(dealer => dealer.id === this.state.selectedDealer);

      if (dealer && dealer.locations && dealer.locations.length > 0) {
        locationOptions = locationOptions.concat(
          dealer.locations.map(l => {
            return { text: l.name, value: l.id, key: l.id };
          })
        );
      }
    }

    if (isUserAdmin) {
      statusOptions.push({ text: t("user_status_deleted").message || "Deleted", value: 3 }, { text: t("user_status_archived").message || "Archived", value: 4 });
    }

    const combiUserOptions = [
      { value: "all", key: "all", text: t("All").message || "All" },
      { value: true, key: "combi", text: t("combi_users").message || "Combi users" },
      { value: false, key: "regular", text: t("regular_users").message || "Regular users" },
    ];

    return (
      <div className="Users">
        <SearchPortal>
          <Search
            className="-fullscreen -results-with-header"
            input={{
              icon: "search",
              iconPosition: "left",
              placeholder: t("start_searching_users").message || "Start searching users...",
            }}
            loading={isSearchLoading}
            showNoResults={true}
            noResultsMessage={t("no_results").message || "No results found"}
            open={false}
            onSearchChange={this.handleSearchChange}
            value={this.state.searchTerm}
            minCharacters={3}
            fluid
          />
          {isSearchResultsOpen && <Icon className="ExtendedSearchIcon" name="close" onClick={this.handleCloseSearchResults} />}
        </SearchPortal>

        <UserMenuActionsPortal>
          <Icon name="refresh" onClick={this.handleRefresh} />
        </UserMenuActionsPortal>

        <SubHeader>
          <Grid stackable className="SubHeader_content_filters -contained-large">
            <Grid.Row>
              <Grid.Column width={3} className="SubHeader_content_title_container">
                <h1 className="SubHeader_content_title">{t("users").message || "Users"}</h1>
                <span className="SubHeader_content_total_users">
                  {t("total").message || "Total"}: {this.state.displayedUsers.length}
                </span>
              </Grid.Column>

              <Grid.Column width={3}>
                <Can I="update" the="users">
                  <Button.Group basic className="pair-users-dropdown-container">
                    <Button className="pair-users-button">
                      <Icon name="linkify" color="green" /> {t("pair_users").message || "Pair users"}
                    </Button>
                    <Dropdown
                      button
                      className="button icon"
                      disabled={selectedLocation === -1 || selectedDealer === -1}
                      selectOnBlur={false}
                      floating
                      trigger={<></>}
                      value={0}
                      options={pairUsersOptions}
                      onChange={this.handleSelectPairOption}
                    />
                  </Button.Group>
                </Can>
              </Grid.Column>
              <Grid.Column width={3}>
                <Form>
                  <Form.Group widths="equal">
                    <Form.Field>
                      <Dropdown
                        placeholder={t("filter_by_role").message || "Filter by role"}
                        selection
                        fluid
                        search
                        options={roleOptions}
                        multiple={true}
                        value={selectedRoles}
                        onChange={this.handleRoleChange}
                      />
                    </Form.Field>
                  </Form.Group>
                </Form>
              </Grid.Column>
              <Grid.Column width={7}>
                <Form>
                  <Form.Group widths="equal">
                    {isAdmin(authState.user.role_id) && (
                      <Form.Field>
                        <Dropdown
                          placeholder={t("filter_by_dealer").message || "Filter by dealer"}
                          fluid
                          options={dealerOptions}
                          search
                          selection
                          value={selectedDealer}
                          onChange={this.handleDealerChange}
                        />
                      </Form.Field>
                    )}
                    <Form.Field>
                      <Dropdown
                        placeholder={t("filter_by_location").message || "Filter by location"}
                        selection
                        fluid
                        search
                        options={locationOptions}
                        value={selectedLocation}
                        onChange={this.handleLocationChange}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Dropdown
                        placeholder={t("filter_by_status").message || "Filter by status"}
                        fluid
                        options={statusOptions}
                        selection
                        value={selectedStatus}
                        onChange={this.handleStatusChange}
                      />
                    </Form.Field>
                  </Form.Group>
                </Form>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={16}>
                <Form>
                  <Form.Group widths="equal">
                    <Form.Field>
                      <Dropdown
                        fluid
                        search
                        multiple
                        selection
                        clearable
                        name="selectedWebVersions"
                        value={selectedWebVersions}
                        options={webVersions}
                        loading={loadingVersions}
                        onChange={this.handleVersionChange}
                        placeholder={t("filter_web_version").message || "Filter by web version"}
                      />
                    </Form.Field>

                    <Form.Field>
                      <Dropdown
                        fluid
                        search
                        multiple
                        selection
                        clearable
                        name="selectedMobileVersions"
                        value={selectedMobileVersions}
                        options={mobileVersions}
                        loading={loadingVersions}
                        onChange={this.handleVersionChange}
                        placeholder={t("filter_app_version").message || "Filter by app version"}
                      />
                    </Form.Field>

                    <Form.Field>
                      <Dropdown
                        fluid
                        search
                        multiple
                        selection
                        clearable
                        name="selectedCameraVersions"
                        value={selectedCameraVersions}
                        options={cameraVersions}
                        loading={loadingVersions}
                        onChange={this.handleVersionChange}
                        placeholder={t("filter_camera_version").message || "Filter by camera version"}
                      />
                    </Form.Field>
                    <Form.Field>
                      <DatePicker
                        isClearable
                        showYearDropdown
                        showMonthDropdown
                        dateFormat="dd-MM-yyyy"
                        name="selectedActiveDateFrom"
                        onChangeRaw={e => e.preventDefault()}
                        onChange={this.handleChangeActiveDateFrom}
                        placeholderText={t("select_active_date_from").message || "Select active date from"}
                        selected={selectedActiveDateFrom ? moment(selectedActiveDateFrom).toDate() : null}
                      />
                    </Form.Field>
                    <Form.Field>
                      <DatePicker
                        isClearable
                        showYearDropdown
                        showMonthDropdown
                        dateFormat="dd-MM-yyyy"
                        name="selectedActiveDateTo"
                        onChangeRaw={e => e.preventDefault()}
                        onChange={this.handleChangeActiveDateTo}
                        placeholderText={t("select_active_date_to").message || "Select active date to"}
                        selected={selectedActiveDateTo ? moment(selectedActiveDateTo).toDate() : null}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Dropdown
                        placeholder={t("filter_by_is_combi_user").message || "Filter by is combi user"}
                        fluid
                        options={combiUserOptions}
                        selection
                        value={isCombiUserSelected}
                        onChange={this.handleIsCombiUserChange}
                      />
                    </Form.Field>
                  </Form.Group>
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </SubHeader>

        <Grid className="-contained-large">
          <Grid.Column width={16}>{this.renderUsersTable()}</Grid.Column>
        </Grid>
        {isUserDetailVisible && (
          <UserDetail
            mode={detailViewMode}
            selectedUser={selectedUser}
            roleOptions={roleOptions}
            dealers={dealers}
            currentUser={authState.user}
            metrics={userMetrics}
            devices={userDevices}
            loading={isSubmittingDetail || isSubmittingRole || isSubmittingDealerAndLocation}
            loadingUser={loadingUser}
            loadingMetrics={loadingUserMetrics}
            loadingDevices={loadingUserDevices}
            loadingBrands={loadingBrands}
            brandsList={brandsList}
            t={t}
            onHideDetail={this.handleHideDetailView}
            onReinstateUser={this.handleReinstateUser}
            isReinstatingUser={this.state.isReinstatingUser}
            onDeleteUser={this.handleDeleteUser}
            onCancelDeleteUser={this.handleCancelDeleteUser}
            isDeletingUser={this.state.isDeletingUser}
            deletingUserError={this.state.deletingUserError}
            onSetDDO={this.handleSetDealerDataOfficer}
            isSettingUserAsDPO={this.state.isSettingUserAsDPO}
            onGetMetrics={this.getMetrics}
            onGetDevices={this.getDevices}
            onSave={this.handleSave}
            errorMessage={this.state.errorMessage}
            selectedLocation={selectedLocation}
            getMechanicsPlanningLists={this.getMechanicsPlanningLists}
            loadingPlanningMechanicsLists={loadingPlanningMechanicsLists}
            oneiPlanningMechanicsOptions={oneiPlanningMechanicsOptions}
            planItMechanicsOptions={planItMechanicsOptions}
            getDMSUsers={this.getDMSUsers}
            dmsUsers={dmsUsersOptions}
            loadingDMSUsers={loadingDMSUsers}
          />
        )}

        {this.renderUserPairModal()}
        {this.canEditDeleteUsers() && <FloatingActions items={[]} onRootClick={this.handleNewUser} />}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { authState: state.auth, globalState: state.global };
};

const mapDispatchToProps = dispatch => {
  return {
    setAlert: alertOptions => dispatch(setAlert(alertOptions)),
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Users));
