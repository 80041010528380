import axios from "axios";

import ENV from "../../util/env-config";
import { getSessionCookie } from "../../util/common";

class Service {
  static requestConfig() {
    return {
      headers: {
        Authorization: `Bearer ${getSessionCookie()}`,
      },
    };
  }

  static searchAccounts(requestBody) {
    return axios.post(`${ENV.exactBase}/accounts/search`, requestBody, this.requestConfig());
  }
}

export default Service;
