import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button, Modal, Popup } from "semantic-ui-react";
import AlertContainer from "react-alert";
import moment from "moment";
import ReactTable, { ReactTableDefaults } from "react-table";

import { UserInput } from "../../components";

import Service from "./service";

class WebhooksErrorLog extends Component {
  state = {
    page: 0,
    nb_pages: 0,
    errorsList: [],
    isLoading: false,
  };

  getListOfErrors = page => {
    const { isLoading } = this.state;
    const { showWebhooksErrorLog } = this.props;

    if (!page) page = 1;
    else if (page === this.state.page || isLoading) return;

    this.setState({ isLoading: true, page }, async () => {
      try {
        const response = await Service.listErrors({ page, webhook_id: showWebhooksErrorLog });
        const { items, nb_pages = 0 } = response?.data?.data || {};
        this.setState({ errorsList: items ? items : [], nb_pages, isLoading: false });
      } catch (err) {
        const errorMsg = typeof err?.response?.data === "string" ? err.response.data : err.response.data?.errors[0];
        this.setState({ isLoading: false }, () => {
          this.msg.show(errorMsg, { type: "error" });
        });
      }
    });
  };

  renderAlert = () => {
    const props = {
      offset: 20,
      position: "top center",
      theme: "light",
      time: 2000,
      transition: "fade",
    };

    return <AlertContainer ref={a => (this.msg = a)} {...props} />;
  };

  renderTable = () => {
    const { errorsList, page, nb_pages, isLoading } = this.state;
    const { t } = this.props;

    return (
      <div className="WebhooksErrorLogTable">
        <ReactTable
          className="ReactTable -floated-table -contained-large"
          minRows={0}
          manual
          page={page - 1}
          pages={nb_pages === null ? -1 : nb_pages}
          data={errorsList}
          showPagination={nb_pages > 1}
          sortable={false}
          resizable={false}
          showPageSizeOptions={false}
          defaultPageSize={errorsList.length}
          pageSize={errorsList.length}
          ofText={t("of").message || "of"}
          nextText={t("next").message || "Next"}
          pageText={t("page").message || "Page"}
          previousText={t("previous").message || "Previous"}
          loading={isLoading}
          loadingText={t("loading").message || "Loading"}
          onFetchData={state => this.getListOfErrors(state.page + 1)}
          noDataText={
            !isLoading && (
              <div className="Table__no-results">
                <p>{t("no_data").message || "No data"}</p>
              </div>
            )
          }
          renderPageJump={({ onChange, onBlur, onKeyPress, inputType, pageJumpText }) => (
            <div className="-pageJump">
              <UserInput
                aria-label={pageJumpText}
                type={inputType}
                onChange={evt => {
                  onChange(evt);

                  const newPage = Number(evt.target.value);
                  if (!Number.isNaN(newPage) && newPage > 0 && newPage <= nb_pages) this.getListOfErrors(newPage);
                }}
                value={page}
                onBlur={onBlur}
                onKeyPress={onKeyPress}
              />
            </div>
          )}
          column={{
            ...ReactTableDefaults.column,
            headerClassName: "ReactTable__column-header",
            className: "ReactTable__column",
          }}
          columns={[
            {
              id: "created_on",
              Header: t("date").message || "Date",
              accessor: d => moment(d.created_on).format("DD-MM-YYYY HH:mm:ss"),
              maxWidth: 200,
            },
            {
              id: "reason",
              Header: t("reason").message || "Reason",
              accessor: d => <Popup wide="very" trigger={<span>{d.reason}</span>} content={<div>{d.reason}</div>} />,
            },
          ]}
        />
      </div>
    );
  };

  render() {
    const { t, showWebhooksErrorLog, onToggleErrorLog } = this.props;

    return (
      <Modal open={!!showWebhooksErrorLog} onClose={() => onToggleErrorLog()} closeOnDimmerClick={false}>
        <Modal.Header>{t("error_log").message || "Error log"}</Modal.Header>
        <Modal.Content scrolling>{this.renderTable()}</Modal.Content>
        <Modal.Actions>
          <Button color="grey" onClick={() => onToggleErrorLog()}>
            {t("close").message || "Close"}
          </Button>
        </Modal.Actions>

        {this.renderAlert()}
      </Modal>
    );
  }
}

export default withTranslation()(WebhooksErrorLog);
