import axios from "axios";

import ENV from "../../util/env-config";
import { getSessionCookie } from "../../util/common";

class Service {
  static requestConfig() {
    return {
      headers: {
        Authorization: `Bearer ${getSessionCookie()}`,
        Pragma: "no-cache",
      },
    };
  }

  static getCustomers(requestData) {
    return axios.post(ENV.dealerBaseUrl + "/customers/list", requestData, this.requestConfig());
  }

  static getCustomerDetails(requestData) {
    return axios.post(ENV.dealerBaseUrl + "/customers/service_details", requestData, this.requestConfig());
  }

  static searchCustomers(requestData) {
    return axios.post(ENV.dealerBaseUrl + "/customers/search", requestData, this.requestConfig());
  }

  static deleteCustomer(id) {
    return axios.post(ENV.dealerBaseUrl + "/customers/delete", { id: id }, this.requestConfig());
  }

  static updateCustomer(requestData) {
    return axios.post(ENV.dealerBaseUrl + "/customers/update", requestData, this.requestConfig());
  }
}

export default Service;
