import axios from "axios";

import ENV from "../util/env-config";
import { getSessionCookie } from "../util/common";

class Service {
  static requestConfig() {
    return {
      headers: {
        Authorization: `Bearer ${getSessionCookie()}`,
      },
    };
  }

  static getWOWidget(requestData) {
    let config = this.requestConfig();
    return axios.post(`${ENV.dealerBaseUrl}/servicebox/widget/wo`, requestData, config);
  }

  static getCarWidget(requestData) {
    let config = this.requestConfig();
    return axios.post(`${ENV.dealerBaseUrl}/servicebox/widget/car`, requestData, config);
  }

  static getCarGarantURL(params) {
    return ENV.carGarantBase + "/app/?" + params;
  }
}

export default Service;
