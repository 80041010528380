import React, { Component } from "react";
import { Grid, Search, Modal, Button, Icon, Popup } from "semantic-ui-react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Loader from "react-loader-spinner";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/pro-regular-svg-icons";

import { SubHeader, SearchPortal, UserMenuActionsPortal } from "../../components";
import { getDealerAndLocationById, getLocationById } from "../../util/common";
import { setAlert } from "../App/store";
import { getAppointment, deselectAppointment, getChecks, applyUpdateToAppointment, handleUpdateAppointments, APPOINTMENTS_ACTION_TYPES } from "../Appointments/store";
import { getCar, deselectCar, CARS_ACTION_TYPES } from "../Cars/store";
import AppointmentDetail from "../Appointments/AppointmentDetail";
import CarDetail from "../Cars/CarDetail";
import BackOrderFilters from "./BackOrderFilters";
import BackOrderTable from "./BackOrderTable";

import Service from "./service";

import "./BackorderDashboard.scss";

class BackOrderLeads extends Component {
  state = {
    items: [],
    page: 0,
    nb_pages: null,
    nb_items: null,
    isLoading: false,
    isLoadingBackorders: false,
    isAppointmentDetailVisible: false,
    selectedAppointment: null,
    isCarDetailVisible: false,
    selectedCar: null,
    searchTerm: "",
    selectedCarInfo: {},
    exportURL: "",
    exportModalOpen: false,
    isLoadingExport: false,
    exportError: false,
    selectedDealersIDs: [],
    selectedLocationsIDs: [],
  };

  componentDidUpdate = prevProps => {
    const { selectedAppointment, selectedCar } = this.state;
    const { appointmentsState, carsState, setAlert, t } = this.props;

    if (appointmentsState.actionType !== prevProps.appointmentsState.actionType) {
      if (selectedAppointment) {
        if (appointmentsState.actionType === APPOINTMENTS_ACTION_TYPES.GET_APPOINTMENT_FAIL) {
          setAlert({ type: "error", title: "Cannot load this appointment" });
          this.setState({ selectedAppointment: null, isLoading: false });
        }

        if (appointmentsState.actionType === APPOINTMENTS_ACTION_TYPES.GET_APPOINTMENT_SUCCESS) this.setState({ isAppointmentDetailVisible: true, isLoading: false });

        if (appointmentsState.actionType === APPOINTMENTS_ACTION_TYPES.WEB_SOCKET_APPOINTMENTS_UPDATE && appointmentsState.webSocketUpdate) {
          this.socketAppointmentUpdate(appointmentsState.webSocketEvent);
        }
      }
    }

    if (carsState.actionType !== prevProps.carsState.actionType) {
      if (selectedCar) {
        if (carsState.actionType === CARS_ACTION_TYPES.GET_CAR_FAIL) {
          this.setState({ selectedCar: null, isLoading: false }, () => setAlert({ type: "error", title: t("can_not_load_this_car").message || "Can not load this car" }));
        } else if (carsState.actionType === CARS_ACTION_TYPES.GET_CAR_NOT_AUTHORIZED) {
          this.setState({ selectedCar: null, isLoading: false }, () =>
            setAlert({ type: "error", title: t("car_location_no_access_err_msg").message || "This car has moved to a location you cannot access" })
          );
        }

        if (carsState.actionType === CARS_ACTION_TYPES.GET_CAR_SUCCESS) {
          if (!carsState.selectedCar) {
            this.setState({ selectedCar: null, isLoading: false });
          } else {
            this.setState({
              isLoading: false,
              isCarDetailVisible: true,
              selectedCarInfo: {
                car: carsState.selectedCar,
                customer: carsState.selectedCustomer,
                appointmentHistory: carsState.selectedCarAppointments,
                snoozedQuestions: carsState.selectedCarSnoozedQuestions,
              },
            });
          }
        }
      }
    }
  };

  socketAppointmentUpdate = event => {
    let updatePayload = null;
    if (event.payload?.data) updatePayload = JSON.parse(event.payload.data);

    // apply update to appointmen list and appointment detail page if open
    this.props.applyUpdateToAppointment(updatePayload, true);
  };

  normalizeResults = response => {
    const data = response?.data?.data ? response.data.data : { items: [], nb_pages: 0, nb_items: 0 };

    if (!Array.isArray(data.items)) data.items = [];
    if (!data.nb_pages) data.nb_pages = 0;
    if (!data.nb_items) data.nb_items = 0;

    return data;
  };

  getBackOrders = () => {
    const { page, searchTerm, selectedDealersIDs, selectedLocationsIDs } = this.state;

    const params = {
      page,
      dealer_ids: selectedDealersIDs,
      location_ids: selectedLocationsIDs,
      search_term: searchTerm,
    };

    return Service.getBackOrders(params);
  };

  getItems = page => {
    if (!page) page = 1;
    else if (page === this.state.page || this.state.isLoadingBackorders) return;

    this.setState({ isLoadingBackorders: true, page }, async () => {
      const { selectedLocationsIDs, selectedDealersIDs } = this.state;

      if (!selectedLocationsIDs.length && !selectedDealersIDs.length) {
        this.setState({ items: [], nb_pages: 0, nb_items: 0, isLoadingBackorders: false });
        return;
      }

      try {
        const response = await this.getBackOrders();
        const { items, nb_pages, nb_items } = this.normalizeResults(response);

        this.setState({ items, page, nb_pages, nb_items, isLoadingBackorders: false });
      } catch (error) {
        console.log("Error getting leads", error);
        this.setState({ isLoadingBackorders: false });
        this.props.setAlert({ type: "error", title: this.props.t("cant_load_backorder_leads").message || "Can't load back order leads" });
        return;
      }
    });
  };

  handleChangeSelectedLocationsIDs = ({ location_ids, dealer_ids }) => {
    this.setState({ selectedLocationsIDs: location_ids, selectedDealersIDs: dealer_ids }, () => {
      this.getItems();
    });
  };

  handleCloseExportModal = () => this.setState({ exportURL: "", exportError: false, isLoadingExport: false, exportModalOpen: false });

  handleExportBackOrder = () => {
    const { searchTerm, selectedLocationsIDs } = this.state;

    const params = {
      location_ids: selectedLocationsIDs,
      search_term: searchTerm,
    };

    return Service.exportBackOrders(params);
  };

  handleExport = () => {
    this.setState({ exportModalOpen: true, isLoadingExport: true }, async () => {
      try {
        const response = await this.handleExportBackOrder();

        const exportURL = response?.data?.data.url || "";
        this.setState({ exportURL, isLoadingExport: false });
      } catch (error) {
        this.setState({ isLoadingExport: false, exportError: true });
      }
    });
  };

  handleRefresh = () => {
    this.getItems();
  };

  handleSearchChange = (_e, data) => {
    this.setState({ searchTerm: data.value }, () => this.getItems());
  };

  renderAppointmentDetail = () => {
    if (!this.state.isAppointmentDetailVisible || !this.props.appointmentsState.selectedAppointment) return null;

    const { authState, appointmentsState, globalState, getChecks, handleUpdateAppointments } = this.props;
    const { dealer, location } = getDealerAndLocationById(globalState.dealers, appointmentsState.selectedAppointment.dealer_location_id);

    return (
      <AppointmentDetail
        detailPageOnlineUsers={appointmentsState.detailPageOnlineUsers}
        currentUser={authState.user}
        appointment={appointmentsState.selectedAppointment}
        appointmentChecks={appointmentsState.selectedAppointmentChecks}
        location={location}
        dealer={dealer}
        getChecks={getChecks}
        onRegistrationClick={car_id => this.handleCarClick(car_id, appointmentsState.selectedAppointment.dealer_location_id)}
        onClose={this.handleCloseAppointmentDetail}
        onHandleUpdateAppointments={handleUpdateAppointments}
      />
    );
  };

  handleAppointmentClick = appointment => {
    this.setState({ selectedAppointment: { id: appointment.id, dealer_location_id: appointment.dealer_location_id }, isLoading: true }, () =>
      this.props.getAppointment(appointment.id)
    );
  };

  handleUpdateSelectedAppointmentFromCarDetail = appointment_id => {
    this.setState({ selectedAppointment: { appointment_id, dealer_location_id: this.state.selectedCar.dealer_location_id }, isLoading: true }, () =>
      this.props.getAppointment(appointment_id)
    );
  };

  handleCloseAppointmentDetail = () => {
    this.setState({ selectedAppointment: null, isAppointmentDetailVisible: false });
    this.props.deselectAppointment();
  };

  handleCarClick = (car_id, dealer_location_id) => {
    this.setState(
      {
        selectedCar: { car_id, dealer_location_id },
        isLoading: true,
        isAppointmentDetailVisible: false,
        selectedAppointment: null,
      },
      () => {
        this.props.getCar(car_id);
      }
    );
  };

  handleCloseCarDetail = () => {
    this.setState({ selectedCar: null, isCarDetailVisible: false, selectedCarInfo: {} });
    this.props.deselectCar();
  };

  renderCarDetail = () => {
    const { selectedCarInfo } = this.state;
    const { globalState } = this.props;

    const location = getLocationById(globalState.dealers, selectedCarInfo.car.dealer_location_id);

    return (
      <CarDetail
        visible={true}
        car={selectedCarInfo.car}
        customer={selectedCarInfo.customer}
        appointmentHistory={selectedCarInfo.appointmentHistory}
        snoozedQuestions={selectedCarInfo.snoozedQuestions}
        location={location}
        onHide={() => this.handleCloseCarDetail()}
        onSelectAppointment={id => this.handleUpdateSelectedAppointmentFromCarDetail(id)}
      />
    );
  };

  render() {
    const {
      isAppointmentDetailVisible,
      isCarDetailVisible,
      isLoading,
      items,
      page,
      nb_pages,
      nb_items,
      isLoadingBackorders,
      selectedLocationsIDs,
      isLoadingExport,
      exportURL,
      exportModalOpen,
      exportError,
    } = this.state;

    const { authState, t } = this.props;
    const { csv_download } = authState.user;

    return (
      <div className="Backorders">
        <SearchPortal>
          <Search
            minCharacters={4}
            className="-large-search"
            input={{
              icon: "search",
              iconPosition: "left",
              placeholder: t("start_searching_backorders").message || "Search back orders by WO or registration...",
            }}
            loading={false}
            showNoResults={false}
            onSearchChange={_.debounce(this.handleSearchChange, 500)}
            fluid
          />
        </SearchPortal>
        <UserMenuActionsPortal>
          <Icon name="refresh" onClick={this.handleRefresh} />
        </UserMenuActionsPortal>
        <SubHeader>
          <Grid.Column width={16} className="header-container">
            <h1>{t("backorders_dashboard").message || "Backorders Dashboard"}</h1>

            <div className="export-button-container">
              {csv_download && (
                <Popup
                  position="top right"
                  content={t("backorder_export_disabled").message || "Export disabled as no location is selected."}
                  disabled={selectedLocationsIDs.length === 1}
                  trigger={
                    <div style={{ display: "inline-block" }}>
                      <Button onClick={this.handleExport} disabled={!selectedLocationsIDs.length} className="export-button" floated="right" basic color="green">
                        <span style={{ marginRight: "5px" }}>{t("export").message || "Export"}</span>
                        <FontAwesomeIcon icon={faFileExport} />
                      </Button>
                    </div>
                  }
                />
              )}
            </div>

            <Modal size="tiny" open={exportModalOpen} onClose={this.handleCloseExportModal} closeOnDimmerClick={false} className="ExportLeads-Modal">
              <Modal.Content className="export-modal-content">
                {isLoadingExport && <p>{t("export_in_progress").message || "Export in progress..."}</p>}
                {!isLoadingExport && exportURL && <p>{t("export_ready").message || "Export ready to be downloaded"}</p>}
                {!isLoadingExport && (exportError || !exportURL) && <p>{t("failed_error_message").message || "Something went wrong, please try again."}</p>}
              </Modal.Content>
              <Modal.Actions>
                <Button onClick={this.handleCloseExportModal} color="grey">
                  {t("close").message || "Close"}
                </Button>
                <Button onClick={() => window.open(exportURL, "_blank")} disabled={isLoadingExport || !exportURL} color="green">
                  {t("download").message || "Download"}
                </Button>
              </Modal.Actions>
            </Modal>
          </Grid.Column>
          <Grid.Column width={16}>
            <BackOrderFilters onChangeSelectedLocationIDs={this.handleChangeSelectedLocationsIDs} />
          </Grid.Column>
        </SubHeader>

        <BackOrderTable
          page={page}
          items={items}
          pages={nb_pages}
          nb_items={nb_items}
          loading={isLoadingBackorders}
          onFetchData={this.getItems}
          onCarClick={this.handleCarClick}
          onAppointmentClick={this.handleAppointmentClick}
          showLocationColumn={selectedLocationsIDs.length > 1 || (selectedLocationsIDs.length > 0 && selectedLocationsIDs[0] === "all")}
        />

        {isAppointmentDetailVisible && this.renderAppointmentDetail()}
        {isCarDetailVisible && this.renderCarDetail()}

        {(isLoading || isLoadingBackorders) && (
          <div className="Loader">
            <Loader type="Oval" color="#46923d" height="100" width="100" />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { globalState: state.global, appointmentsState: state.appointments, carsState: state.cars, authState: state.auth };
};

const mapDispatchToProps = dispatch => {
  return {
    applyUpdateToAppointment: (updates, skipMainTableUpdate) => dispatch(applyUpdateToAppointment(updates, skipMainTableUpdate)),
    deselectAppointment: () => dispatch(deselectAppointment()),
    deselectCar: () => dispatch(deselectCar()),
    getAppointment: appID => dispatch(getAppointment(appID)),
    getCar: carID => dispatch(getCar(carID)),
    getChecks: appID => dispatch(getChecks(appID)),
    handleUpdateAppointments: appointment => dispatch(handleUpdateAppointments(appointment)),
    setAlert: alertOptions => dispatch(setAlert(alertOptions)),
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(BackOrderLeads));
