import React, { Component } from "react";
import ReactTable, { ReactTableDefaults } from "react-table";
import { connect } from "react-redux";
import moment from "moment";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import { Dropdown, Button, Grid, Icon, Search, Popup } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import AlertContainer from "react-alert";

import NewsDetail from "./NewsDetail";
import { EnforceUserLocations } from "../Users/roles";
import { SearchPortal, SubHeader, TourGuide, UserInput } from "./../../components";
import { setAlert } from "../App/store";
import { isAdmin } from "../../util/common";

import Service from "./service";

import "./index.scss";

class News extends Component {
  state = {
    news: [],
    categories: [],
    selectedDealers: [],
    selectedLocations: [],
    selectedCategories: [],
    selectedPublishedDate: null,
    selectedArticleIndex: null,
    isLoadingNews: false,
    isNewsDetailVisible: false,
    isLoading: false,
    showTourGuide: false,
    isSubmitted: false,
    searchTerm: "",
    page: 0,
    pages: null,
    dealerOptions: [],
    locationOptions: [],
  };

  alertRef = React.createRef();

  componentDidMount() {
    this.getCategories();
    this.getNews();

    const dealerOptions = [];
    const locationOptions = [];

    this.props.globalState.dealers.forEach(dealer => {
      dealerOptions.push({ key: dealer.id, value: dealer.id, text: dealer.name });

      if (dealer.locations?.length) {
        const { locations } = EnforceUserLocations(this.props.authState.user, dealer.locations);
        locations.forEach(location => locationOptions.push({ key: location.id, value: location.id, text: location.name, dealer_id: dealer.id }));
      }
    });

    this.setState({ dealerOptions, locationOptions });
  }

  getCategories = () => {
    this.setState({ isLoading: true }, () => {
      Service.getCategories()
        .then(response => {
          let categories = response?.data?.data?.categories || [];

          categories = categories.map(cat => ({ text: this.props.t(cat.name).message || cat.name, value: cat.id, key: cat.id }));

          if (response?.data?.data) this.setState({ categories, isLoading: false });
        })
        .catch(err => {
          this.setState({ isLoading: false });
          console.error("Error getting news categories", err);
          this.props.setAlert({
            type: "error",
            title: err.message,
          });
        });
    });
  };

  getNews = page => {
    const { selectedPublishedDate, selectedDealers, selectedLocations, selectedCategories, searchTerm } = this.state;

    if (!page) page = 1;
    else if (page === this.state.page || this.state.loadingNews) return;

    const requestData = {
      page,
      search_term: searchTerm || null,
      dealer_ids: selectedDealers?.length > 0 ? selectedDealers : null,
      location_ids: selectedLocations?.length > 0 ? selectedLocations : null,
      category_ids: selectedCategories?.length > 0 ? selectedCategories : null,
      language_code: localStorage.getItem("locale"),
      published_date: selectedPublishedDate ? selectedPublishedDate : null,
    };

    this.setState({ isLoadingNews: true }, () => {
      Service.getArticles(requestData)
        .then(response => {
          const { articles: news, nb_pages: pages } = response?.data?.data ? response.data.data : { articles: [], pages: null };
          news.sort((a, b) => (a.created_on > b.created_on ? -1 : 1));
          news.forEach(n => {
            if (n.publish_date) n.publish_date = moment(n.publish_date);
            if (n.expire_date) n.expire_date = moment(n.expire_date);
          });

          this.setState({ news, pages, page, isLoadingNews: false });
        })
        .catch(err => {
          this.setState({ news: [], isLoadingNews: false });
          console.error("Error getting news articles.", err);
          this.props.setAlert({
            type: "error",
            title: err.message,
          });
        });
    });
  };

  handleShowNewsDetail = options => {
    this.setState({
      detailViewMode: options.mode,
      isNewsDetailVisible: true,
      selectedArticleIndex: options.mode === "edit" ? options.index : null,
    });
  };

  handleHideNewsDetail = () => this.setState({ isNewsDetailVisible: false, selectedArticleIndex: null });

  handleSearchChange = (_e, data) => this.setState({ searchTerm: data.value }, () => this.getNews());

  handleChangeDropdowns = (e, data) => this.setState({ [data.name]: data.value }, () => this.getNews());

  handleChangeDate = date => {
    let selectedPublishedDate = null;

    if (date) selectedPublishedDate = moment(date).format("YYYY-MM-DDTHH:mm:ssZ");

    this.setState({ selectedPublishedDate }, () => this.getNews());
  };

  handleToggle = selectedArticle => {
    let { news, selectedArticleIndex } = this.state;

    const { user } = this.props.authState;

    selectedArticle.disabled = !selectedArticle.disabled;
    selectedArticle.category_id = selectedArticle.category_id || null;

    this.setState({ isLoading: true }, () => {
      Service.updateArticle(selectedArticle)
        .then(result => {
          const newArticle = {
            ...selectedArticle,
            updated_by_id: user.id,
            updated_by: {
              id: user.id,
              first_name: user.first_name,
              last_name: user.last_name,
            },
          };

          news[selectedArticleIndex] = newArticle;

          this.setState({
            news: [...news],
            isLoading: false,
            isNewsDetailVisible: false,
            selectedArticleIndex: null,
          });
        })
        .catch(err => {
          console.error("Error disabling article.", err);
          this.setState({
            isLoading: false,
          });
          this.props.setAlert({
            type: "error",
            title: err.message,
          });
        });
    });
  };

  hasSubmitError = article =>
    !article.title || (!article.location_ids?.length && !article.dealer_ids?.length && !article.visible_to_all) || !article.selector || !article.content;

  submitArticle = selectedArticle => {
    if (this.hasSubmitError(selectedArticle)) {
      this.setState({ isSubmitted: true });
      return;
    }
    this.setState({ isLoading: true }, () => {
      let { detailViewMode, news, selectedArticleIndex } = this.state;
      const { user } = this.props.authState;
      let publish_date = null,
        expire_date = null;

      if (selectedArticle.publish_date) publish_date = moment(selectedArticle.publish_date).format("YYYY-MM-DDTHH:mm:ss") + "Z";
      if (selectedArticle.expire_date) expire_date = moment(selectedArticle.expire_date).format("YYYY-MM-DDTHH:mm:ss") + "Z";

      selectedArticle.category_id = selectedArticle.category_id || null;

      if (detailViewMode === "create") {
        Service.addArticle({ ...selectedArticle, publish_date, expire_date })
          .then(result => {
            const newArticle = {
              ...selectedArticle,
              id: result.data?.data.article_id || 0,
              user_id: user.id,
              user: {
                id: user.id,
                first_name: user.first_name,
                last_name: user.last_name,
              },
              stripe_closed_count: 0,
              stripe_opened_count: 0,
            };

            news = [newArticle, ...news];
            this.setState({
              news,
              isNewsDetailVisible: false,
              selectedArticleIndex: null,
              isLoading: false,
              isSubmitted: false,
            });
          })
          .catch(err => {
            console.error("Error creating article.", err.response);
            // it would be better to write something like The size exceeds configured limit and to put the limit
            if (err?.response?.data?.errors?.length) {
              if (err.response.data.errors[0].includes("Data too long")) err.message = this.props.t("content_too_big").message || "The content is too big";
              else err.message = err.response.data.errors[0];
            } else err.message = this.props.t("failed_error_message").message || "Something went wrong, please try again.";

            this.setState({ isLoading: false, isSubmitted: false }, () => {
              this.alertRef.show(err.message, { type: "error" });
            });
          });
      } else {
        Service.updateArticle({ ...selectedArticle, updated_by_id: user.id, publish_date, expire_date })
          .then(result => {
            const newArticle = {
              ...selectedArticle,
              updated_by_id: user.id,
              user: {
                id: user.id,
                first_name: user.first_name,
                last_name: user.last_name,
              },
            };

            news[selectedArticleIndex] = newArticle;

            this.setState({
              news,
              isNewsDetailVisible: false,
              selectedArticleIndex: null,
              isLoading: false,
              isSubmitted: false,
            });
          })
          .catch(err => {
            console.error("Error updating article.", err);
            this.setState({ isLoading: false, isSubmitted: false }, () => {
              if (err?.response?.data?.errors?.length) err.message = err.response.data.errors[0];

              this.alertRef.show(err.message, { type: "error" });
            });
          });
      }
    });
  };

  closeTourGuide = () => {
    this.setState({ showTourGuide: false, selectedArticleIndex: null });
  };

  getDealers = dealer_ids => {
    const { dealers } = this.props.globalState;

    if (!dealer_ids?.length) return null;

    const names = [];

    dealer_ids.forEach(did => {
      for (let i = 0; i < dealers.length; i++) {
        if (did === dealers[i].id) {
          names.push(dealers[i].name);
          break;
        }
      }
    });

    return names.join(", ");
  };

  getLocations = location_ids => {
    const { dealers } = this.props.globalState;

    if (!location_ids?.length) return null;

    const names = [];

    location_ids.forEach(lid => {
      for (let i = 0; i < dealers.length; i++) {
        if (dealers[i].locations?.length) {
          const location = dealers[i].locations.find(l => l.id === lid);
          if (location) {
            names.push(location.name);
            break;
          }
        }
      }
    });

    return names.join(", ");
  };

  renderFilters = () => {
    const { t } = this.props;
    const { dealerOptions, locationOptions, selectedDealers, selectedLocations, selectedPublishedDate, selectedCategories, categories } = this.state;

    return (
      <Grid className="NewsPageFilters">
        <Grid.Column width={4}>
          <Dropdown
            fluid
            search
            multiple
            selection
            clearable
            name="selectedDealers"
            value={selectedDealers}
            options={dealerOptions}
            onChange={this.handleChangeDropdowns}
            placeholder={t("select_dealer").message || "Select dealer"}
          />
        </Grid.Column>

        <Grid.Column width={4}>
          <Dropdown
            fluid
            search
            multiple
            selection
            clearable
            name="selectedLocations"
            value={selectedLocations}
            options={locationOptions}
            onChange={this.handleChangeDropdowns}
            placeholder={t("select_location").message || "Select location"}
          />
        </Grid.Column>

        <Grid.Column width={4}>
          <DatePicker
            isClearable
            showYearDropdown
            showMonthDropdown
            dateFormat="dd-MM-yyyy"
            onChangeRaw={e => e.preventDefault()}
            onChange={date => this.handleChangeDate(date)}
            placeholderText={t("select_date").message || "Select date"}
            selected={selectedPublishedDate ? moment(selectedPublishedDate).toDate() : null}
          />
        </Grid.Column>

        <Grid.Column width={4}>
          <Dropdown
            fluid
            search
            multiple
            selection
            clearable
            name="selectedCategories"
            value={selectedCategories}
            options={categories}
            onChange={this.handleChangeDropdowns}
            placeholder={t("select_category").message || "Select category"}
          />
        </Grid.Column>
      </Grid>
    );
  };

  renderTable = () => {
    const { isLoadingNews, news, categories, pages, page } = this.state;
    const { t } = this.props;
    const { user } = this.props.authState;

    return (
      <div className="NewsTable">
        <ReactTable
          className={`ReactTable -floated-table -contained-large ${!news?.length && "-no-data"}`}
          data={news}
          showPagination={pages > 1}
          page={page - 1}
          pages={pages === null ? -1 : pages}
          pageSize={news?.length || 0}
          renderPageJump={({ onChange, value, onBlur, onKeyPress, inputType, pageJumpText }) => (
            <div className="-pageJump">
              <UserInput
                aria-label={pageJumpText}
                type={inputType}
                onChange={evt => {
                  onChange(evt);
                  let newPage = evt.target.value - 0;
                  if (!Number.isNaN(newPage) && newPage > 0) {
                    if (newPage > pages) newPage = pages;
                    this.getNews(newPage);
                  }
                }}
                value={value}
                onBlur={onBlur}
                onKeyPress={onKeyPress}
              />
            </div>
          )}
          noDataText={
            <div className="Table__no-results">
              <Icon disabled name="newspaper" style={{ fontSize: "1.75em" }} />
              <p>{t("no_news").message || "No news"}</p>
            </div>
          }
          showPageSizeOptions={false}
          sortable={false}
          resizable={false}
          nextText={t("next").message || "Next"}
          previousText={t("previous").message || "Previous"}
          pageText={t("page").message || "Page"}
          ofText={t("of").message || "of"}
          loading={isLoadingNews}
          manual
          onFetchData={(state, instance) => this.getNews(state.page + 1)}
          onPageChange={() => {
            try {
              document.querySelector(".App__module").scrollTo(0, 0);
            } catch (e) {
              document.querySelector(".App__module").scrollTop = 0; // IE Fix
            }
          }}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              onClick: e => {
                this.setState({ showTourGuide: true, selectedArticleIndex: rowInfo.index });
              },
            };
          }}
          column={{
            ...ReactTableDefaults.column,
            headerClassName: "ReactTable__column-header",
            className: "ReactTable__column",
          }}
          columns={[
            {
              id: "index",
              Cell: row => {
                return (
                  <div className="news-table-count" style={{ backgroundColor: row.original.disabled ? "red" : "green" }}>
                    {row.index + 1}
                  </div>
                );
              },
              maxWidth: 70,
            },
            {
              id: "title",
              Header: t("title").message || "Title",
              accessor: "title",
              minWidth: 250,
            },
            {
              id: "dealers",
              Header: t("dealers").message || "Dealers",
              accessor: d => {
                if (d.visible_to_all) return <span className="dealers-locations-rd">{t("all_dealers").message || "All dealers"}</span>;
                return <span className="dealers-locations-rd">{this.getDealers(d.dealer_ids)}</span>;
              },
            },
            {
              id: "locations",
              Header: t("locations").message || "Locations",
              accessor: d => {
                if (d.visible_to_all) return <span className="dealers-locations-rd">{t("all_locations").message || "All locations"}</span>;
                return <span className="dealers-locations-rd">{this.getLocations(d.location_ids)}</span>;
              },
            },
            {
              id: "publishDate",
              Header: t("publish_date").message || "Publish date",
              accessor: d => {
                if (d.publish_date) return moment(d.publish_date).format("DD-MM-YYYY");
              },
            },
            {
              id: "expireDate",
              Header: t("expire_date").message || "Expire date",
              accessor: d => {
                if (d.expire_date) return moment(d.expire_date).format("DD-MM-YYYY");
              },
            },
            {
              id: "category",
              Header: t("category").message || "Category",
              accessor: d => {
                if (d.category_id) {
                  const category = categories.find(cat => cat.key === d.category_id);
                  if (category) return <span className="news-category-column">{t(category.text).message || category.text}</span>;
                }
              },
            },
            {
              id: "Published",
              Header: t("published_by").message || "Published by",
              accessor: d => (
                <span>
                  <strong>{`  ${d.user.first_name} ${d.user.last_name}`}</strong>
                </span>
              ),
            },
            {
              id: "stripe_opened_count",
              Header: (
                <Popup
                  content={t("stripe_opened_count").message || "Stripe opened count"}
                  trigger={<div className="-text-elipsis">{t("stripe_opened_count").message || "Stripe opened count"}</div>}
                />
              ),
              accessor: d => <div style={{ textAlign: "center" }}>{d.stripe_opened_count}</div>,
            },
            {
              id: "stripe_closed_count",
              Header: (
                <Popup
                  content={t("stripe_closed_count").message || "Stripe closed count"}
                  trigger={<div className="-text-elipsis">{t("stripe_closed_count").message || "Stripe closed count"}</div>}
                />
              ),
              accessor: d => <div style={{ textAlign: "center" }}>{d.stripe_closed_count}</div>,
            },
            {
              id: "edit_article",
              className: "edit-article-rd",
              Cell: row => {
                return (
                  <div className="edit-article-btn-container">
                    <Button
                      className="edit-article-btn"
                      color="green"
                      disabled={news[row.index].user_id !== user.id && !isAdmin(user.role_id)}
                      onClick={e => {
                        e.stopPropagation();
                        this.handleShowNewsDetail({ index: row.index, mode: "edit" });
                      }}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </Button>
                  </div>
                );
              },
            },
          ]}
        />
      </div>
    );
  };

  renderAlert = () => {
    const props = {
      offset: 20,
      position: "top right",
      theme: "light",
      time: 2000,
      transition: "fade",
    };

    return <AlertContainer ref={a => (this.alertRef = a)} {...props} />;
  };

  render() {
    const { isNewsDetailVisible, detailViewMode, news, categories, selectedArticleIndex, showTourGuide, isLoading, isSubmitted } = this.state;
    const { user } = this.props.authState;
    const { t } = this.props;

    return (
      <div>
        <SearchPortal>
          <Search
            fluid
            minCharacters={3}
            className="-large-search"
            input={{
              icon: "search",
              iconPosition: "left",
              placeholder: t("search_news").message || "Search by title or author",
            }}
            loading={isLoading}
            showNoResults={false}
            onSearchChange={_.debounce(this.handleSearchChange, 500)}
          />
        </SearchPortal>
        <SubHeader>
          <Grid.Column width={14}>
            <h1>{t("news").message || "News"}</h1>
          </Grid.Column>
          <Grid.Column width={2} floated="right">
            <Button color="green" icon labelPosition="left" fluid onClick={() => this.handleShowNewsDetail({ mode: "create" })}>
              <Icon name="add" /> {t("add").message || "Add"}
            </Button>
          </Grid.Column>

          {this.renderFilters()}
        </SubHeader>

        {this.renderTable()}

        {isNewsDetailVisible && (
          <NewsDetail
            isSubmitted={isSubmitted}
            mode={detailViewMode}
            article={selectedArticleIndex !== null ? news[selectedArticleIndex] : null}
            onSave={this.submitArticle}
            onToggle={this.handleToggle}
            onHide={this.handleHideNewsDetail}
            renderAlert={this.renderAlert}
            isLoading={isLoading}
            isUserAdmin={isAdmin(user.role_id)}
            categories={categories}
          />
        )}

        {showTourGuide && <TourGuide article={news[selectedArticleIndex]} closeTourGuide={this.closeTourGuide} />}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    authState: state.auth,
    globalState: state.global,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setAlert: alertOptions => dispatch(setAlert(alertOptions)),
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(News));
