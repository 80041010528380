import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Button, Grid, Icon } from "semantic-ui-react";

import { ScrollGrid, LocationDetail } from "../../components";
import { setAlert, updateLocation as globalLocationUpdate } from "../App/store";
import StatusEdit from "./StatusEdit";

import Service from "./service";

import "./LocationOverview.css";

class LocationOverview extends Component {
  state = {
    dealerLocations: [],
    showEditStatusModal: false,
    selectedLocation: { ...this.props.globalState.selectedLocation },
    showEditLocationModal: false,
    newLocation: "",
    isLoading: false,
    submitLocation: false,
    languages: [],
    thresholdsValid: true,
  };

  componentDidMount() {
    this.getLanguages();

    const locations = this.props.globalState.selectedDealer ? this.props.globalState.selectedDealer.locations : [];
    this.setState({ dealerLocations: locations });
  }

  handleChangeSelectedLocation = selectedLocation => {
    this.setState({ selectedLocation });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.globalState.selectedLocation.id !== this.props.globalState.selectedLocation.id) {
      this.setState({ selectedLocation: this.props.globalState.selectedLocation });
    }
  }

  getLanguages = () => {
    this.setState({ isLoading: true }, () => {
      Service.getLanguages()
        .then(result => {
          if (result?.data?.data) {
            this.setState({ languages: result.data.data, isLoading: false });
          }
        })
        .catch(error => {
          console.error("Error getting languages.", error);
          this.setState({ languages: [], isLoading: false });
        });
    });
  };

  getLocationName = id => {
    const { globalState } = this.props;
    let name = "";
    if (globalState && globalState.selectedDealer && globalState.selectedDealer.locations && globalState.selectedDealer.locations.length > 0) {
      let location = globalState.selectedDealer.locations.find(loc => loc.id === id);
      if (location) {
        name = location.name;
      }
    }
    return name;
  };

  handleEditStatuses = () => {
    this.setState({
      showEditStatusModal: true,
    });
  };

  handleStatusChange = selectedLocation => {
    this.setState({ isLoading: true }, () => {
      Service.updateLocation(selectedLocation)
        .then(() => {
          this.setState({ isLoading: false, showEditStatusModal: false, selectedLocation }, () => this.props.globalLocationUpdate(selectedLocation));
        })
        .catch(error => {
          this.setState({ isLoading: false });
          console.error("Error saving statuses.", error);
        });
    });
  };

  handleLocationChange = location => {
    this.setState({ submitLocation: true });

    if (!this.state.thresholdsValid) return;

    const updatedLocation = { ...location, keylocker_code: Number(location.keylocker_code) };

    if (!updatedLocation.language_code || !updatedLocation.name || location.keylocker_code.length < 4 || !updatedLocation.commercial_name) {
      return;
    }

    if (location.timeslots) delete updatedLocation.timeslots;

    this.setState({ isLoading: true, submitLocation: false }, () => {
      if (!updatedLocation.photo) {
        this.updateLocation(updatedLocation);
        this.props.globalLocationUpdate(updatedLocation);
        return;
      }

      let fd = new FormData();
      fd.append("file", updatedLocation.photo);
      Service.uploadLogo(fd)
        .then(result => {
          if (result?.data?.data?.url) updatedLocation.logo = result.data.data.url;

          this.updateLocation(updatedLocation);
          this.props.globalLocationUpdate(updatedLocation);
        })
        .catch(error => {
          console.error("Error updating location.", error);
          this.setState({ isLoading: false });
        });
    });
  };

  updateLocation = location => {
    Service.updateLocation(location)
      .then(() => {
        this.setState({ isLoading: false });
      })
      .catch(error => {
        console.error("Error updating location.", error);
        this.setState({ isLoading: false });
      });
  };

  handleCloseStatusEditModal = () => {
    this.setState({ showEditStatusModal: false });
  };

  handleUpdateThresholdValid = thresholdsValid => {
    this.setState({ thresholdsValid });
  };

  renderLoading = () => {
    return (
      <div className="Table__loading Loader-Placeholder">
        <div className="bounce1" />
        <div className="bounce2" />
        <div className="bounce3" />
        <section>{this.props.t("loading").message || "Loading"}</section>
      </div>
    );
  };

  render() {
    const { selectedLocation, languages, submitLocation, isLoading } = this.state;

    return (
      <ScrollGrid
        containerClassName="LocationOverview"
        headerHeight={51}
        header={
          <Grid className="SubHeader_content_filters -no-padding -no-margin">
            <Grid.Row className="-no-padding -no-margin">
              <Grid.Column width={2} className="titleSelector">
                <h2>{this.props.t("edit_location").message || "Edit Location"}</h2>
              </Grid.Column>
              <Grid.Column width={2} className="titleSelector">
                <Button
                  compact
                  color="green"
                  icon
                  labelPosition="left"
                  onClick={() => {
                    this.handleEditStatuses();
                  }}
                >
                  <Icon name="angle double down" />
                  {this.props.t("statuses").message || "Statuses"}
                </Button>
              </Grid.Column>
              <Grid.Column width={12}>
                <Button floated="right" color="green" onClick={() => this.handleLocationChange(selectedLocation)} loading={isLoading} disabled={isLoading}>
                  {this.props.t("save").message || "SAVE"}
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        }
      >
        <Grid className="-contained-large">
          <Grid.Row className="-no-padding -no-margin">
            <Grid.Column className="-no-padding -no-margin">
              <LocationDetail
                size="small"
                t={this.props.t}
                user={this.props.authState.user}
                loading={isLoading}
                languages={languages}
                isGeneralSettingsHidden={true}
                onChangeSelectedLocation={this.handleChangeSelectedLocation}
                location={selectedLocation}
                isSubmit={submitLocation}
                onUpdateThresholdValid={this.handleUpdateThresholdValid}
              />
            </Grid.Column>
          </Grid.Row>

          {this.state.showEditStatusModal && (
            <StatusEdit isLoading={isLoading} discard={this.handleCloseStatusEditModal} dealerLocation={selectedLocation} handleStatusChange={this.handleStatusChange} />
          )}
        </Grid>
      </ScrollGrid>
    );
  }
}

const mapStateToProps = state => {
  return { globalState: state.global, authState: state.auth };
};

const mapDispatchToProps = dispatch => {
  return {
    setAlert: alertOptions => dispatch(setAlert(alertOptions)),
    globalLocationUpdate: location => dispatch(globalLocationUpdate(location)),
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(LocationOverview));
