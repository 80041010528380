import React from "react";
import { Icon, Label } from "semantic-ui-react";
import { PIN_ORDER, PIN_TYPE } from "./enum";

import "./Pin.scss";

export const renderPinTypeIcon = pinItem => {
  switch (pinItem) {
    case 2:
      return <Icon name="shield" />;
    case 3:
      return <Icon name="registered outline" />;
    default:
      return <Icon name="pin" className="icon-margin-left" />;
  }
};

export const renderPinTypeIconWithColor = (location, pin) => {
  let color = "blue";

  if (pin.pin_type_id === PIN_TYPE.WARRANTY) {
    if (location.is_warranty_pin_support_warning_enabled && !pin.support_nr) color = "red";
    else if (location.is_warranty_pin_claim_warning_enabled && !pin.claim_nr) color = "orange";
  } else if (pin.pin_type_id === PIN_TYPE.RECALL) {
    if (location.is_recall_pin_support_warning_enabled && !pin.support_nr) color = "red";
    else if (location.is_recall_pin_claim_warning_enabled && !pin.claim_nr) color = "orange";
  }

  switch (pin.pin_type_id) {
    case PIN_TYPE.WARRANTY:
      return (
        <Label color={color}>
          <Icon name="shield" style={{ margin: "0px" }} />
        </Label>
      );
    case PIN_TYPE.RECALL:
      return (
        <Label color={color}>
          <Icon name="registered outline" style={{ margin: "0px" }} />
        </Label>
      );
    default:
      return (
        <Label color={color}>
          <Icon name="pin" style={{ margin: "0px" }} />
        </Label>
      );
  }
};

export const getShopIconColor = orderStatus => {
  switch (orderStatus) {
    case PIN_ORDER.TO_ORDER:
      return "blue";
    case PIN_ORDER.SAME_DAY:
      return "green";
    case PIN_ORDER.NEXT_DAY:
      return "orange";
    case PIN_ORDER.BACK_ORDER:
      return "red";
    case PIN_ORDER.ARRIVED:
      return "yellow";
    default:
      return "";
  }
};

export const isWarrantyPinSignatureMissing = (appointment, location) => {
  if (!location.is_warranty_pin_signature_missing_warning_enabled || !appointment.warranty_pin_signature_missing_count) return false;
  if (!location.warranty_pin_signature_brands?.length) return true;

  return location.warranty_pin_signature_brands.some(b => b.vin_prefix && appointment.vin.startsWith(b.vin_prefix));
};
