import React from "react";
import { Dropdown, Form, Checkbox, Button, Icon } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import moment from "moment";
import DatePicker from "react-datepicker";

import DealerLocationsDropdown from "../../../components/DealerLocationsDropdown";
import { QUESTION_RESULT_STATUS } from "../../Appointments/common";
import { SNOOZE_TYPE } from "../../../components/Snooze/enum";

const SnoozeFilters = ({
  onChangeCustomDates,
  customDateFrom,
  customDateTo,
  selectedSnoozedType,
  onChangeSnoozedType,
  selectedCustomerApproved,
  onChangeCustomerApproved,
  selectedStatuses,
  onChangeStatus,
  onChangeSelectedLocationIDs,
  t,
  tags,
  questions,
  onChangeTags,
  selectedTags,
  onChangeQuestions,
  selectedQuestions,
  onChangeInterventions,
  selectedInterventions,
  isLoadingTags,
  selectedSnoozedStatus,
  onChangeSnoozedStatus,
  isLoadingSnoozeOptions,
  snoozeStatusOptions,
  snoozeTypeOptions,
  selectedSnoozedDepartment,
  onChangeSnoozedDepartment,
  snoozeDepartmentOptions,
  onChangeIsLease,
  selectedIsLease,
  moreFiltersVisible,
  shouldReset,
  dealer,
}) => {
  const leaseOptions = [
    {
      key: "lease",
      text: t("lease").message || "Lease",
      value: true,
    },
    {
      key: "no_lease",
      text: t("no_lease").message || "No lease",
      value: false,
    },
  ];

  const dropdownOptions = options =>
    Object.keys(options)
      .filter(
        key =>
          !snoozeTypeOptions[key] ||
          (Number(key) === SNOOZE_TYPE.NEXT_VISIT_CUSTOMER && dealer.is_snooze_type_next_visit_customer_enabled) ||
          (Number(key) === SNOOZE_TYPE.MAKE_OFFER && dealer.is_snooze_type_make_offer_enabled) ||
          (Number(key) === SNOOZE_TYPE.MAKE_NEW_APPOINTMENT && dealer.is_snooze_type_make_new_appointment_enabled) ||
          (Number(key) === SNOOZE_TYPE.REMIND_CUSTOMER && dealer.is_snooze_type_remind_customer_enabled)
      )
      .map(key => ({ key, value: Number(key), text: t(options[key]).message || options[key] }));

  return (
    <Form>
      <Form.Group>
        <Form.Field width={5}>
          <DealerLocationsDropdown selectAll={false} selectDealers={true} onChange={onChangeSelectedLocationIDs} shouldReset={shouldReset} />
        </Form.Field>

        <Form.Field width={3} style={{ position: "relative" }}>
          <DatePicker
            selectsRange
            startDate={customDateFrom}
            endDate={customDateTo}
            value={`${moment(customDateFrom).format("DD-MM-YYYY")}${customDateTo ? " - " + moment(customDateTo).format("DD-MM-YYYY") : ""}`}
            onChange={onChangeCustomDates}
            maxDate={moment(customDateFrom).add(12, "M").toDate()}
            shouldCloseOnSelect={false}
          />
        </Form.Field>

        <>
          <Form.Field width={2} style={{ textAlign: "center" }}>
            <Button.Group className="status-group">
              <Button
                icon
                active={selectedStatuses.includes(QUESTION_RESULT_STATUS.OK_WITH_REMARKS)}
                style={{
                  color: "white",
                  backgroundColor: selectedStatuses.includes(QUESTION_RESULT_STATUS.OK_WITH_REMARKS) ? "green" : "#b7b7b7",
                  border: selectedStatuses.includes(QUESTION_RESULT_STATUS.OK_WITH_REMARKS) ? "1px solid green" : "1px solid grey",
                  padding: "8.3px",
                }}
                onClick={() => {
                  onChangeStatus(QUESTION_RESULT_STATUS.OK_WITH_REMARKS);
                }}
              >
                <Icon name="file outline" />
              </Button>
              <Button
                icon
                active={selectedStatuses.includes(QUESTION_RESULT_STATUS.ADVISED)}
                style={{
                  color: "white",
                  backgroundColor: selectedStatuses.includes(QUESTION_RESULT_STATUS.ADVISED) ? "orange" : "#b7b7b7",
                  border: selectedStatuses.includes(QUESTION_RESULT_STATUS.ADVISED) ? "1px solid orange" : "1px solid grey",
                  padding: "8.3px",
                }}
                onClick={() => {
                  onChangeStatus(QUESTION_RESULT_STATUS.ADVISED);
                }}
              >
                <Icon name="attention" />
              </Button>
              <Button
                icon
                active={selectedStatuses.includes(QUESTION_RESULT_STATUS.NECESSARY)}
                style={{
                  color: "white",
                  backgroundColor: selectedStatuses.includes(QUESTION_RESULT_STATUS.NECESSARY) ? "red" : "#b7b7b7",
                  border: selectedStatuses.includes(QUESTION_RESULT_STATUS.NECESSARY) ? "1px solid red" : "1px solid grey",
                  padding: "8.3px",
                }}
                onClick={() => {
                  onChangeStatus(QUESTION_RESULT_STATUS.NECESSARY);
                }}
              >
                <Icon name="dont" />
              </Button>
            </Button.Group>
          </Form.Field>

          <Form.Field width={3} className="leads-toggle">
            <Checkbox
              toggle
              checked={selectedCustomerApproved}
              label={t("customer_approved").message || "Customer Approved"}
              onChange={(e, data) => onChangeCustomerApproved(data.checked)}
            />
          </Form.Field>
        </>
        <Form.Field width={3}>
          <Dropdown
            className="wide-dropdown"
            selectOnBlur={false}
            placeholder={t("company_type").message || "Company type"}
            value={selectedIsLease}
            onChange={(e, data) => onChangeIsLease(data.value)}
            selection
            fluid
            clearable
            options={leaseOptions}
          />
        </Form.Field>
      </Form.Group>

      {moreFiltersVisible && (
        <Form.Group>
          <>
            {dealer.is_snooze_department_enabled && (
              <Form.Field width={3}>
                <Dropdown
                  selectOnBlur={false}
                  placeholder={t("department").message || "Department"}
                  value={selectedSnoozedDepartment}
                  onChange={(e, data) => onChangeSnoozedDepartment(data.value)}
                  selection
                  fluid
                  clearable
                  options={dropdownOptions(snoozeDepartmentOptions)}
                />
              </Form.Field>
            )}

            {dealer.is_snooze_status_enabled && (
              <Form.Field width={3}>
                <Dropdown
                  selectOnBlur={false}
                  placeholder={isLoadingSnoozeOptions ? t("loading").message || "Loading..." : t("snooze_status").message || "Snooze Status"}
                  value={selectedSnoozedStatus}
                  onChange={(e, data) => onChangeSnoozedStatus(data.value)}
                  selection
                  clearable
                  fluid
                  options={[...dropdownOptions(snoozeStatusOptions), { key: "0", value: 0, text: t("deleted").message || "Deleted" }]}
                />
              </Form.Field>
            )}

            <Form.Field width={3}>
              <Dropdown
                className="wide-dropdown"
                selectOnBlur={false}
                placeholder={isLoadingSnoozeOptions ? t("loading").message || "Loading..." : t("task").message || "Task"}
                value={selectedSnoozedType}
                onChange={(e, data) => onChangeSnoozedType(data.value)}
                selection
                clearable
                fluid
                options={dropdownOptions(snoozeTypeOptions)}
              />
            </Form.Field>
          </>

          <>
            <Form.Field width={2}>
              <Dropdown
                fluid
                multiple
                selection
                clearable
                selectOnBlur={false}
                value={selectedTags}
                onChange={(e, data) => onChangeTags(data.value)}
                placeholder={isLoadingTags ? t("loading").message || "Loading..." : t("tags").message || "Tags"}
                options={tags.map(tag => ({
                  text: tag.name,
                  value: tag.id,
                }))}
              />
            </Form.Field>
            <Form.Field width={3}>
              <Dropdown
                fluid
                search
                multiple
                selection
                clearable
                selectOnBlur={false}
                value={selectedQuestions}
                onChange={(e, data) => onChangeQuestions(data.value)}
                placeholder={t("questions").message || "Questions"}
                options={questions}
              />
            </Form.Field>

            <Form.Field width={2} className="leads-toggle">
              <Checkbox
                toggle
                checked={selectedInterventions}
                label={t("interventions").message || "Interventions"}
                onChange={(e, data) => onChangeInterventions(data.checked)}
              />
            </Form.Field>
          </>
        </Form.Group>
      )}
    </Form>
  );
};

export default withTranslation()(SnoozeFilters);
