import React from "react";
import _ from "lodash";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList, ReferenceLine } from "recharts";
import moment from "moment";

const TYRE_MOTION_REPORT_COLORS = ["#278ecf", "#4bd762", "#ff402c", "#ff9416", "#d42ae8", "#535ad7", "#83bfff", "#ffca1f", "#6edb8f", "#ffe366", "#ffc266", "#8784db"];

class TyreMotion extends React.Component {
  transformData = data => {
    const groupedData = _.groupBy(data, item => `${item.year}-${String(item.month).padStart(2, "0")}`);

    return Object.entries(groupedData).map(([dateKey, items]) => {
      const monthLabel = moment(`${dateKey}-01`, "YYYY-MM-DD").format("MMM YYYY");

      const dataPoint = {
        month: monthLabel,
        rawData: items,
      };

      items.forEach(item => {
        dataPoint[item.location] = item.usage;
        dataPoint[`${item.location}_limit`] = item.monthly_limit;
        dataPoint[`${item.location}_percent`] = Math.round((item.usage / item.monthly_limit) * 100);
        dataPoint[`${item.location}_label`] = `${item.usage}/${item.monthly_limit}`;
      });

      return dataPoint;
    });
  };

  renderCustomTooltip = ({ active, payload, label }) => {
    if (!active || !payload?.length) return null;

    const { t } = this.props;
    const firstItem = payload[0].payload;

    return (
      <div className="TyreMotionReport__tooltip">
        <div className="TyreMotionReport__tooltip-label">{label}</div>

        {payload.map((entry, index) => {
          const location = entry.dataKey;
          const usage = firstItem[location];
          const limit = firstItem[`${location}_limit`];
          const percent = firstItem[`${location}_percent`];

          return (
            <div key={index} className="TyreMotionReport__tooltip-row">
              <div className="TyreMotionReport__tooltip-content">
                <span className="TyreMotionReport__tooltip-location" style={{ color: entry.color }}>
                  {location}:
                </span>
                <span className="TyreMotionReport__tooltip-value">
                  {t("usage").message || "Usage"}: {usage} / {limit} ({percent}%)
                </span>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  renderCustomLabel = props => {
    const { x, y, width, value } = props;
    return (
      <text x={x + width / 2} y={y - 6} fill="#666" textAnchor="middle" fontSize={11}>
        {value}
      </text>
    );
  };

  render() {
    const { report, t } = this.props;

    if (!report.report_data?.length) {
      return (
        <>
          <h3 className="report-heading">{t(report.report_name).message || report.report_name}</h3>
          <p className="no-data">{t("no_data").message || "No data"}</p>
        </>
      );
    }

    const data = report.report_data.map(d => JSON.parse(d));
    const transformedData = this.transformData(data);
    const uniqueLocations = Array.from(new Set(data.map(item => item.location)));

    return (
      <>
        <h3 className="report-heading">{t(report.report_name).message || report.report_name}</h3>
        <div className="TyreMotionReport__chart">
          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={transformedData} margin={{ top: 30, right: 30, left: 20, bottom: 20 }} barSize={35} barGap={5}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis label={{ value: t("requests").message || "Requests", angle: -90, position: "insideLeft" }} allowDecimals={false} />
              <Tooltip content={this.renderCustomTooltip} />
              <Legend />

              {uniqueLocations.map((location, index) => (
                <Bar key={location} dataKey={location} name={location} fill={TYRE_MOTION_REPORT_COLORS[index % TYRE_MOTION_REPORT_COLORS.length]}>
                  <LabelList dataKey={`${location}_label`} position="top" content={this.renderCustomLabel} />
                </Bar>
              ))}

              {uniqueLocations.map((location, index) => {
                const locationData = data.find(item => item.location === location);
                if (!locationData) return null;

                const limit = locationData.monthly_limit;
                const color = TYRE_MOTION_REPORT_COLORS[index % TYRE_MOTION_REPORT_COLORS.length];

                return (
                  <ReferenceLine
                    key={`limit-${location}`}
                    y={limit}
                    stroke={color}
                    strokeDasharray="3 3"
                    label={{
                      value: `${location} limit: ${limit}`,
                      fill: color,
                      fontSize: 12,
                      position: "insideBottomRight",
                    }}
                  />
                );
              })}
            </BarChart>
          </ResponsiveContainer>
        </div>
      </>
    );
  }
}

export { TyreMotion };
