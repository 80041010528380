import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Form, Dropdown, Header, Icon, Segment } from "semantic-ui-react";

const TYRE_MOTION_AI_PLAN = {
  DISABLED: 0,
  TWO_HUNDRED_RPM: 200,
  FIVE_HUNDRED_RPM: 500,
  THOUSAND_RPM: 1000,
  TWO_THOUSAND_RPM: 2000,
};

class TyreMotionConfiguration extends Component {
  handleDropdownChange = (_e, { value: tyre_motion_ai_plan_id }) => {
    const { location, onLocationChange } = this.props;
    onLocationChange({ ...location, tyre_motion_ai_plan_id });
  };

  render() {
    const { location, t } = this.props;

    const planOptions = Object.entries(TYRE_MOTION_AI_PLAN).map(([key, value]) => ({
      key: value,
      value: value,
      text:
        value === TYRE_MOTION_AI_PLAN.DISABLED
          ? t("tyre_motion_ai_plan_disabled").message || "Tyre Motion AI Disabled"
          : t("tyre_motion_ai_plan_requests", { count: value }).message || `${value} requests per month`,
    }));

    return (
      <Segment>
        <Header as="h5">
          <Icon name="settings" />
          <Header.Content>{t("tyre_motion_ai_plan").message || "Tyre Motion AI Plan"}</Header.Content>
        </Header>

        <Form>
          <Form.Field>
            <Dropdown options={planOptions} onChange={this.handleDropdownChange} value={location.tyre_motion_ai_plan_id} selection selectOnBlur={false} fluid />
          </Form.Field>
        </Form>
      </Segment>
    );
  }
}

export default withTranslation()(TyreMotionConfiguration);
