import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Button, Modal } from "semantic-ui-react";
import AlertContainer from "react-alert";

import { getCar } from "./../Cars/store";
import CustomerDetail from "./CustomerDetail.js";
import CustomerEntities from "./CustomerEntities.js";

import Service from "./service";

class CustomerModal extends Component {
  state = {
    customer: {},
    loadingCustomerDetail: false,
    isUpdatingCustomer: false,
  };

  alertRef = React.createRef();

  componentDidMount() {
    this.getCustomerDetails();
  }

  getCustomerDetails = () => {
    this.setState({ loadingCustomerDetail: true }, () => {
      Service.getCustomerDetails({ id: this.props.customerID })
        .then(response => {
          this.setState({
            loadingCustomerDetail: false,
            customer: response.data.data.customer,
          });
        })
        .catch(error => {
          this.setState({ loadingCustomerDetail: false }, () => {
            const errorMsg = error?.response?.data?.errors?.length ? error.response.data.errors[0] : "Error while getting customer details please try again";

            this.alertRef.show(errorMsg, {
              time: 4000,
              type: "error",
            });
          });
        });
    });
  };

  handeChangeCheckbox = (_e, { name, checked }) => {
    this.setState({ customer: { ...this.state.customer, [name]: checked } });
  };

  handleChangePreferredComunicationChannel = preferred_communication_channel => {
    this.setState({ customer: { ...this.state.customer, preferred_communication_channel } });
  };

  handleUpdateCustomer = () => {
    const { id, block_communication, is_internal, preferred_communication_channel } = this.state.customer;

    this.setState({ isUpdatingCustomer: true }, async () => {
      try {
        await Service.updateCustomer({ id, block_communication, is_internal, preferred_communication_channel });
        this.setState({ isUpdatingCustomer: false, customer: {} }, () => this.props.onClose());
      } catch (error) {
        console.error("Failed to update customer", error);
        const errorMsg = error?.response?.data?.errors?.length ? error.response.data.errors[0] : "Error while updating customer please try again";

        this.setState({ isUpdatingCustomer: false }, () => {
          this.alertRef.show(errorMsg, {
            time: 4000,
            type: "error",
          });
        });
      }
    });
  };

  handleCarClick = id => {
    if (!id) return;

    this.props.getCar(id);
  };

  renderAlertCustomerModal = () => {
    const props = {
      offset: 20,
      position: "top right",
      theme: "light",
      time: 2000,
      transition: "fade",
    };

    return <AlertContainer ref={a => (this.alertRef = a)} {...props} />;
  };

  renderDetailsAndEntities = () => {
    const { customer, loadingCustomerDetail, isUpdatingCustomer } = this.state;
    const { location, onClose, t } = this.props;

    let name = "";
    if (customer.title) name = customer.title;

    if (customer.firstname) name += " " + customer.firstname;

    if (customer.surname) name += " " + customer.surname;

    name = name.trim();
    if (!name) name = customer.company;

    return (
      <Modal
        open={true}
        size="large"
        onClose={onClose}
        closeOnDimmerClick={false}
        style={{
          paddingTop: 0,
          margin: 0,
        }}
      >
        <Modal.Header>
          <span>{name}</span>
          <Button floated="right" color="green" onClick={onClose}>
            {t("close").message || "CLOSE"}
          </Button>
        </Modal.Header>
        <Modal.Content scrolling>
          <div className="customer_sys">
            <div className="customer_sys_info">
              <strong>DMS :</strong>
              {customer.dms_nr}
            </div>
            <div className="customer_sys_info">
              <strong>CRM :</strong>
              {customer.customer_crm_nr ? customer.customer_crm_nr : "-"}
            </div>
            {customer.dealer_name && (
              <div className="customer_sys_info">
                <strong>{customer.dealer_name}</strong>
              </div>
            )}
            {customer.location_name && (
              <div className="customer_sys_info">
                <strong>{customer.location_name}</strong>
              </div>
            )}
          </div>

          {loadingCustomerDetail && (
            <div className="Loader-Placeholder">
              <div className="bounce1"></div>
              <div className="bounce2"></div>
              <div className="bounce3"></div>
              <section>{t("loading_customer").message || "Loading customer"}</section>
            </div>
          )}

          {!loadingCustomerDetail && (
            <>
              <CustomerDetail
                customer={customer}
                isUpdatingCustomer={isUpdatingCustomer}
                loading={loadingCustomerDetail}
                onChangeCheckbox={this.handeChangeCheckbox}
                onChangePreferredCommunicationChannel={this.handleChangePreferredComunicationChannel}
              ></CustomerDetail>
              <CustomerEntities
                appointments={customer.appointments}
                cars={customer.cars}
                onCarClick={this.handleCarClick}
                loading={loadingCustomerDetail}
                location={location}
              ></CustomerEntities>
            </>
          )}
        </Modal.Content>

        <Modal.Actions>
          <Button onClick={this.handleUpdateCustomer} color="green" loading={isUpdatingCustomer} disabled={isUpdatingCustomer || loadingCustomerDetail}>
            {t("save").message || "Save"}
          </Button>
        </Modal.Actions>
        {this.renderAlertCustomerModal()}
      </Modal>
    );
  };

  render() {
    return this.renderDetailsAndEntities();
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getCar: carID => dispatch(getCar(carID)),
  };
};

export default withTranslation()(connect(null, mapDispatchToProps)(CustomerModal));
