import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Grid, List } from "semantic-ui-react";

import { GLOBAL_ACTION_TYPES } from "../App/store.js";
import { FloatingActions, SubHeader } from "../../components";
import Service from "./service";
import ServiceModal from "./ServiceModal.js";
import "./style.scss";

class CheckInService extends Component {
  state = {
    isModalVisible: false,
    service: {},
    isLoading: false,
    tags: [],
    brands: [],
    tagOptions: [],
    brandOptions: [],
    loadingBrands: false,
    isLoadingDealerServices: false,
    originalDealerServices: [],
    dealerServices: [],
    originalLocationServices: [],
    isLoadingLocationServices: false,
    locationServices: [],
    currentList: "",
    mode: "",
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.globalState.actionType === GLOBAL_ACTION_TYPES.DEALER_SELECTED) this.getDealerServices();

    if (nextProps.globalState.actionType === GLOBAL_ACTION_TYPES.LOCATION_SELECTED) this.getLocationServices();
  }

  componentDidMount() {
    this.getBrands();
    this.getDealerServices();
    this.getLocationServices();
    this.getTags();
  }

  getDealerServices = () => {
    this.setState({ isLoadingDealerServices: true }, () => {
      Service.listDealerServices({ dealer_id: this.props.globalState.selectedDealer.id })
        .then(result => {
          if (result?.data?.data?.check_in_services) {
            let services = result.data.data.check_in_services;
            this.setState({
              isLoadingDealerServices: false,
              dealerServices: services,
            });
          }
        })
        .catch(error => {
          console.error("Error getting dealer services.", error);
          this.setState({ isLoadingDealerServices: false });
          let message = error.response.data && error.response.data.errors && error.response.data.errors.length > 0 ? error.response.data.errors[0] : error.message;
          this.props.setAlert({ type: "error", title: message });
        });
    });
  };

  getTags = () => {
    this.setState({ isLoading: true }, async () => {
      try {
        let tags,
          tagOptions = [];

        const response = await Service.getTags();

        if (response?.data?.data?.tags) {
          tags = response.data.data.tags.filter(tag => tag.system);
          tagOptions = tags.map(tag => ({ key: tag.id, value: tag.id, text: tag.name }));
        }

        this.setState({ tags, tagOptions, isLoading: false });
      } catch (err) {
        console.error("Failed to get tags", err);
        this.setState({ isLoading: false });
      }
    });
  };

  getLocationServices = () => {
    this.setState({ isLoadingLocationServices: true }, () => {
      Service.listLocationServices({ dealer_location_id: this.props.globalState.selectedLocation.id })
        .then(result => {
          if (result?.data?.data?.check_in_services) {
            let services = result.data.data.check_in_services;
            this.setState({
              isLoadingLocationServices: false,
              locationServices: services,
            });
          }
        })
        .catch(error => {
          console.error("Error getting location services.", error);
          this.setState({ isLoadingLocationServices: false });
          let message = error.response.data && error.response.data.errors && error.response.data.errors.length > 0 ? error.response.data.errors[0] : error.message;
          this.props.setAlert({ type: "error", title: message });
        });
    });
  };

  getService = serviceID => {
    let reqData = { id: serviceID };
    this.setState({ isLoading: true }, () => {
      Service.viewService(reqData)
        .then(result => {
          if (result?.data?.data) {
            this.setState({ isLoading: false, isModalVisible: true, mode: "edit", service: result.data.data });
          }
        })
        .catch(error => {
          console.error("Error getting service", error);
          this.setState({ isLoading: false });
          let message = error.response.data && error.response.data.errors && error.response.data.errors.length > 0 ? error.response.data.errors[0] : error.message;
          this.props.setAlert({ type: "error", title: message });
        });
    });
  };

  getBrands = () => {
    this.setState(
      {
        loadingBrands: true,
      },
      () => {
        Service.getBrands()
          .then(result => {
            if (result?.data?.data?.brands) {
              let brands = result.data.data.brands;
              const brandOptions =
                brands && brands.length > 0
                  ? brands.map((a, key) => {
                      return { text: a.name, value: a.id, key };
                    })
                  : [];
              this.setState({ brands, isLoadingBrands: false, brandOptions });
            }
          })
          .catch(error => {
            console.error("Error loading brands.", error);
            this.setState({ brands: [], isLoadingBrands: false });
            let message = error.response.data && error.response.data.errors && error.response.data.errors.length > 0 ? error.response.data.errors[0] : error.message;
            this.props.setAlert({ type: "error", title: message });
          });
      }
    );
  };

  handleCloseServiceModal = () => {
    this.setState({
      isModalVisible: false,
    });
  };

  handleSubmitServiceModal = () => {
    this.setState(
      {
        isModalVisible: false,
      },
      () => {
        this.getDealerServices();
      }
    );
  };

  handleUpdateService = () => {
    this.setState(
      {
        isModalVisible: false,
      },
      () => {
        this.getLocationServices();
      }
    );
  };

  handleOpenCreateServiceModal = () => {
    this.setState({ isModalVisible: true, mode: "create", currentList: "" });
  };

  displayLoading = () => {
    return (
      <div className="Table__loading Loader-Placeholder">
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
        <section>{this.props.t("loading_services").message || "Loading services"}</section>
      </div>
    );
  };

  handleEditService = (id, list) => {
    this.setState({ currentList: list }, () => {
      this.getService(id);
    });
  };

  renderServiceModal = () => {
    let { isModalVisible, currentList, mode, locationServices, service, brands, tagOptions, brandOptions } = this.state;

    if (!isModalVisible) return null;

    return (
      <ServiceModal
        mode={mode}
        onClose={this.handleCloseServiceModal}
        onSubmit={this.handleSubmitServiceModal}
        list={currentList}
        service={service}
        brandOptions={brandOptions}
        tagOptions={tagOptions}
        brands={brands}
        dealer_id={this.props.globalState.selectedDealer.id}
        dealer_location_id={this.props.globalState.selectedLocation.id}
        onUpdateService={this.handleUpdateService}
        notification_email={locationServices?.find(s => s.id === service.id)?.notification_email || ""}
      />
    );
  };

  onDragStart = (ev, id) => {
    ev.dataTransfer.setData("Text", JSON.stringify(id));
  };

  onDragOver = ev => {
    ev.stopPropagation();
    ev.preventDefault();
  };

  onDrop = ev => {
    let id = parseInt(JSON.parse(ev.dataTransfer.getData("Text")), 10);

    const requestData = {
      id: id,
      dealer_location_id: this.props.globalState.selectedLocation.id,
    };

    Service.locationEnable(requestData)
      .then(result => {
        this.getLocationServices();
      })
      .catch(error => {
        console.error("error enabling service to location: ", error);
        let message = error.response.data && error.response.data.errors && error.response.data.errors.length > 0 ? error.response.data.errors[0] : error.message;
        this.props.setAlert({ type: "error", title: message });
      });
  };

  render() {
    let { tags, isLoadingDealerServices, isLoadingLocationServices, dealerServices, locationServices } = this.state;
    let { t } = this.props;

    let dealerLists =
      dealerServices && dealerServices.length > 0
        ? dealerServices
            .sort((a, b) => (a.id < b.id ? 1 : a.id > b.id ? -1 : 0))
            .map(item => {
              const tag = tags.find(t => t.id === item.tag_id);

              return (
                <List.Item
                  key={"dealer" + item.id}
                  className="Service"
                  draggable
                  onDragStart={e => this.onDragStart(e, item.id)}
                  onClick={() => this.handleEditService(item.id, "dealer")}
                >
                  <List.Content className="list-content">
                    <span>{item.name}</span>
                    {tag && (
                      <span className="service-tag" style={{ backgroundColor: tag.color }}>
                        {tag.name}
                      </span>
                    )}
                  </List.Content>
                  <List.Content className="list-content" floated="right">
                    <span>{item.price}</span>
                  </List.Content>
                </List.Item>
              );
            })
        : [];

    let locationLists =
      locationServices && locationServices.length > 0
        ? locationServices
            .sort((a, b) => (a.id < b.id ? 1 : a.id > b.id ? -1 : 0))
            .map(item => {
              const tag = tags.find(t => t.id === item.tag_id);

              return (
                <List.Item className="Service" key={"location" + item.id} onClick={() => this.handleEditService(item.id, "location")}>
                  <List.Content className="list-content">
                    <span>{item.name}</span>
                    {tag && (
                      <span className="service-tag" style={{ backgroundColor: tag.color }}>
                        {tag.name}
                      </span>
                    )}
                  </List.Content>
                  <List.Content className="list-content" floated="right">
                    <span>{item.price}</span>
                  </List.Content>
                </List.Item>
              );
            })
        : [];

    return (
      <div className="CheckInService">
        {this.renderServiceModal()}

        <SubHeader>
          <h1 style={{ marginLeft: "28px" }}>{t("checkin_services").message || "Online Check-in Services"}</h1>
        </SubHeader>

        <Grid columns={2} divided className="-container-large" style={{ padding: "0 2rem" }}>
          <Grid.Row className="-no-padding-left">
            <Grid.Column width="8">
              <h3 style={{ margin: "5px 0" }}>{t("location_services").message || "Location Services"}</h3>
              <List
                className="Scrollbar"
                style={{
                  minHeight: "500px",
                  paddingBottom: "100px",
                }}
                onDrop={e => this.onDrop(e)}
                onDragOver={e => this.onDragOver(e)}
              >
                {isLoadingLocationServices && this.displayLoading()}
                {!isLoadingLocationServices && locationLists}
              </List>
            </Grid.Column>
            <Grid.Column width="8" className="-no-margin">
              <h3 style={{ margin: "5px 0" }}>{t("dealer_services").message || "Dealer Services"}</h3>
              <List className="Scrollbar" onDragOver={e => this.onDragOver(e)}>
                {isLoadingDealerServices && this.displayLoading()}
                {!isLoadingDealerServices && dealerLists}
              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <FloatingActions items={[]} onRootClick={this.handleOpenCreateServiceModal} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { globalState: state.global };
};

const c = connect(mapStateToProps)(CheckInService);

export default withTranslation()(c);
