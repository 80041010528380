import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import ReactTable, { ReactTableDefaults } from "react-table";
import { Icon, Popup, Label } from "semantic-ui-react";
import moment from "moment";
import Lightbox from "react-image-lightbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt, faCar, faWarehouse, faCheck } from "@fortawesome/free-solid-svg-icons";
import { faTire } from "@fortawesome/pro-light-svg-icons";

import { Snooze, CustomersCard } from "../../../components";
import { ANSWER_STATUSES, removeGenerationParam } from "../../../util/common";
import { TYRE_POSITION, renderMedia, getTyreSeasonIcon } from "../../Appointments/common";

import frontLeft from "./position_icons/Front Left Tire.svg";
import frontRight from "./position_icons/Front Right Tire.svg";
import rearLeft from "./position_icons/Rear Left Tire.svg";
import rearRight from "./position_icons/Rear Right Tire.svg";

class NotSnoozedTable extends Component {
  state = {
    photoIndex: 0,
    images: [],
    videos: [],
    isImageGalleryVisible: false,
  };

  handleAppointmentClick = result => {
    if (this.props.externalLink) {
      window.open(`${document.location.origin}/#!/appointments/${result.appointment_id}`, "_blank");
      return;
    }
    this.props.onAppointmentClick(result.appointment);
  };

  handleCarClick = result => {
    if (this.props.externalLink) {
      window.open(`${document.location.origin}/#!/appointments/car/${result.car_id}`, "_blank");
      return;
    }

    this.props.onCarClick(result.car_id, result.dealer_location_id);
  };

  getDBBStatusColor = status => {
    if (status === 1) return "green";
    else if (status === 2) return "orange";

    return "red";
  };

  renderDBBIcon = appointment => {
    if (!appointment.has_dbb || !appointment.dbb_appointment_date) return <Label style={{ visibility: "hidden", width: "25px" }} />;

    return (
      <>
        {appointment.final_car_check_dbb_status > 0 && (
          <Label className="checkedTire" circular color={this.getDBBStatusColor(appointment.final_car_check_dbb_status)}>
            <FontAwesomeIcon className="checkedTire-tire" icon={faTire} />
            <FontAwesomeIcon className="checkedTire-check" icon={faCheck} />
          </Label>
        )}
        {appointment.final_car_check_dbb_status === 0 && appointment.car_check_dbb_status > 0 && (
          <Label className="bigTire" circular color={this.getDBBStatusColor(appointment.car_check_dbb_status)}>
            <FontAwesomeIcon icon={faTire} />
          </Label>
        )}
        {appointment.final_car_check_dbb_status === 0 && appointment.car_check_dbb_status === 0 && (
          <Label className="bigTire" circular color={moment().isAfter(appointment.dbb_appointment_date) ? "green" : "orange"}>
            <FontAwesomeIcon icon={faTire} />
          </Label>
        )}
      </>
    );
  };

  renderTirePostionIcon = position => {
    switch (position) {
      case TYRE_POSITION.CAR_FRONT_LEFT:
      case TYRE_POSITION.STORAGE_FRONT_LEFT:
        return <img src={frontLeft} alt="Car front left tyre" />;

      case TYRE_POSITION.CAR_FRONT_RIGHT:
      case TYRE_POSITION.STORAGE_FRONT_RIGHT:
        return <img src={frontRight} alt="Car front right tyre" />;

      case TYRE_POSITION.CAR_REAR_LEFT:
      case TYRE_POSITION.STORAGE_REAR_LEFT:
        return <img src={rearLeft} alt="Car rear left tyre" />;

      case TYRE_POSITION.CAR_REAR_RIGHT:
      case TYRE_POSITION.STORAGE_REAR_RIGHT:
        return <img src={rearRight} alt="Car rear right tyre" />;
      default:
        break;
    }
  };

  renderMediaButton = d => {
    if (!d.images && !d.videos) return null;

    return (
      <Popup
        basic
        on="click"
        hideOnScroll
        className="Snooze_media_popup"
        position="left center"
        wide="very"
        flowing
        style={{ zIndex: "999" }}
        onOpen={() => this.setState({ images: d.images, videos: d.videos })}
        content={<div>{renderMedia(true, d, this.state.images, this.state.videos, this.handleMediaToggle, null, false)}</div>}
        trigger={
          <div className="ui teal label" style={{ width: "auto", cursor: "pointer" }}>
            <Icon name="images" style={{ margin: "auto" }} /> {(d.images ? d.images.length : 0) + (d.videos ? d.videos.length : 0)}
          </div>
        }
      />
    );
  };

  handleMediaToggle = () => {
    this.setState({ isImageGalleryVisible: true });
  };

  renderImageGallery = () => {
    const { photoIndex, images } = this.state;

    return (
      <Lightbox
        mainSrc={removeGenerationParam(images[photoIndex].url)}
        nextSrc={removeGenerationParam(images[(photoIndex + 1) % images.length].url)}
        prevSrc={removeGenerationParam(images[(photoIndex + images.length - 1) % images.length].url)}
        onCloseRequest={() => this.setState({ isImageGalleryVisible: false })}
        onMovePrevRequest={() =>
          this.setState({
            photoIndex: (photoIndex + images.length - 1) % images.length,
          })
        }
        onMoveNextRequest={() =>
          this.setState({
            photoIndex: (photoIndex + 1) % images.length,
          })
        }
      />
    );
  };

  renderStatus = status => {
    if (typeof status === "undefined" || status === "" || status === -1) {
      status = 4;
    }

    let backgroundColor = ANSWER_STATUSES[status] ? ANSWER_STATUSES[status].color : "#fff";
    let style = {
      backgroundColor,
      color: "white",
    };

    return (
      <div className="QuestionStatus" style={style}>
        {ANSWER_STATUSES[status].text}
      </div>
    );
  };

  handleOnSnooze = (groupedItem, newSnoozeLog) => {
    const { onSnoozeChanged } = this.props;

    if (newSnoozeLog?.length < 1) return onSnoozeChanged(newSnoozeLog);
    newSnoozeLog[0].appointment = groupedItem.appointment;

    if (groupedItem.intervention) newSnoozeLog[0].intervention = groupedItem.intervention;
    else if (groupedItem.question_result) newSnoozeLog[0].question_result = groupedItem.question_result;

    onSnoozeChanged(newSnoozeLog);
  };

  renderTable = () => {
    const { t, items, page, pages, nb_items, loading, onFetchData, externalLink } = this.props;

    if (!items || items.length < 1) return <div className="Table__no-results">{t("no_leads_found").message || "No leads found"}</div>;

    return (
      <ReactTable
        className="SnoozeTable -floated-table"
        data={items}
        page={page - 1}
        pages={pages === null ? -1 : pages}
        showPagination={pages > 1}
        renderPageJump={({ onChange, value, onBlur, onKeyPress, inputType, pageJumpText }) => (
          <div className="-pageJump">
            <input
              aria-label={pageJumpText}
              type={inputType}
              onChange={evt => {
                onChange(evt);

                const newPage = evt.target.value - 0;
                if (!Number.isNaN(newPage) && newPage > 0 && newPage <= pages) onFetchData(newPage);
              }}
              value={page}
              onBlur={onBlur}
              onKeyPress={onKeyPress}
            />
          </div>
        )}
        loading={loading}
        manual
        onFetchData={state => onFetchData(state.page + 1)}
        showPageSizeOptions={false}
        sortable={false}
        resizable={false}
        minRows={0}
        loadingText=""
        nextText={t("next").message || "Next"}
        previousText={t("previous").message || "Previous"}
        pageText={t("page").message || "Page"}
        ofText={t("of").message || "of"}
        onPageChange={() => {
          try {
            document.querySelector(".App__module").scrollTo(0, 0);
          } catch (e) {
            document.querySelector(".App__module").scrollTop = 0; // IE Fix
          }
        }}
        column={{
          ...ReactTableDefaults.column,
          headerClassName: "ReactTable__column-header -text-ellipsis",
          className: "ReactTable__column",
        }}
        columns={[
          {
            Header: t("Appointment_date").message || "Appointment Date",
            id: "appointmentDate",
            accessor: d =>
              d.appointment.time_car_app && (moment.isMoment(d.appointment.time_car_app) || !d.appointment.time_car_app.startsWith("0001-01-01T00:00:00")) // might be time zoned
                ? moment(d.appointment.time_car_app).format("DD-MM-YYYY")
                : "",
            maxWidth: 135,
          },
          {
            id: "woNr",
            Header: (
              <>
                {t("WO Nr").message || "WO"} <span className="total-appointments">{nb_items}</span>
              </>
            ),
            accessor: d =>
              d.appointment.wo_nr === 0 ? (
                ""
              ) : (
                <span className="-cursor-pointer" onClick={() => this.handleAppointmentClick(d)}>
                  {externalLink ? <FontAwesomeIcon icon={faExternalLinkAlt} style={{ marginRight: "5px", fontSize: "15px" }} /> : <Icon name="info circle" />}
                  {d.appointment.wo_nr}
                </span>
              ),
            maxWidth: 100,
          },

          {
            id: "regNo",
            Header: t("Reg Nr").message,
            accessor: d =>
              d.appointment.reg_number && (
                <span className="-cursor-pointer" onClick={() => this.handleCarClick(d)}>
                  {externalLink ? <FontAwesomeIcon icon={faExternalLinkAlt} style={{ marginRight: "5px", fontSize: "15px" }} /> : <Icon name="info circle" />}
                  {d.appointment.reg_number}
                </span>
              ),
            maxWidth: 120,
          },

          {
            id: "dbb",
            Header: t("DBB").message || "DBB",
            maxWidth: 120,
            accessor: d => (
              <div className="dbb-column-item">
                {this.renderDBBIcon(d.appointment)}
                {TYRE_POSITION.IS_ON_CAR(d.tyre_position) ? <FontAwesomeIcon icon={faCar} /> : <FontAwesomeIcon icon={faWarehouse} />}
                {this.renderTirePostionIcon(d.tyre_position)}
              </div>
            ),
          },
          {
            id: "season_size",
            Header: t("season_size").message || "Season/Size",
            accessor: d => (
              <div>
                {getTyreSeasonIcon(d.tyre.season)}
                <span style={{ marginLeft: "10px" }}>
                  {d.tyre.width}-{d.tyre.height}-R{d.tyre.size}
                </span>
              </div>
            ),
          },
          {
            id: "description",
            Header: t("description").message || "Description",
            accessor: d => <span>{d.tyre.description}</span>,
          },
          {
            id: "brand",
            Header: t("brand").message || "Brand",
            accessor: d => <span>{d.tyre.manufacturer}</span>,
          },
          {
            id: "profile",
            Header: t("profile").message || "Profile",
            accessor: d => <span>{d.tyre_profile}</span>,
          },
          {
            id: "remarks",
            Header: t("remarks").message || "Remarks",
            accessor: d => <span>{d.mechanic_notes}</span>,
          },
          {
            id: "media",
            Header: "",
            accessor: d => this.renderMediaButton(d),
            maxWidth: 49,
          },
          {
            id: "status",
            Header: "",
            accessor: d => this.renderStatus(d.status),
            maxWidth: 42,
          },
          {
            id: "snoozer",
            Header: "",
            className: "-text-overflow-unset",
            Cell: row => (
              <Snooze
                question_result={row.original}
                appointment={row.original.appointment}
                snoozeLog={row.original.history}
                onSnooze={snoozeLog => this.handleOnSnooze(row.original, snoozeLog)}
                onDelete={reason => this.props.onSnoozeDeleted(row.original, reason)}
              />
            ),
            maxWidth: 42,
          },
          {
            id: "user",
            Header: "",
            accessor: d =>
              !d.appointment.customer_contract && !d.appointment.customer_driver && !d.appointment.customer_owner ? (
                <div className="QuestionStatus" style={{ visibility: "hidden" }} />
              ) : (
                <CustomersCard contractor={d.appointment.customer_contract} driver={d.appointment.customer_driver} owner={d.appointment.customer_owner} />
              ),
            maxWidth: 42,
          },
        ]}
      />
    );
  };

  render() {
    return (
      <div className="SnoozeTableContainer">
        {this.renderTable()}
        {this.state.isImageGalleryVisible && this.renderImageGallery()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { globalState: state.global, authState: state.auth };
};

export default withTranslation()(connect(mapStateToProps)(NotSnoozedTable));
