import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Dropdown, Checkbox, Form, Header, Icon, Segment } from "semantic-ui-react";

class PlanitConfiguration extends Component {
  render() {
    const { t, location, loadingPlanitPlanningEstablishmentOptions, planitPlanningEstablishmentOptions, onCheckboxChange, onChangePlanitPlanningEstablishmentID } =
      this.props;

    return (
      <Segment>
        <Header as="h5">
          <Icon name="file alternate outline" />
          <Header.Content>{t("planit_planning_configuration").message || "Planit Planning Configuration"}</Header.Content>
        </Header>
        <Segment.Inline>
          <Form>
            <Form.Field className="full-width-input">
              <Dropdown
                selection
                selectOnBlur={false}
                clearable
                loading={loadingPlanitPlanningEstablishmentOptions}
                options={planitPlanningEstablishmentOptions}
                value={location.planit_planning_establishment_id}
                name="planit_planning_establishment_id"
                label={t("establishment_id").message || "Establishment ID"}
                onChange={onChangePlanitPlanningEstablishmentID}
              />
            </Form.Field>

            <Form.Field className="-no-margin">
              <Checkbox
                checked={location.planit_planning_replacement_vehicle}
                name="planit_planning_replacement_vehicle"
                label={t("show_replacement_car").message || "Show Replacement Car"}
                onChange={onCheckboxChange}
              />
            </Form.Field>
          </Form>
        </Segment.Inline>
      </Segment>
    );
  }
}

export default withTranslation()(PlanitConfiguration);
