import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button, Icon, Modal, Form, Dropdown, TextArea, Checkbox, Grid, GridRow, GridColumn, Label } from "semantic-ui-react";
import { faUserHardHat } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import AlertContainer from "react-alert";

import { getShopIconColor, renderPinTypeIcon } from "./util";
import Can from "../../modules/Can";
import CustomConfirm from "../CustomConfirm";
import Service from "./service";
import PinOverview from "./PinOverview";
import { PIN_TYPE, PIN_STATUS } from "./enum";
import { FileDropDown, UserInput } from "../../components/";
import { NOTE_TYPES } from "../../modules/NoteSuggestions";
import { getFilesButton, pinLogAttachmentList } from "../../util/common";

class PinModal extends Component {
  constructor(props) {
    super(props);

    const { pinTypesOptions, warrantyTypeOptions, pinStatusOptions, orderStatusOptions } = this.getDropdownOptions();

    this.state = {
      showDeleteConfirmation: false,
      isDeletingPin: false,
      deleteError: "",
      showCancelConfirmation: false,
      showPrintOverview: false,
      pinTypesOptions,
      warrantyTypeOptions,
      pinStatusOptions,
      orderStatusOptions,
    };

    this.msg = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dropdownOptions !== this.props.dropdownOptions) {
      const { pinTypesOptions, warrantyTypeOptions, pinStatusOptions, orderStatusOptions } = this.getDropdownOptions();
      this.setState({ pinTypesOptions, warrantyTypeOptions, pinStatusOptions, orderStatusOptions });
    }
  }

  getDropdownOptions = () => {
    const { t, dropdownOptions, dealer } = this.props;

    let pinTypesOptions = [],
      warrantyTypeOptions = [],
      pinStatusOptions = [],
      orderStatusOptions = [];

    if (dropdownOptions) {
      if (Array.isArray(dropdownOptions.pin_types)) {
        dropdownOptions.pin_types.forEach(o => {
          pinTypesOptions[o.id] = t(o.name).message;
        });
      }

      if (Array.isArray(dropdownOptions.warranty_types)) {
        dropdownOptions.warranty_types
          .filter(type => dealer.enabled_warranty_types.includes(type.id))
          .forEach(o => {
            warrantyTypeOptions[o.id] = t(o.name).message;
          });
      }

      if (Array.isArray(dropdownOptions.pin_statuses)) {
        dropdownOptions.pin_statuses.forEach(o => {
          pinStatusOptions[o.id] = t(o.name).message;
        });
      }

      if (Array.isArray(dropdownOptions.pin_order_statuses)) {
        dropdownOptions.pin_order_statuses.forEach(o => {
          orderStatusOptions[o.id] = t(o.name).message;
        });
      }
    }

    return { pinTypesOptions, warrantyTypeOptions, pinStatusOptions, orderStatusOptions };
  };

  handleInputChange = e => {
    const { pin, onChange } = this.props;
    if (e.target.name === "note" && e.target.value === "" && !pin.keep_parts) {
      pin.visible_important_items = pin.visible_mechanic = false;
    }

    pin[e.target.name] = e.target.value;
    onChange(pin);
  };

  handleSelectPinType = (e, data) => {
    const { pin, onChange } = this.props;

    if (data.value === PIN_TYPE.REMARKS) {
      pin.warranty_type_id = null;
      pin.pin_status_id = null;
    } else if (data.value === PIN_TYPE.RECALL) pin.warranty_type_id = null;

    pin.pin_type_id = data.value;
    onChange(pin);
  };

  handleSelectChange = (e, data) => {
    const { pin, onChange } = this.props;
    pin[data.name] = data.value;
    onChange(pin);
  };

  handleSelectNoteSuggestion = (_e, data) => {
    const { pin, onChange } = this.props;
    onChange({ ...pin, note: data.value });
  };

  handleCheckboxChange = (e, data) => {
    const { pin, onChange } = this.props;
    pin[data.name] = data.checked;
    onChange(pin);
  };

  handleKeepPartsCheckbox = (e, data) => {
    const { pin, onChange } = this.props;

    pin.keep_parts = data.checked;

    if (data.checked) pin.visible_important_items = pin.visible_mechanic = true;
    else if (!pin.note) pin.visible_important_items = pin.visible_mechanic = false;

    onChange(pin);
  };

  handleDelete = deleteNote => {
    const { intervention, questionResult, onDelete, t } = this.props;

    const pin = {
      ...(questionResult?.id && { question_result_id: questionResult.id }),
      ...(intervention?.id && { intervention_id: intervention.id }),
      note: deleteNote,
    };

    this.setState({ isDeletingPin: true }, () => {
      Service.deletePin(pin)
        .then(result => {
          this.setState({ isDeletingPin: false, showDeleteConfirmation: false }, () => onDelete(deleteNote));
        })
        .catch(error => {
          console.error(error?.response?.data?.errors?.shift() || t("unexpected_error").message || "Unexpected error, please try to reload the page.");
          this.setState({ isDeletingPin: false, deleteError: t("cannot_delete_pin").message || "Can't delete pin." });
        });
    });
  };

  toggleDeleteConfirmation = () => {
    this.setState(prevState => ({ showDeleteConfirmation: !prevState.showDeleteConfirmation, deleteError: "" }));
  };

  renderDeleteConfirmation = () => {
    const { showDeleteConfirmation, isDeletingPin, deleteError } = this.state;
    const { t } = this.props;

    return (
      <CustomConfirm
        type="danger"
        isOpen={showDeleteConfirmation}
        handleConfirm={this.handleDelete}
        handleCancel={this.toggleDeleteConfirmation}
        confirmMsg={t("confirm_delete_message").message || "Are you sure that you want to delete this? You can't undo this action."}
        confirmMandatoryNote={t("delete_pin_note").message || "Reason for deleting pin item"}
        isLoading={isDeletingPin}
        error={deleteError}
      />
    );
  };

  toggleCancelConfirmation = () => {
    this.setState(prevState => ({ showCancelConfirmation: !prevState.showCancelConfirmation }));
  };

  handleConfirmCancel = () => {
    this.setState({ showCancelConfirmation: false }, () => {
      this.props.onClose();
    });
  };

  renderCancelConfirmation = () => {
    return (
      <CustomConfirm
        type="warning"
        isOpen={this.state.showCancelConfirmation}
        handleCancel={this.toggleCancelConfirmation}
        handleConfirm={this.handleConfirmCancel}
        confirmMsg={this.props.t("confirm_discard_message").message || "There are some unsaved changes. Are you sure that you want to cancel?"}
      />
    );
  };

  renderPinChanges = (currPin, prevPin) => {
    const { t } = this.props;
    const { pinStatusOptions, pinTypesOptions, warrantyTypeOptions, orderStatusOptions } = this.state;
    if (prevPin) {
      return (
        <div className="log-values">
          <GridRow>
            {currPin.mechanic_fixed && !prevPin.mechanic_fixed && (
              <Label>
                <FontAwesomeIcon icon={faUserHardHat} />
                {<strong className="mark-fixed">{t("marked_as_fixed").message || "Marked as fixed"}</strong>}
              </Label>
            )}
            {!!currPin.pin_type_id && currPin.pin_type_id !== prevPin.pin_type_id && (
              <Label>
                <Icon name="pin" />
                {pinTypesOptions[currPin.pin_type_id]}
              </Label>
            )}
            {!!currPin.warranty_type_id && currPin.warranty_type_id !== prevPin.warranty_type_id && (
              <Label>
                <Icon name="shield" />
                {warrantyTypeOptions[currPin.warranty_type_id]}
              </Label>
            )}
            {!!currPin.pin_status_id && currPin.pin_status_id !== prevPin.pin_status_id && (
              <Label>
                <Icon name="list alternate" />
                {pinStatusOptions[currPin.pin_status_id]}
              </Label>
            )}
            {!!currPin.order_status && currPin.order_status !== prevPin.order_status && (
              <Label>
                <Icon name="shop" color={getShopIconColor(currPin.order_status)} />
                {orderStatusOptions[currPin.order_status]}
              </Label>
            )}
          </GridRow>
          <GridRow>
            {!!currPin.claim_nr && currPin.claim_nr !== prevPin.claim_nr && (
              <Label>
                <Icon name="hashtag" />
                <strong>{t("claim_number").message || "Claim"}: </strong>
                {currPin.claim_nr}
              </Label>
            )}
            {!!currPin.support_nr && currPin.support_nr !== prevPin.support_nr && (
              <Label>
                <Icon name="hashtag" />
                <strong>{t("support_number").message || "Support"}: </strong>
                {currPin.support_nr}
              </Label>
            )}
            {!!currPin.ref_nr && currPin.ref_nr !== prevPin.ref_nr && (
              <Label>
                <Icon name="hashtag" />
                <strong>{t("ref_number").message || "Reference"}: </strong>
                {currPin.ref_nr}
              </Label>
            )}
          </GridRow>
        </div>
      );
    }
  };

  renderInitialLogValues = pin => {
    const { t } = this.props;
    const { pinStatusOptions, pinTypesOptions, warrantyTypeOptions, orderStatusOptions } = this.state;

    if (pin) {
      return (
        <div className="log-values">
          <GridRow>
            {!!pin.pin_type_id && (
              <Label>
                <Icon name="pin" />
                {pinTypesOptions[pin.pin_type_id]}
              </Label>
            )}
            {!!pin.warranty_type_id && (
              <Label>
                <Icon name="shield" />
                {warrantyTypeOptions[pin.warranty_type_id]}
              </Label>
            )}
            {!!pin.pin_status_id && (
              <Label>
                <Icon name="list alternate" />
                {pinStatusOptions[pin.pin_status_id]}
              </Label>
            )}
            {!!pin.order_status && (
              <Label>
                <Icon name="shop" color={getShopIconColor(pin.order_status)} />
                {orderStatusOptions[pin.order_status]}
              </Label>
            )}
          </GridRow>
          <GridRow>
            {pin.claim_nr && (
              <Label>
                <Icon name="hashtag" />
                <strong>{t("claim_number").message || "Claim"}: </strong>
                {pin.claim_nr}
              </Label>
            )}
            {pin.support_nr && (
              <Label>
                <Icon name="hashtag" />
                <strong>{t("support_number").message || "Support"}: </strong>
                {pin.support_nr}
              </Label>
            )}
            {pin.ref_nr && (
              <Label>
                <Icon name="hashtag" />
                <strong>{t("ref_number").message || "Reference"}: </strong>
                {pin.ref_nr}
              </Label>
            )}
          </GridRow>
        </div>
      );
    }
  };

  renderLogHistory = () => {
    const { t, pinLog, onVisibilityUpdate, readOnly, noteOnly } = this.props;

    const lastDeletedIndex = pinLog.findIndex(p => p.pin_status_id === PIN_STATUS.DELETED);
    const clickableEyesStartIndex = lastDeletedIndex > -1 ? lastDeletedIndex : pinLog.length;

    const allAttachments = pinLog.map(pin => (pin.note_attachments ? pinLogAttachmentList(pin) : [])).flat();

    return (
      <div className="Pin-LogHistory">
        <Grid>
          <GridRow className="headers" style={{ alignItems: "center" }}>
            <GridColumn width={10}>{t("log_history").message || "Log history"}</GridColumn>
            <GridColumn width={2}>
              {allAttachments?.length > 0 && (
                <FileDropDown
                  resource="pin"
                  files={allAttachments}
                  trigger={getFilesButton({ count: allAttachments.length })}
                  showPreview
                  showCount={false}
                  showIcon={true}
                  disableUpload
                />
              )}
            </GridColumn>

            <GridColumn width={2}>{t("visible_important").message || "Important"}</GridColumn>
            <GridColumn width={2}>{t("visible_mechanic").message || "Mechanic"}</GridColumn>
          </GridRow>
        </Grid>

        <div className="content">
          {!pinLog.length && <div className="no-data">{t("no_log_history").message || "No log history"}</div>}

          {pinLog.map((pin, i) => (
            <div className="inner-row" key={pin.id}>
              <Grid>
                <GridRow>
                  <GridColumn width={12}>
                    <GridRow>
                      <strong>
                        {pin.is_dms && !pinLog[i + 1]?.is_dms ? (
                          "DMS"
                        ) : (
                          <>
                            {pin.user?.first_name} {pin.user?.last_name}
                          </>
                        )}
                      </strong>
                      {" - "}
                      <span className="-margin-right-10">{moment(pin.created_on).format("HH:mm DD-MM-YYYY")}</span>
                      {pin.note_attachments?.length > 0 && (
                        <FileDropDown
                          resource="pin"
                          files={pinLogAttachmentList(pin)}
                          trigger={getFilesButton({ count: pin.note_attachments.length })}
                          showPreview
                          showCount={false}
                          showIcon={true}
                          disableUpload
                        />
                      )}
                      {pin.manufacturer_id > 0 && (
                        <>
                          {" - "}
                          {
                            <strong>
                              {t("granted_access_to_the_manufacturer").message || "granted access to the manufacturer"} {pin.manufacturer?.first_name}{" "}
                              {pin.manufacturer?.last_name}
                            </strong>
                          }
                        </>
                      )}
                      {pin.pin_status_id === PIN_STATUS.DELETED && (
                        <>
                          {" - "}
                          {<strong style={{ color: "red" }}>{t("deleted").message || "Deleted"}</strong>}
                        </>
                      )}
                      {pin.info && (
                        <>
                          {" - "}
                          {<strong style={{ color: "blue" }}>{t("marked_as_info").message || "Marked as info"}</strong>}
                        </>
                      )}
                    </GridRow>

                    {i === pinLog.length - 1 || pinLog[i - 1]?.pin_status_id === PIN_STATUS.DELETED
                      ? this.renderInitialLogValues(pin)
                      : this.renderPinChanges(pin, pinLog[i + 1])}
                    {(pin.note || pin.keep_parts) && (
                      <GridRow>
                        {pin.keep_parts && pin.pin_status_id !== PIN_STATUS.DELETED && (t("keep_parts_prefix").message || "KEEP PARTS") + " "}
                        {pin.note}
                      </GridRow>
                    )}
                  </GridColumn>

                  {(pin.note || pin.keep_parts) && !(pin.mechanic_fixed && !pinLog[i + 1]?.mechanic_fixed) && pin.pin_status_id !== PIN_STATUS.DELETED && (
                    <>
                      <GridColumn width={2} className="eye-icon">
                        {i < clickableEyesStartIndex && !readOnly && !noteOnly ? (
                          <Label name="visible_important_items" checked={pin.visible_important_items} onClick={(e, data) => onVisibilityUpdate(pin.id, data)}>
                            <Icon color={pin.visible_important_items ? "green" : "red"} name={pin.visible_important_items ? "eye" : "eye slash"} />
                          </Label>
                        ) : (
                          <Icon color={pin.visible_important_items ? "green" : "red"} name={pin.visible_important_items ? "eye" : "eye slash"} />
                        )}
                      </GridColumn>
                      <GridColumn width={2} className="eye-icon">
                        {i < clickableEyesStartIndex && !readOnly && !noteOnly ? (
                          <Label name="visible_mechanic" checked={pin.visible_mechanic} onClick={(e, data) => onVisibilityUpdate(pin.id, data)}>
                            <Icon color={pin.visible_mechanic ? "green" : "red"} name={pin.visible_mechanic ? "eye" : "eye slash"} />
                          </Label>
                        ) : (
                          <Icon color={pin.visible_mechanic ? "green" : "red"} name={pin.visible_mechanic ? "eye" : "eye slash"} />
                        )}
                      </GridColumn>
                    </>
                  )}
                </GridRow>
              </Grid>
            </div>
          ))}
        </div>
      </div>
    );
  };

  toggleShowPrintOverview = () => {
    this.setState(prevState => ({
      showPrintOverview: !prevState.showPrintOverview,
    }));
  };

  handleUploadFiles = file => {
    const { pin, location, appointment, onHandleAddAttachments, onChange } = this.props;

    const uploadFile = new File([file], file.name);

    const formData = new FormData();

    const date = moment(appointment.created_on).format("YYYY/MM/DD");

    const path = `${location.id}_${location.name}/${date}/${appointment.id}_${appointment.wo_nr}`;

    formData.append("path", path);
    formData.append("file", uploadFile);

    Service.uploadFiles(formData)
      .then(res => {
        if (res?.data?.data?.URL) {
          onHandleAddAttachments(uploadFile, res.data.data.URL);

          pin.visible_important_items = true;
          pin.visible_mechanic = true;
          onChange(pin);
        } else throw new Error("Error uploading files");
      })
      .catch(error => {
        const errorMsg =
          typeof error === "string"
            ? error
            : error.message
            ? error.message
            : error.response?.data?.errors?.length
            ? error.response.data.errors[0]
            : "Error uploading files";

        this.msg.show(errorMsg, { type: "error" });
      });
  };

  renderAlert = () => {
    const props = {
      offset: 20,
      position: "top right",
      theme: "light",
      time: 2000,
      transition: "fade",
    };

    return <AlertContainer ref={a => (this.msg = a)} {...props} />;
  };

  render() {
    const { isDeletingPin, pinTypesOptions, pinStatusOptions, warrantyTypeOptions, orderStatusOptions, showPrintOverview } = this.state;
    const {
      readOnly,
      noteOnly,
      appointment,
      questionResult,
      intervention,
      t,
      isOpen,
      onClose,
      pin,
      pinLog,
      isLoading,
      isUpdated,
      errorMessage,
      getCurrentPin,
      showInfoToggle,
      attachments,
      location,
    } = this.props;

    const currentPin = getCurrentPin(pinLog);

    const noteSuggestions =
      pin.pin_type_id &&
      location.note_suggestions?.filter(s => {
        if (pin.pin_type_id === PIN_TYPE.WARRANTY) return s.note_type_id === NOTE_TYPES.WARRANTY_PIN && s.active;
        if (pin.pin_type_id === PIN_TYPE.RECALL) return s.note_type_id === NOTE_TYPES.RECALL_PIN && s.active;
        if (pin.pin_type_id === PIN_TYPE.REMARKS) return s.note_type_id === NOTE_TYPES.REMARK_PIN && s.active;

        return null;
      });

    return (
      <>
        <Modal open={isOpen} onClose={onClose} closeOnDimmerClick={false} className="Pin-Modal">
          <Modal.Header>
            <div className="modal-header">
              <div className="modal-header-title">Pin</div>
              {(questionResult?.pinned || intervention?.pinned) && (
                <Button onClick={this.toggleShowPrintOverview}>
                  <Icon name="print" />
                  {t("print_overview").message || "Print overview"}
                </Button>
              )}
            </div>
          </Modal.Header>
          <Modal.Content scrolling>
            <Form>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>{t("pin_type").message || "Pin Type"}</label>
                  <Dropdown
                    placeholder={pinTypesOptions.length ? t("select_pin_type").message || "Select Pin Type" : t("loading").message || "Loading"}
                    options={Object.keys(pinTypesOptions).map(key => ({
                      key: Number(key),
                      text: (
                        <>
                          <span style={{ marginRight: "4.5px" }}>{renderPinTypeIcon(Number(key))}</span> <span>{pinTypesOptions[Number(key)]}</span>
                        </>
                      ),
                      value: Number(key),
                    }))}
                    disabled={readOnly || noteOnly}
                    selection
                    name="pin_type_id"
                    value={pin.pin_type_id}
                    onChange={this.handleSelectPinType}
                  />
                </Form.Field>
                <Form.Field>
                  <label>{t("warranty_type").message || "Warranty Type"}</label>
                  <Dropdown
                    placeholder={warrantyTypeOptions.length ? t("select_warranty_type").message || "Select Warranty Type" : t("loading").message || "Loading"}
                    options={Object.keys(warrantyTypeOptions).map(key => ({
                      key: Number(key),
                      text: warrantyTypeOptions[Number(key)],
                      value: Number(key),
                    }))}
                    selection
                    name="warranty_type_id"
                    disabled={pin.pin_type_id !== PIN_TYPE.WARRANTY || readOnly || noteOnly}
                    value={pin.warranty_type_id}
                    onChange={this.handleSelectChange}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>{t("support_nr").message || "Support #"}</label>
                  <UserInput
                    disabled={readOnly || noteOnly}
                    value={pin.support_nr || ""}
                    placeholder={t("support_nr").message || "Support #"}
                    name="support_nr"
                    onChange={this.handleInputChange}
                  />
                </Form.Field>
                <Form.Field>
                  <label>{t("pin_status").message || "Pin status"}</label>
                  <Dropdown
                    placeholder={pinStatusOptions.length ? t("select_pin_status").message || "Select Pin Status" : t("loading").message || "Loading"}
                    options={Object.keys(pinStatusOptions).map(key => ({ key: Number(key), text: pinStatusOptions[Number(key)], value: Number(key) }))}
                    selection
                    name="pin_status_id"
                    disabled={![PIN_TYPE.WARRANTY, PIN_TYPE.RECALL].includes(pin.pin_type_id) || readOnly || noteOnly}
                    value={pin.pin_status_id}
                    onChange={this.handleSelectChange}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>{t("claim_nr").message || "Claim #"}</label>
                  <UserInput
                    disabled={readOnly || noteOnly}
                    value={pin.claim_nr || ""}
                    placeholder={t("claim_nr").message || "Claim #"}
                    name="claim_nr"
                    onChange={this.handleInputChange}
                  />
                </Form.Field>
                <Form.Field>
                  <label>{t("ref_nr").message || "Ref #"}</label>
                  <UserInput
                    disabled={readOnly || noteOnly}
                    value={pin.ref_nr || ""}
                    placeholder={t("ref_nr").message || "Ref #"}
                    name="ref_nr"
                    onChange={this.handleInputChange}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field width={8}>
                  <label>{t("order_status").message || "Order status"}</label>
                  <Dropdown
                    placeholder={orderStatusOptions.length ? t("order_status").message || "Order status" : t("loading").message || "Loading"}
                    options={Object.keys(orderStatusOptions).map(key => ({
                      key: Number(key),
                      text: (
                        <>
                          <Icon name="shop" color={getShopIconColor(Number(key))} />
                          <span>{orderStatusOptions[Number(key)]}</span>
                        </>
                      ),
                      value: Number(key),
                    }))}
                    selection
                    clearable
                    selectOnBlur={false}
                    name="order_status"
                    disabled={readOnly || noteOnly}
                    value={pin.order_status}
                    onChange={this.handleSelectChange}
                  />
                </Form.Field>
              </Form.Group>

              <Form.Field>
                <label>{t("note").message || "Note"}</label>
                {noteSuggestions?.length > 0 && (
                  <Form.Field>
                    <Dropdown
                      placeholder={t("select_default_note_text").message || "Select default note text"}
                      options={noteSuggestions.map(s => ({
                        key: s.order,
                        text: s.content,
                        value: s.content,
                      }))}
                      selection
                      clearable
                      disabled={readOnly}
                      onChange={this.handleSelectNoteSuggestion}
                    />
                  </Form.Field>
                )}
                <TextArea disabled={readOnly} value={pin.note} name="note" onChange={this.handleInputChange} />
              </Form.Field>

              <Form.Group>
                <Form.Field>
                  <FileDropDown
                    resource="pin"
                    addFileText={t("drag_here_or_browse").message || "Drag here or browse"}
                    noFilesText={t("no_files").message || "No files"}
                    files={attachments}
                    trigger={getFilesButton({ disabled: !pin.note })}
                    onFileAdded={this.handleUploadFiles}
                    showPreview
                    showCount={false}
                    showIcon={true}
                    multiple
                    disabled={!pin.note}
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    toggle
                    name="visible_important_items"
                    label={t("visible_important_items").message || "Note visible in important items"}
                    onChange={this.handleCheckboxChange}
                    checked={pin.visible_important_items}
                    disabled={(!pin.keep_parts && !pin.note) || readOnly || noteOnly}
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    toggle
                    name="visible_mechanic"
                    label={t("visible_for_mechanic").message || "Note visible for the mechanic"}
                    onChange={this.handleCheckboxChange}
                    checked={pin.visible_mechanic}
                    disabled={(!pin.keep_parts && !pin.note) || readOnly || noteOnly}
                  />
                </Form.Field>

                <Form.Group className="align-right">
                  {showInfoToggle && (
                    <Form.Field>
                      <Checkbox
                        toggle
                        name="info"
                        checked={pin.info}
                        disabled={readOnly || noteOnly}
                        label={{
                          children: (
                            <>
                              <Icon name="info" className="-no-margin" /> {t("info").message || "Info"}
                            </>
                          ),
                        }}
                        onChange={this.handleCheckboxChange}
                      />
                    </Form.Field>
                  )}

                  <Form.Field>
                    <Checkbox
                      disabled={readOnly || noteOnly}
                      toggle
                      name="keep_parts"
                      label={t("keep_parts").message || "Keep parts"}
                      onChange={this.handleKeepPartsCheckbox}
                    />
                  </Form.Field>
                </Form.Group>
              </Form.Group>

              {this.renderLogHistory()}
              {errorMessage && (
                <Label className="-margin-top-10" basic color="red">
                  {errorMessage}
                </Label>
              )}
            </Form>
          </Modal.Content>
          <Modal.Actions>
            {currentPin.pin_type_id && !intervention?.is_warranty_in_dms && (
              <Can I="delete" the="pin">
                <Button color="red" loading={isDeletingPin} disabled={isDeletingPin || readOnly || noteOnly} onClick={this.toggleDeleteConfirmation} floated="left">
                  <Icon name="trash" className="-no-margin" />
                </Button>
              </Can>
            )}
            <Button onClick={isUpdated ? this.toggleCancelConfirmation : onClose} color="grey">
              {t("close").message || "Close"}
            </Button>
            <Button color="green" onClick={this.props.onSave} loading={isLoading} disabled={isLoading || !isUpdated || readOnly}>
              <Icon name="checkmark" /> {currentPin.pin_type_id ? t("update").message || "Update" : t("save").message || "Save"}
            </Button>
          </Modal.Actions>
          <PinOverview
            pin={pin}
            pinLog={pinLog}
            appointment={appointment}
            intervention={intervention}
            questionResult={questionResult}
            pinStatusOptions={pinStatusOptions}
            showPrintOverview={showPrintOverview}
            onToggle={this.toggleShowPrintOverview}
            renderInitialLogValues={this.renderInitialLogValues}
            renderPinChanges={this.renderPinChanges}
          />

          {this.renderAlert()}
        </Modal>

        {this.renderDeleteConfirmation()}
        {this.renderCancelConfirmation()}
      </>
    );
  }
}

export default withTranslation()(PinModal);
