import axios from "axios";

import ENV from "../../util/env-config";
import { getSessionCookie } from "../../util/common";

class Service {
  static requestConfig() {
    return {
      headers: {
        Authorization: `Bearer ${getSessionCookie()}`,
        Pragma: "no-cache",
      },
    };
  }

  static getBrands() {
    return axios.get(ENV.carBaseUrl + "/brands/list", this.requestConfig());
  }

  static getPins(requestData) {
    return axios.post(ENV.appointmentApiBase + "/pins/list", requestData, this.requestConfig());
  }

  static exportPinItems(requestData) {
    return axios.post(ENV.appointmentApiBase + "/pins/export", requestData, this.requestConfig());
  }
}

export default Service;
