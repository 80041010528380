import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Header, Segment, Form, Dropdown } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTag } from "@fortawesome/free-solid-svg-icons";

export const KEY_LABEL_TYPES = {
  STANDARD: 1,
  BASIC: 2,
};

class KeyLabelConfiguration extends Component {
  handleDropdownChange = (e, { name, value }) => {
    let { location, onLocationChange } = this.props;

    onLocationChange({ ...location, [name]: value });
  };

  render() {
    const { t, location, isSubmit } = this.props;

    const dropdownOptions = Object.entries(KEY_LABEL_TYPES).map(([key, value]) => ({
      key,
      value,
      text: t(key.toLocaleLowerCase()).message || `${key[0]}${key.slice(1).toLocaleLowerCase()}`,
    }));

    return (
      <Segment>
        <Header as="h5">
          <FontAwesomeIcon icon={faTag} className="-margin-right-5" />
          <Header.Content>{t("key_label_settings").message || "Key Label Settings"}</Header.Content>
        </Header>
        <Form>
          <Form.Field className="full-width-input">
            <label>{t("label_format").message || "Label format"}</label>
            <Dropdown
              options={dropdownOptions}
              value={location.key_label_type_id}
              onChange={this.handleDropdownChange}
              name="key_label_type_id"
              selectOnBlur={false}
              selection
              fluid
              disabled={isSubmit}
            />
          </Form.Field>
        </Form>
      </Segment>
    );
  }
}

export default withTranslation()(KeyLabelConfiguration);
