import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Modal, Form, Dropdown, Checkbox, Popup, Button, Icon, Label } from "semantic-ui-react";
import ReactTable, { ReactTableDefaults } from "react-table";
import AlertContainer from "react-alert";
import moment from "moment";
import _ from "lodash";

import { round2 } from "../../util/common";
import { TYRE_TEAM_CHANNEL, SEASONS, getTyreSeasonIcon } from "./common";
import TyreOptions from "./TyreBrandLoader";

import "./ReplacementTyreOffer.scss";

import Service from "./service";

class ReplacementTyreOffer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      brands: [],
      discounts: [],
      loading: false,
      loadingDeliveries: false,
    };
  }

  componentDidMount() {
    if (!this.props.search_results?.length) {
      if (this.props.isTyreTeamEnabled) {
        this.updateSelectedDeliveries();
      } else this.handleSearchTires();
    } else {
      const sortedResults = this.sortSearchResults([...this.props.search_results]);
      this.props.onSearchResults(sortedResults);
      this.setState({ loading: false });
    }

    this.fetchBrands();
    this.getDiscounts();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedDiscount?.id !== this.props.selectedDiscount?.id) this.handleUpdateBrandsOnDiscountChange(prevProps.selectedDiscount);
  }

  shouldUpdateBrands = (prevDiscount, currentDiscount) => {
    if (!prevDiscount || (!prevDiscount?.is_brand_discount_only && currentDiscount?.is_brand_discount_only)) return true;
    if (prevDiscount?.is_brand_discount_only && (!currentDiscount || !currentDiscount?.is_brand_discount_only)) return true;
    if (
      prevDiscount?.is_brand_discount_only &&
      currentDiscount?.is_brand_discount_only & !_.isEqual(prevDiscount.tyre_brand_discounts, currentDiscount.tyre_brand_discounts)
    )
      return true;

    return false;
  };

  handleUpdateBrandsOnDiscountChange = prevDiscount => {
    const { selectedDiscount, selectedDeliveries, selectedTyreQuestionBrand, onChangeSelectedBrand } = this.props;

    if (this.shouldUpdateBrands(prevDiscount, selectedDiscount)) {
      TyreOptions.getDropdownOptions()
        .then(result => {
          let brands = result.map(brand => ({
            key: brand,
            value: brand,
            text: brand,
          }));

          if (selectedDiscount?.is_brand_discount_only) brands = brands.filter(brand => selectedDiscount.tyre_brand_discounts?.some(tbd => tbd.brand === brand.value));

          this.setState({ brands }, () => {
            const brand =
              selectedDiscount?.tyre_brand_discounts?.length > 0
                ? selectedDiscount?.tyre_brand_discounts[0].brand
                : selectedTyreQuestionBrand
                ? selectedTyreQuestionBrand
                : null;

            onChangeSelectedBrand(brand);
            this.handleSearchTires(selectedDeliveries, brand);
          });
        })
        .catch(errorMessage => {
          console.error(errorMessage);
        });
    }
  };

  renderAlert = () => {
    const props = {
      offset: 20,
      position: "top right",
      theme: "light",
      time: 2000,
      transition: "fade",
    };

    return (
      <div className="react-alert-container">
        <AlertContainer ref={a => (this.msg = a)} {...props} />
      </div>
    );
  };

  getDiscounts = () => {
    if (!this.props.isTyreTeamEnabled) return;

    Service.getDiscounts({ dealer_id: this.props.dealer_id })
      .then(res => {
        let discounts = [];

        if (res?.data?.data) {
          discounts = res.data.data
            .filter(r => r.active)
            .map(r => ({
              text: r.name,
              key: r.id,
              value: r.id,
              id: r.id,
              discount: r.discount,
              net_margin_euro: r.net_margin_euro,
              net_margin_percent: r.net_margin_percent,
              tyre_brand_discounts: r.tyre_brand_discounts,
              is_brand_discount_only: r.is_brand_discount_only,
            }));

          this.setState({ discounts });
        }
      })
      .catch(error => {
        console.log("Error getting discounts", error);
      });
  };

  sortSearchResults = results =>
    results.sort((a, b) => {
      if (a.checkbox_selected !== b.checkbox_selected) return a.checkbox_selected ? -1 : 1;
      if (a.manufacturer !== b.manufacturer) return a.manufacturer.localeCompare(b.manufacturer);
      if (a.width !== b.width) return a.width < b.width ? -1 : 1;
      if (a.height !== b.height) return a.height < b.height ? -1 : 1;
      if (a.size !== b.size) return a.size < b.size ? -1 : 1;
      if (a.season !== b.season) return a.season < b.season ? -1 : 1;

      return a.description.localeCompare(b.description);
    });

  fetchBrands = () => {
    TyreOptions.getDropdownOptions()
      .then(result => {
        const { selectedDiscount } = this.props;

        let brands = result.map(brand => ({
          key: brand,
          value: brand,
          text: brand,
        }));

        if (selectedDiscount?.is_brand_discount_only) brands = brands.filter(brand => selectedDiscount.tyre_brand_discounts?.some(tbd => tbd.brand === brand.value));

        this.setState({ brands });
      })
      .catch(errorMessage => {
        console.error(errorMessage);
      });
  };

  updateSelectedDeliveries = () => {
    if (!this.props.selectedDeliveries.length) {
      const selectedDeliveries = this.props.deliveries.filter(d => !d.id.includes("PICKUP")).map(delivery => delivery.id);
      this.props.onChangeSelectedDeliveries(selectedDeliveries);
      if (selectedDeliveries.length) this.handleSearchTires(selectedDeliveries);
    } else this.handleSearchTires(this.props.selectedDeliveries);
  };

  handleSearchTires = (selectedDeliveries = this.props.selectedDeliveries, brand = null) => {
    const { isTyreTeamEnabled, location_id, onSearchResults, size, height, width, manufacturer, season, in_stock } = this.props;

    this.setState({ loading: true }, async () => {
      let response;

      try {
        const params = {
          width: width ? Number(width) : null,
          height: height ? Number(height) : null,
          size: size && height ? Number(size) : null,
          manufacturer: brand ? brand : manufacturer ? manufacturer : null,
          season: Number.isInteger(season) ? season : null,
          tyre_team_delivery_ids: selectedDeliveries || null,
          id: location_id,
          in_stock,
        };

        if (isTyreTeamEnabled) response = await Service.searchTyreTeamTires(params);
        else response = await Service.searchTires({ ...params, limit: 1000 });

        let sortedResults = [];
        if (response?.data?.data?.tyres) sortedResults = this.sortSearchResults(response.data.data.tyres);

        onSearchResults(sortedResults);
        this.setState({ loading: false });
      } catch (err) {
        const errorMessage = typeof err?.response?.data !== "string" ? err.response.data.errors[0] : "Tyre search failed";
        console.error("Error fetching tires", errorMessage);
        this.msg.show(errorMessage, { type: "error" });
        this.setState({ loading: false });
        onSearchResults(null);
      }
    });
  };

  handleAddReplacements = () => {
    const { isTyreTeamEnabled, question_result_id, search_results, t } = this.props;

    this.setState({ loading: true }, async () => {
      const selectedResults = search_results.filter(res => res.checkbox_selected);

      for (let i = 0; i < selectedResults.length; i++) {
        const result = selectedResults[i];

        try {
          if (isTyreTeamEnabled && result.system_number) {
            await Service.addTyreTeamReplacements({ question_result_id, price: Number(result.customerPrice), tyre_team: result });
          } else await Service.addTiresReplacements({ question_result_id, tyre_id: result.id, price: Number(result.price) });
        } catch (err) {
          console.error("Error adding replacements", err, result);
          this.msg.show((t("error_adding_replacement").message || "Error Adding Replacement") + ": " + result.description, {
            time: 4000,
            type: "error",
          });
        }
      }

      this.setState({ loading: false }, () => this.props.close());
    });
  };

  getDeliveryLong = delivery => {
    const { t } = this.props;

    const { order_before, delivery_before, delivery_from } = {
      order_before: delivery.order_before ? moment(delivery.order_before).format("HH:mm") : null,
      delivery_before: delivery.delivery_before ? moment(delivery.delivery_before).format("HH:mm") : null,
      delivery_from: delivery.delivery_from ? moment(delivery.delivery_from).format("HH:mm") : null,
    };

    if (delivery.id.includes("PICKUPSAMEDAY"))
      return t("tyre_team_pickup_sameday", { order_before, delivery_from }).message || t("pickup_same_day").message || "Pickup same day";
    if (delivery.id.includes("SAMEDAY")) return t("tyre_team_sameday", { order_before, delivery_before }).message || t("same_day").message || "Same day";
    if (delivery.id.includes("PICKUPOVERNIGHT"))
      return t("tyre_team_pickupovernight", { order_before, delivery_from }).message || t("pickup_overnight").message || "Pickup overnight";
    if (delivery.id.includes("OVERNIGHT")) return t("tyre_team_overnight", { order_before, delivery_before }).message || t("overnight").message || "Overnight";

    return "";
  };

  getDeliveryOptions = () => {
    return this.props.deliveries.map(d => ({
      key: d.id,
      value: d.id,
      text: this.getDeliveryLong(d),
    }));
  };

  getDeliveryChannel = channel => {
    switch (channel) {
      case TYRE_TEAM_CHANNEL.WHEEL_TYRE:
        return "Wheel-Tyre";
      case TYRE_TEAM_CHANNEL.BANDEN_EXPRESS:
        return "Banden Express";

      default:
        return "";
    }
  };

  getDelivery = delivery => {
    const { t, deliveries } = this.props;

    const selectedDelivery = deliveries.find(d => d.id === delivery) || {};

    const { order_before, delivery_before, delivery_from } = {
      order_before: selectedDelivery.order_before ? moment(selectedDelivery.order_before).format("HH:mm") : null,
      delivery_before: selectedDelivery.delivery_before ? moment(selectedDelivery.delivery_before).format("HH:mm") : null,
      delivery_from: selectedDelivery.delivery_from ? moment(selectedDelivery.delivery_from).format("HH:mm") : null,
    };

    if (delivery.includes("PICKUPSAMEDAY"))
      return (
        <Popup
          content={<span>{t("tyre_team_pickupsameday_detail", { order_before, delivery_from }).message || t("pickup_sameday").message || "Pickup sameday"}</span>}
          trigger={<span>{t("pickup_sameday").message || "Pickup sameday"}</span>}
        />
      );

    if (delivery.includes("SAMEDAY"))
      return (
        <Popup
          content={<span>{t("tyre_team_sameday_detail", { order_before, delivery_before }).message || t("sameday").message || "Sameday"}</span>}
          trigger={<span>{t("sameday").message || "Sameday"}</span>}
        />
      );

    if (delivery.includes("PICKUPOVERNIGHT"))
      return (
        <Popup
          content={<span>{t("tyre_team_pickupovernight_detail", { order_before, delivery_from }).message || t("pickup_overnight").message || "Pickup Overnight"}</span>}
          trigger={<span>{t("pickup_overnight").message || "Pickup Overnight"}</span>}
        />
      );

    if (delivery.includes("OVERNIGHT")) {
      return (
        <Popup
          content={<span>{t("tyre_team_overnight_detail", { order_before, delivery_before }).message || t("overnight").message || "Overnight"}</span>}
          trigger={<span>{t("overnight").message || "Overnight"}</span>}
        />
      );
    }

    return "";
  };

  renderForm = () => {
    const { brands, discounts, loading, loadingDeliveries } = this.state;
    const {
      t,
      onChangeSelectedWidth,
      onChangeSelectedHeight,
      onChangeSelectedSize,
      onChangeSelectedBrand,
      onChangeSelectedDiscount,
      onChangeSelectedSeason,
      onChangeSelectedInStock,
      onChangeSelectedDeliveries,
      width,
      height,
      size,
      manufacturer,
      selectedDiscount,
      season,
      in_stock,
      selectedDeliveries,
      isTyreTeamEnabled,
    } = this.props;

    const seasonOptions = [
      {
        key: SEASONS.ALL_SEASONS,
        value: SEASONS.ALL_SEASONS,
        text: t("all_seasons").message || "All season",
      },
      {
        key: SEASONS.SUMMER,
        value: SEASONS.SUMMER,
        text: t("summer_season").message || "Summer",
      },

      {
        key: SEASONS.WINTER,
        value: SEASONS.WINTER,
        text: t("winter_season").message || "Winter",
      },
    ];

    return (
      <Form>
        {isTyreTeamEnabled && (
          <Form.Group>
            <Form.Field width={4}>
              <label>{t("discount_name").message || "Discount Name"}</label>
              <Dropdown
                selection
                clearable
                selectOnBlur={false}
                options={discounts}
                value={selectedDiscount ? selectedDiscount.value : null}
                onChange={(_, data) => {
                  const discount = discounts.find(d => d.key === data.value);

                  onChangeSelectedDiscount(discount);
                }}
              />
            </Form.Field>
          </Form.Group>
        )}

        <Form.Group>
          <Form.Field width={2} required>
            <label>{t("width").message || "Width"}</label>
            <Form.Input name="width" value={width} onChange={evt => onChangeSelectedWidth(evt.target.value)} className="replacement-tyre-offer-input-field"></Form.Input>
          </Form.Field>

          <Form.Field width={2}>
            <label>{t("height").message || "Height"}</label>
            <Form.Input
              name="height"
              value={height}
              onChange={evt => onChangeSelectedHeight(evt.target.value)}
              className="replacement-tyre-offer-input-field"
            ></Form.Input>
          </Form.Field>

          <Form.Field width={2}>
            <label>{t("size").message || "Size"}</label>
            <Form.Input
              name="size"
              disabled={!height}
              value={size}
              onChange={evt => onChangeSelectedSize(evt.target.value)}
              className="replacement-tyre-offer-input-field"
            ></Form.Input>
          </Form.Field>

          <Form.Field width={4}>
            <label>{t("brand").message || "Brand"}</label>
            <Dropdown
              selection
              selectOnBlur={false}
              options={brands}
              clearable={!selectedDiscount?.is_brand_discount_only}
              search
              value={manufacturer}
              name="manufacturer"
              onChange={(_evt, { name, value }) => onChangeSelectedBrand(value)}
            />
          </Form.Field>

          <Form.Field width={3}>
            <label>{t("season").message || "Season"}</label>
            <Dropdown
              selection
              selectOnBlur={false}
              clearable
              options={seasonOptions}
              value={season}
              name="season"
              onChange={(_evt, { name, value }) => onChangeSelectedSeason(value)}
            />
          </Form.Field>

          {isTyreTeamEnabled && (
            <>
              <Form.Field width={6} required>
                <label>{t("delivery").message || "Delivery"}</label>
                <Dropdown
                  selection
                  selectOnBlur={false}
                  clearable
                  className="DeliveryDropdown"
                  options={this.getDeliveryOptions()}
                  placeholder={t("select_deliveries").message || "Select deliveries"}
                  loading={loadingDeliveries}
                  value={selectedDeliveries}
                  name="delivery"
                  multiple
                  onChange={(_evt, { value }) => onChangeSelectedDeliveries(value)}
                />
              </Form.Field>

              <Form.Field width={1} style={{ textAlign: "center" }}>
                <label>{t("in_stock").message || "In stock"}</label>
                <Checkbox toggle style={{ marginTop: "7px" }} checked={in_stock} onChange={(_evt, { checked }) => onChangeSelectedInStock(checked)} name="in_stock" />
              </Form.Field>
            </>
          )}

          <Form.Field>
            <label style={{ visibility: "hidden" }}>Search</label>
            <Button
              disabled={!width || (isTyreTeamEnabled && !selectedDeliveries.length) || loading}
              loading={loading}
              onClick={() => this.handleSearchTires()}
              style={{ color: "#000", fontWeight: "600" }}
            >
              {t("search").message || "Search"}
            </Button>
          </Form.Field>
        </Form.Group>
      </Form>
    );
  };

  tyreTeamColumns = () => {
    const { isTyreTeamEnabled, t } = this.props;

    if (!isTyreTeamEnabled) return [];

    return [
      {
        Header: "Art / EAN",
        id: "System number",
        accessor: d => (
          <div>
            {d.system_number}
            {d.ean && (
              <>
                {" / "}
                <Popup hoverable trigger={<div className="replacement-tyre-ean">{d.ean}</div>} content={d.ean} />
              </>
            )}
          </div>
        ),
        minWidth: 120,
      },
      {
        Header: `${t("gross").message || "Gross"} €`,
        id: "Price Gross",
        accessor: d =>
          d.stock && d.gross_price ? (
            <div>
              {d.gross_price} {d.discount && `- ${d.discount}%`}
            </div>
          ) : (
            "0.0"
          ),
        minWidth: 90,
      },
      {
        Header: `${t("neto").message || "Neto"} €`,
        id: "Price Net",
        accessor: d =>
          d.stock && d.net_price ? (
            <div className={`${d.customerPrice && d.customerPrice < d.net_price ? "-lower-customer-price" : ""}`}>
              {d.net_price}

              {d.net_percent_discount && ` + ${d.net_percent_discount}%`}
              {d.price_reduction && ` + ${d.price_reduction}€`}
            </div>
          ) : (
            "0.0"
          ),
        minWidth: 90,
      },
      {
        Header: t("customer_€").message || "Customer €",
        id: "customerPrice",
        accessor: d => {
          if (!d.customerPrice) return null;
          return <div className={`${d.customerPrice < d.net_price ? "-lower-customer-price" : ""}`}>{round2(Number(d.customerPrice)).toFixed(2)}</div>;
        },
        minWidth: 70,
      },
      {
        Header: t("load").message || "Load",
        accessor: "carry_capacity",
        minWidth: 60,
      },
      {
        Header: t("speed").message || "Speed",
        accessor: "speed",
        minWidth: 60,
      },
      {
        Header: (
          <Popup trigger={<div className="-text-ellipsis">{t("flat_tire").message || "Flat Tire"}</div>} content={<div>{t("flat_tire").message || "Flat Tire"}</div>} />
        ),
        id: "run_flat_tire",
        className: "ReactTable__column -text-center-column",
        headerClassName: "ReactTable__column-header -text-center-header",
        accessor: d =>
          d.run_flat_tire ? (
            <div className="-text-center">
              <Icon name="check circle" color="green" />
            </div>
          ) : null,
        minWidth: 60,
      },
      {
        Header: t("channel").message || "Channel",
        id: "Channel",
        accessor: d => (d.channel ? <div>{this.getDeliveryChannel(d.channel)}</div> : null),
        minWidth: 100,
      },
      {
        Header: t("stock").message || "Stock",
        id: "stock",
        className: "-text-center-column",
        headerClassName: "-text-center-header",
        accessor: d => (d.stock ? d.stock : 0),
        minWidth: 80,
      },

      {
        Header: t("delivery").message || "Delivery",
        id: "Delivery",
        accessor: d => (d.delivery ? this.getDelivery(d.delivery) : null),
        minWidth: 100,
      },
    ];
  };

  claireTireColumns = () => {
    const { isTyreTeamEnabled, t, onChangeReplacementPrice } = this.props;

    if (isTyreTeamEnabled) return [];

    return [
      {
        Header: t("price").message || "Price",
        id: "Price",
        accessor: d => (
          <Form.Input
            name="price"
            value={d.price || ""}
            placeholder="0.0"
            onChange={evt => onChangeReplacementPrice(d.id, evt.target.value)}
            className="replacement-tyre-offer-price-input-field"
          ></Form.Input>
        ),
      },
    ];
  };

  renderTable = () => {
    const { loading } = this.state;
    const { search_results, onSelectReplacement, t } = this.props;

    return (
      <ReactTable
        className="ReplacementOfferTable ReactTable -floated-table no-overflow all-data-table -initial-margin"
        showPagination={true}
        showPageSizeOptions={true}
        defaultPageSize={100}
        sortable={false}
        resizable={false}
        scrolling={false}
        minRows={0}
        loading={loading}
        loadingText={(t("loading").message || "Loading") + "..."}
        data={search_results || []}
        nextText={t("next").message || "Next"}
        previousText={t("previous").message || "Previous"}
        pageText={t("page").message || "Page"}
        ofText={t("of").message || "of"}
        noDataText={<div className="Table__no-results">{t("no_tires_found").message || "No tires found"}</div>}
        column={{
          ...ReactTableDefaults.column,
          headerClassName: "ReactTable__column-header -text-ellipsis",
          className: "ReactTable__column",
        }}
        columns={[
          {
            Header: "",
            id: "Season",
            accessor: d => (
              <div className="season-cell">
                {getTyreSeasonIcon(d.season)}

                {d["3pmsf"] === 2 && (
                  <Label color={"grey"} className="pmsf-icon-container">
                    <img alt="3pmsf" src="/img/3pmsf_outline_white.svg" />
                  </Label>
                )}
              </div>
            ),
            minWidth: 75,
          },
          {
            Header: t("description").message || "Description",
            id: "Description",
            accessor: d => <Popup trigger={<div className="-text-ellipsis">{d.description}</div>} content={<div>{d.description}</div>} />,
            minWidth: 280,
          },
          {
            Header: t("brand").message || "Brand",
            id: "Brand",
            accessor: d => <div className="-text-ellipsis">{d.manufacturer}</div>,
            minWidth: 135,
          },
          ...this.tyreTeamColumns(),
          ...this.claireTireColumns(),
          {
            Header: t("add").message || "Add",
            className: "-text-center-column",
            headerClassName: "-text-center-header",
            Cell: props => <Checkbox checked={!!props.original.checkbox_selected} onChange={() => onSelectReplacement(props.original)} />,
            minWidth: 75,
          },
        ]}
      />
    );
  };

  render() {
    const { loading } = this.state;
    const { search_results, close, t } = this.props;

    return (
      <Modal open={true} closeOnDimmerClick={false} size="fullscreen" className="tyre-team-replacement-modal">
        <Modal.Header style={{ display: "flex", justifyContent: "space-between" }}>
          <div>{t("add_replacement").message || "Add Replacement"}</div>
          <Icon name="close" floated="right" onClick={close} color="grey" style={{ cursor: "pointer" }} />
        </Modal.Header>

        <Modal.Content scrolling style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
          {this.renderForm()}
          <div style={{ fontSize: "14px", fontWeight: "600", marginTop: "20px" }}>{t("results").message || "Results"}</div>
          {this.renderTable()}
        </Modal.Content>

        <Modal.Actions>
          <Button color="green" disabled={loading || !search_results?.some(res => res.checkbox_selected)} loading={loading} onClick={this.handleAddReplacements}>
            {t("add").message || "Add"}
            <Icon name="check" className="-margin-left-5" />
          </Button>
        </Modal.Actions>
        {this.renderAlert()}
      </Modal>
    );
  }
}

export default withTranslation()(ReplacementTyreOffer);
