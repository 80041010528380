import axios from "axios";

import ENV from "../../util/env-config";
import { getSessionCookie } from "../../util/common";

class Service {
  static requestConfig() {
    return {
      headers: {
        Authorization: `Bearer ${getSessionCookie()}`,
      },
    };
  }

  static getTires() {
    return axios.get(`${ENV.carBaseUrl}/tyres/incomplete/list`, this.requestConfig());
  }

  static getCompleteTires(requestData) {
    return axios.post(`${ENV.carBaseUrl}/tyres/search`, { ...requestData }, this.requestConfig());
  }

  static completeTire(requestData, tyre_id) {
    return axios.post(`${ENV.carBaseUrl}/tyres/incomplete/complete`, { ...requestData, tyre_id }, this.requestConfig());
  }

  static replaceTire(complete_tyre_id, incomplete_tyre_id) {
    return axios.post(`${ENV.carBaseUrl}/tyres/incomplete/replace`, { complete_tyre_id, incomplete_tyre_id }, this.requestConfig());
  }

  static deleteTire(tyre_id) {
    return axios.post(`${ENV.carBaseUrl}/tyres/incomplete/delete`, { tyre_id }, this.requestConfig());
  }

  static getTireBrands(requestData) {
    return axios.get(`${ENV.carBaseUrl}/tyres/brands/list`, this.requestConfig());
  }
}

export default Service;
