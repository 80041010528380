import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Button, Icon, Modal, Form, Dropdown, TextArea, Grid, GridRow, GridColumn, Label } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import moment from "moment";

import Can from "../../modules/Can";
import CustomConfirm from "../CustomConfirm";
import Service from "./service";
import { SNOOZE_TYPE, SNOOZE_STATUS } from "./enum";
class SnoozeModal extends Component {
  constructor(props) {
    super(props);

    const { snoozeTypeOptions, snoozeStatusOptions, snoozeDepartmentOptions } = this.getDropdownOptions();

    this.state = {
      showDeleteConfirmation: false,
      showCancelConfirmation: false,
      isDeleting: false,
      isDiscarding: false,
      deleteError: "",
      hasMissingFields: false,
      snoozeTypeOptions,
      snoozeStatusOptions,
      snoozeDepartmentOptions,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dropdownOptions !== this.props.dropdownOptions) {
      const { snoozeTypeOptions, snoozeStatusOptions } = this.getDropdownOptions();
      this.setState({ snoozeTypeOptions, snoozeStatusOptions });
    }
  }

  getDropdownOptions = () => {
    const {
      t,
      dropdownOptions,
      globalState: { selectedDealer },
    } = this.props;

    let snoozeTypeOptions = [],
      snoozeStatusOptions = [],
      snoozeDepartmentOptions = [];

    if (dropdownOptions) {
      if (Array.isArray(dropdownOptions.snooze_statuses)) {
        dropdownOptions.snooze_statuses.forEach(s => {
          snoozeStatusOptions[s.id] = t(s.name).message || s.name;
        });
      }

      if (Array.isArray(dropdownOptions.snooze_types)) {
        dropdownOptions.snooze_types.forEach(s => {
          const isEnabled =
            (s.id === SNOOZE_TYPE.NEXT_VISIT_CUSTOMER && selectedDealer.is_snooze_type_next_visit_customer_enabled) ||
            (s.id === SNOOZE_TYPE.MAKE_OFFER && selectedDealer.is_snooze_type_make_offer_enabled) ||
            (s.id === SNOOZE_TYPE.MAKE_NEW_APPOINTMENT && selectedDealer.is_snooze_type_make_new_appointment_enabled) ||
            (s.id === SNOOZE_TYPE.REMIND_CUSTOMER && selectedDealer.is_snooze_type_remind_customer_enabled);

          if (isEnabled) snoozeTypeOptions[s.id] = t(s.name).message || s.name;
        });
      }

      if (Array.isArray(dropdownOptions.snooze_departments)) {
        dropdownOptions.snooze_departments.forEach(s => {
          snoozeDepartmentOptions[s.id] = t(s.name).message || s.name;
        });
      }
    }

    return { snoozeTypeOptions, snoozeStatusOptions, snoozeDepartmentOptions };
  };

  handleInputChange = e => {
    const { snoozeItem, onChange } = this.props;
    onChange({ ...snoozeItem, [e.target.name]: e.target.value });
  };

  handleDateChange = date => {
    const { snoozeItem, onChange } = this.props;
    onChange({ ...snoozeItem, snooze_date: moment.utc(moment(date).format("YYYY-MM-DD")).format() });
  };

  handleSelectChange = (e, data) => {
    const { snoozeItem, onChange } = this.props;
    onChange({ ...snoozeItem, [data.name]: data.value || null });
  };

  handleSnoozeTypeChange = (e, data) => {
    const { snoozeItem, onChange } = this.props;
    onChange({ ...snoozeItem, snooze_type_id: data.value, snooze_date: !this.snoozeTypeHasDate(data.value) ? null : snoozeItem.snooze_date });
  };

  snoozeTypeHasDate = type => [SNOOZE_TYPE.MAKE_OFFER, SNOOZE_TYPE.REMIND_CUSTOMER].includes(type);

  handleDelete = deleteNote => {
    const { interventionId, questionResultId, onDelete, t } = this.props;

    const snoozeItem = {
      ...(questionResultId && { question_result_id: questionResultId }),
      ...(interventionId && { intervention_id: interventionId }),
      note: deleteNote,
    };

    this.setState({ isDeleting: true }, () => {
      Service.deleteSnooze(snoozeItem)
        .then(result => {
          this.setState({ isDeleting: false, showDeleteConfirmation: false }, () => onDelete(deleteNote));
        })
        .catch(error => {
          console.error(error?.response?.data?.errors?.shift() || t("unexpected_error").message || "Unexpected error, please try to reload the page.");
          this.setState({ isDeleting: false, deleteError: t("cannot_delete_snooze_item").message || "Can't delete snooze item." });
        });
    });
  };

  handleValidateRequest = () => {
    const {
      globalState: { selectedDealer },
      snoozeItem,
    } = this.props;

    return !!snoozeItem?.snooze_type_id && (!selectedDealer.is_snooze_status_enabled || !!snoozeItem.snooze_status_id);
  };

  handleSave = () => {
    if (this.handleValidateRequest()) this.props.onSave();
    else {
      this.setState({ hasMissingFields: true });
    }
  };

  handleClose = () => {
    this.setState({ hasMissingFields: false }, () => this.props.onClose());
  };

  toggleDeleteConfirmation = () => {
    this.setState(prevState => ({ showDeleteConfirmation: !prevState.showDeleteConfirmation, deleteError: "" }));
  };

  toggleCancelConfirmation = () => {
    this.setState(prevState => ({ showCancelConfirmation: !prevState.showCancelConfirmation }));
  };

  handleConfirmCancel = () => {
    this.setState({ showCancelConfirmation: false }, () => {
      this.handleClose();
    });
  };

  renderCancelConfirmation = () => {
    return (
      <CustomConfirm
        type="warning"
        isOpen={this.state.showCancelConfirmation}
        handleCancel={this.toggleCancelConfirmation}
        handleConfirm={this.handleConfirmCancel}
        isLoading={this.state.isDiscarding}
        confirmMsg={this.props.t("confirm_discard_message").message || "There are some unsaved changes. Are you sure that you want to cancel?"}
      />
    );
  };

  renderDeleteConfirmation = () => {
    const { t } = this.props;
    const { showDeleteConfirmation, isDeleting, deleteError } = this.state;

    return (
      <CustomConfirm
        type="danger"
        isOpen={showDeleteConfirmation}
        handleConfirm={this.handleDelete}
        handleCancel={this.toggleDeleteConfirmation}
        confirmMsg={t("confirm_delete_message").message || "Are you sure that you want to delete this? You can't undo this action."}
        confirmMandatoryNote={t("delete_note").message || "Reason for deleting snooze item"}
        isLoading={isDeleting}
        error={deleteError}
      />
    );
  };

  renderLogUpdates = (snoozeItem, prevSnoozeItem) => {
    const { snoozeTypeOptions, snoozeStatusOptions, snoozeDepartmentOptions } = this.state;

    if (prevSnoozeItem) {
      return (
        <GridRow className="log_values">
          {!!snoozeItem.snooze_type_id && snoozeItem.snooze_type_id !== prevSnoozeItem.snooze_type_id && (
            <Label>
              <Icon name="time" />
              {snoozeTypeOptions[snoozeItem.snooze_type_id]}
            </Label>
          )}
          {!!snoozeItem.snooze_status_id && snoozeItem.snooze_status_id !== prevSnoozeItem.snooze_status_id && (
            <Label>
              <Icon name="list alternate" />
              {snoozeStatusOptions[snoozeItem.snooze_status_id]}
            </Label>
          )}
          {!!snoozeItem.snooze_date && snoozeItem.snooze_date !== prevSnoozeItem.snooze_date && (
            <Label>
              <Icon name="calendar alternate outline" />
              {moment(snoozeItem.snooze_date).format("DD-MM-YYYY")}
            </Label>
          )}
          {snoozeItem.snooze_department_id !== prevSnoozeItem.snooze_department_id && (
            <Label>
              <Icon name="building" />
              {!snoozeItem.snooze_department_id
                ? this.props.t("department_removed").message || "Department removed"
                : snoozeDepartmentOptions[snoozeItem.snooze_department_id]}
            </Label>
          )}
        </GridRow>
      );
    }
  };

  renderInitialLogValues = snoozeItem => {
    const { snoozeTypeOptions, snoozeStatusOptions, snoozeDepartmentOptions } = this.state;

    if (snoozeItem) {
      return (
        <GridRow className="log_values">
          {!!snoozeItem.snooze_type_id && (
            <Label>
              <Icon name="time" />
              {snoozeTypeOptions[snoozeItem.snooze_type_id]}
            </Label>
          )}
          {!!snoozeItem.snooze_status_id && (
            <Label>
              <Icon name="list alternate" />
              {snoozeStatusOptions[snoozeItem.snooze_status_id]}
            </Label>
          )}
          {!!snoozeItem.snooze_date && (
            <Label>
              <Icon name="calendar alternate outline" />
              {moment(snoozeItem.snooze_date).format("DD-MM-YYYY")}
            </Label>
          )}
          {!!snoozeItem.department_id && (
            <Label>
              <Icon name="building" />
              {snoozeDepartmentOptions[snoozeItem.snooze_department_id]}
            </Label>
          )}
        </GridRow>
      );
    }
  };

  renderLogHistory = () => {
    const { t, snoozeLog } = this.props;

    return (
      <div className="Snooze-LogHistory">
        <Grid>
          <GridRow className="headers">{t("log_history").message || "Log history"}</GridRow>
        </Grid>
        <div className="content">
          {!snoozeLog.length && <div className="no-data">{t("no_log_history").message || "No log history"}</div>}

          {snoozeLog.map((snoozeItem, i) => (
            <div className="inner-row" key={snoozeItem.id}>
              <Grid>
                <GridRow>
                  <GridColumn width={12}>
                    <GridRow>
                      <strong>
                        {snoozeItem.user.first_name} {snoozeItem.user.last_name}
                      </strong>
                      {" - "}
                      {moment(snoozeItem.created_on).format("HH:mm DD-MM-YYYY")}
                      {snoozeItem.snooze_status_id === SNOOZE_STATUS.DELETED && (
                        <>
                          {" - "}
                          {<strong style={{ color: "red" }}>{t("deleted").message || "Deleted"}</strong>}
                        </>
                      )}
                      {snoozeItem.mechanic_fixed && (
                        <>
                          {" - "}
                          {<strong style={{ color: "blue" }}>{t("marked_as_fixed").message || "Marked as fixed"}</strong>}
                        </>
                      )}
                    </GridRow>
                    {i === snoozeLog.length - 1 || snoozeLog[i - 1]?.snooze_status_id === SNOOZE_STATUS.DELETED
                      ? this.renderInitialLogValues(snoozeItem)
                      : this.renderLogUpdates(snoozeItem, snoozeLog[i + 1])}
                    {!snoozeItem.mechanic_fixed && snoozeItem.note && <GridRow>{snoozeItem.note}</GridRow>}
                  </GridColumn>
                </GridRow>
              </Grid>
            </div>
          ))}
        </div>
      </div>
    );
  };

  render() {
    const { isDeleting, snoozeTypeOptions, snoozeStatusOptions, snoozeDepartmentOptions, hasMissingFields } = this.state;
    const {
      readOnly,
      t,
      isOpen,
      isUpdated,
      snoozeItem,
      snoozeLog,
      isLoading,
      errorMessage,
      getCurrentSnooze,
      globalState: { selectedDealer },
    } = this.props;

    return (
      <>
        <Modal open={isOpen} onClose={this.handleClose} closeOnDimmerClick={false} className="Snooze-Modal">
          <Modal.Header>{t("snooze_item").message || "Snooze item"}</Modal.Header>
          <Modal.Content scrolling>
            <Form>
              <Form.Group>
                <Form.Field width={8} required error={hasMissingFields && !snoozeItem.snooze_type_id}>
                  <label>{t("snooze_type").message || "Snooze Type"}</label>
                  <Dropdown
                    placeholder={snoozeTypeOptions.length ? t("select_snooze_type").message || "Select Snooze Type" : t("loading").message || "Loading"}
                    options={Object.keys(snoozeTypeOptions).map(key => ({ key: Number(key), text: snoozeTypeOptions[Number(key)], value: Number(key) }))}
                    disabled={readOnly}
                    selection
                    name="snooze_type_id"
                    value={snoozeItem.snooze_type_id}
                    onChange={this.handleSnoozeTypeChange}
                  />
                </Form.Field>
                <Form.Field width={8} className="date-picker-wrapper">
                  <label>{t("snooze_date").message || "Snooze date"}</label>
                  <DatePicker
                    disabled={!this.snoozeTypeHasDate(snoozeItem.snooze_type_id) || readOnly}
                    dateFormat="dd-MM-yyyy"
                    placeholderText={t("snooze_date").message || "Snooze date"}
                    name="snooze_date"
                    selected={snoozeItem.snooze_date && moment(snoozeItem.snooze_date).toDate()}
                    onChange={this.handleDateChange}
                    onChangeRaw={e => e.preventDefault()}
                    showMonthDropdown
                    showYearDropdown
                  />
                </Form.Field>
              </Form.Group>
              {(selectedDealer.is_snooze_status_enabled || selectedDealer.is_snooze_department_enabled) && (
                <Form.Group>
                  {selectedDealer.is_snooze_status_enabled && (
                    <Form.Field width={8} required error={hasMissingFields && !snoozeItem.snooze_status_id}>
                      <label>{t("snooze_status").message || "Snooze status"}</label>
                      <Dropdown
                        placeholder={snoozeStatusOptions.length ? t("select_snooze_status").message || "Select snooze status" : t("loading").message || "Loading"}
                        options={Object.keys(snoozeStatusOptions).map(key => ({ key: Number(key), text: snoozeStatusOptions[Number(key)], value: Number(key) }))}
                        disabled={readOnly}
                        selection
                        name="snooze_status_id"
                        value={snoozeItem.snooze_status_id}
                        onChange={this.handleSelectChange}
                      />
                    </Form.Field>
                  )}

                  {selectedDealer.is_snooze_department_enabled && (
                    <Form.Field width={8}>
                      <label>{t("department").message || "Department"}</label>
                      <Dropdown
                        placeholder={
                          snoozeDepartmentOptions.length ? t("select_snooze_department").message || "Select snooze department" : t("loading").message || "Loading"
                        }
                        options={Object.keys(snoozeDepartmentOptions).map(key => ({
                          key: Number(key),
                          text: snoozeDepartmentOptions[Number(key)],
                          value: Number(key),
                        }))}
                        disabled={readOnly}
                        selection
                        clearable
                        name="snooze_department_id"
                        value={snoozeItem.snooze_department_id}
                        onChange={this.handleSelectChange}
                      />
                    </Form.Field>
                  )}
                </Form.Group>
              )}
              <Form.Field>
                <label>{t("note").message || "Note"}</label>
                <TextArea
                  disabled={readOnly}
                  value={snoozeItem.note}
                  name="note"
                  placeholder={t("enter_note").message || "Enter note"}
                  onChange={this.handleInputChange}
                />
              </Form.Field>
              {this.renderLogHistory()}
              {errorMessage && (
                <Label className="-margin-top-10" basic color="red">
                  {errorMessage}
                </Label>
              )}
            </Form>
          </Modal.Content>
          <Modal.Actions>
            {getCurrentSnooze(snoozeLog).snooze_type_id && (
              <Can I="delete" the="snooze">
                <Button color="red" loading={isDeleting} disabled={isDeleting || readOnly} onClick={this.toggleDeleteConfirmation} floated="left">
                  <Icon name="trash" className="-no-margin" />
                </Button>
              </Can>
            )}
            <Button onClick={isUpdated ? this.toggleCancelConfirmation : this.handleClose} color="grey">
              {t("close").message || "Close"}
            </Button>
            <Button color="green" onClick={this.handleSave} loading={isLoading} disabled={isLoading || !isUpdated || readOnly}>
              <Icon name="checkmark" /> {getCurrentSnooze(snoozeLog).snooze_type_id ? t("update").message || "Update" : t("save").message || "Save"}
            </Button>
          </Modal.Actions>
        </Modal>
        {this.renderDeleteConfirmation()}
        {this.renderCancelConfirmation()}
      </>
    );
  }
}

const mapStateToProps = state => {
  return { globalState: state.global };
};

export default connect(mapStateToProps)(withTranslation()(SnoozeModal));
