export const QUERY_STATUS = {
  PENDING: 1,
  EXECUTED: 2,
  ERROR: 3,
};

export const DMS = {
  UNKNOWN: 1,
  NTCAR: 2,
  EVA: 3,
  WINCAR: 4,
  ANON_EVA: 5,
  AUTOLINE: 6,
  AUTOLINE_EF_NEFKENS: 7,
  TEST_API: 8,
  TEST_SOW: 9,
  INCADEA: 10,
  TRUCK_VISION: 11,
  ICAR_WO: 12,
  CARIT: 13,
  AUTOLINE_OPEL_MENU: 14,
  KEYLOOP_MENUS: 15,
  CUSTOM_QUERIES: 16,
  KEYLOOP_JOBS: 17,
  AUTOLINE_EF_TERWOLDE: 18,
  ICAR_PLANNING: 19,
  AUTOFLEX: 20,
  AUTOFLEX_QA_STAGING: 21,
  NEXTLANE: 22,
  WINCAR_API: 23,
  EVA_BE: 24,
};

export const SEVERITY = {
  LOG: 1,
  WARNING: 2,
  ERROR: 3,
  CRITICAL: 4,
};

export const SOURCE = {
  CLIENT: 1,
  SERVER: 2,
  CUSTOM_QUERY: 3,
};

export const getDMSName = id => {
  switch (id) {
    case DMS.ANON_EVA:
      return "Anonymous Eva";
    case DMS.AUTOLINE_EF_NEFKENS:
      return "Autoline EF Nefkens";
    case DMS.AUTOLINE:
      return "Autoline";
    case DMS.EVA:
      return "EVA";
    case DMS.NTCAR:
      return "NTCar";
    case DMS.TEST_API:
      return "Test API";
    case DMS.TEST_SOW:
      return "Test SOW";
    case DMS.TRUCK_VISION:
      return "TruckVision";
    case DMS.ICAR_WO:
      return "ICar WO";
    case DMS.KEYLOOP_MENUS:
      return "Keyloop Menus";
    case DMS.CARIT:
      return "CarIT";
    case DMS.AUTOLINE_OPEL_MENU:
      return "Autoline Opel Menu";
    case DMS.CUSTOM_QUERIES:
      return "Custom Queries";
    case DMS.KEYLOOP_JOBS:
      return "Keyloop Jobs";
    case DMS.AUTOLINE_EF_TERWOLDE:
      return "Autoline EF Terwolde";
    case DMS.WINCAR:
      return "Wincar";
    case DMS.INCADEA:
      return "Incadea";
    case DMS.ICAR_PLANNING:
      return "ICAR Planning";
    case DMS.AUTOFLEX:
      return "Autoflex";
    case DMS.AUTOFLEX_QA_STAGING:
      return "Autoflex QA Staging";
    case DMS.NEXTLANE:
      return "Nextlane";
    case DMS.WINCAR_API:
      return "Wincar API";
    case DMS.EVA_BE:
      return "EVA Belgium";
    case DMS.UNKNOWN:
      return "Unknown";
    default:
      return "";
  }
};

export const parseDate = time => {
  if (!time) return null;
  if (time instanceof Date) return time;

  const timeValues = time.split(":");
  const date = new Date();
  date.setHours(timeValues[0] - 0, timeValues[1] - 0, timeValues[2] - 0);

  return date;
};
