import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Grid, Button, Modal } from "semantic-ui-react";
import { connect } from "react-redux";
import moment from "moment";

import { FileDropDown } from "./../../components";
import { ROLES } from "../Users/roles";

import CarTyres from "./CarTyres";
import TruckTyres from "./TruckTyres";
import CarDetailMenu from "./CarDetailMenu";
import CarDetailHeader from "./CarDetailHeader";
import CarSnoozed from "./CarSnoozed";
import CarPins from "./CarPins";
import CarDrivingTrend from "./CarDrivingTrend";
import AppointmentHistory from "./AppointmentHistory";
import CarNotes from "./CarNotes";
import { applyWebsocketUpdate, addCarAttachment, deleteCarAttachment, updateSelectedCar, CARS_ACTION_TYPES } from "./store";
import { sortTruckTires, getFilesButton, CAR_NOTE_TYPES } from "../../util/common";
import { TYRE_POSITION } from "../Appointments/common";
import IntegrationsService from "../../integrations/service";

import Service from "./service";

import "./CarDetail.css";

class CarDetail extends Component {
  constructor(props) {
    super(props);

    if (props.car.is_truck) this.truck_tyres = this.prepareTruckTires(props.car.truck_tyres);

    this.state = {
      car_widget: "",
      button_label: "S",
      car: this.props.car,
      appointmentHistory: [],
    };
  }

  prepareTruckTires = tires => {
    const truck_tyres = {};
    let spareTiresCount = 0;

    tires.forEach(tyre => {
      const placeholderTyre = { ...tyre };

      let tiresGroup;
      if (placeholderTyre.position === TYRE_POSITION.TRUCK_SPARE) {
        placeholderTyre.spareTiresCount = ++spareTiresCount;
        tiresGroup = `spare_${spareTiresCount}`;
      } else tiresGroup = placeholderTyre.axle;

      if (!truck_tyres[tiresGroup]) truck_tyres[tiresGroup] = [];

      truck_tyres[tiresGroup].push(placeholderTyre);
    });

    sortTruckTires(truck_tyres);

    return truck_tyres;
  };

  componentDidMount() {
    this.getCarWidget();

    const appointmentHistory = this.props.appointmentHistory.map(app => {
      let location_name = "";

      this.props.globalState.dealers.forEach(d => {
        if (d.locations)
          d.locations.forEach(l => {
            if (l.id === app.dealer_location_id) location_name = l.name;
          });
      });

      return { ...app, location_name };
    });

    this.setState({ appointmentHistory });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.carsState.actionType !== this.props.carsState.actionType) {
      if (this.props.carsState.actionType === CARS_ACTION_TYPES.WEB_SOCKET_UPDATE) {
        this.props.applyWebsocketUpdate(this.props.carsState.webSocketUpdate);
      } else if (this.props.carsState.actionType === CARS_ACTION_TYPES.CAR_UPDATE_APPLIED) {
        this.setState({ car: this.props.carsState.selectedCar });
      }
    }
  }

  getCarWidget = () => {
    let { location, car } = this.props;

    if (car) {
      IntegrationsService.getCarWidget({ location_id: location.id, car_id: car.id })
        .then(result => {
          if (result?.data?.data?.redirect_url) {
            const { redirect_url, button_label } = result.data.data;

            this.setState({
              car_widget: redirect_url,
              button_label: button_label ? button_label : "S",
            });
          }
        })
        .catch(error => {
          console.log(error);
          this.setState({ car_widget: "", button_label: "S" });
        });
    }
  };

  handleOpenSWidget = () => {
    window.open(this.state.car_widget, "_blank");
  };

  handleAddFile = file => {
    const {
      globalState: { dealers },
    } = this.props;
    const { car } = this.state;

    let fd = new FormData();

    const dealer = dealers.find(d => d.id === car.dealer_id);

    const date = moment(car.created_on).format("YYYY/MM/DD");
    const path = `${dealer?.id}_${dealer?.name}/${date}/${car.id}_${car.reg_nr}`;
    fd.append("file", file);
    fd.append("path", path);

    const attachment = {
      type: file.type,
      name: file.name,
    };

    const carNote = car.notes?.find(n => n.car_note_type_id === CAR_NOTE_TYPES.DEFAULT);

    const newCarNote = {
      car_note_type_id: CAR_NOTE_TYPES.DEFAULT,
      car_id: car.id,
      note: "Attachment",
    };

    Service.uploadAttachment(fd)
      .then(result => {
        if (!result?.data?.data) return;

        attachment.url = result.data.data.url;

        if (carNote) {
          const notePayload = { ...carNote, attachments: [...(carNote.attachments || []), attachment] };

          Service.updateNote(notePayload)
            .then(() => {
              attachment.username = this.props.authState.user.first_name + " " + this.props.authState.user.last_name;
              attachment.created_on = new Date();
              this.props.addCarAttachment(notePayload);
            })
            .catch(error => {
              console.error("Error saving the car attachment updates.", error);

              attachment.failed = true;
              this.props.addCarAttachment(notePayload);
            });
        } else {
          const notePayload = {
            car_note_type_id: CAR_NOTE_TYPES.DEFAULT,
            car_id: car.id,
            note: "Attachment",
            attachments: [attachment],
            visible_to_mechanic: true,
          };

          Service.createNote(notePayload)
            .then(response => {
              notePayload.id = response?.data?.data?.car_note_id;
              attachment.username = this.props.authState.user.first_name + " " + this.props.authState.user.last_name;
              attachment.created_on = new Date();
              this.props.addCarAttachment(notePayload);
            })
            .catch(error => {
              console.error("Error saving the car attachment updates.", error);

              attachment.failed = true;
              this.props.addCarAttachment(notePayload);
            });
        }
      })
      .catch(error => {
        console.error("Error uploading the car attachment updates.", error);

        attachment.failed = true;
        this.props.addCarAttachment({ ...newCarNote, attachments: [...(carNote?.attachments || []), attachment] });
      });
  };

  handleRemoveFile = url => {
    const { car } = this.state;

    const carNote = car.notes?.find(n => n.car_note_type_id === CAR_NOTE_TYPES.DEFAULT);
    const attachments = carNote?.attachments.filter(att => att.url !== url);

    Service.updateNote({ ...carNote, attachments })
      .then(() => this.props.deleteCarAttachment(url))
      .catch(error => {
        console.error("Error deleting attachment on car detail.", error);
      });
  };

  render() {
    const { car, appointmentHistory } = this.state;

    const { customer, visible, onHide, globalState, authState, isURLHandler, location, updateSelectedCar, t } = this.props;

    if (!car) return null;

    const carAttachments = car.notes?.find(n => n.car_note_type_id === CAR_NOTE_TYPES.DEFAULT)?.attachments || [];

    return (
      <span>
        <Modal className="CarDetailModal" size="fullscreen" dimmer="inverted" onClose={onHide} closeOnDimmerClick={false} open={visible}>
          <Modal.Header>
            <Grid>
              <Grid.Column width={5} floated="right">
                {!isURLHandler && (
                  <Button
                    color="green"
                    size="large"
                    floated="right"
                    onClick={onHide}
                    style={{
                      fontSize: "1.05rem",
                    }}
                    className="-margin-left-10"
                  >
                    {t("close").message || "Close"}
                  </Button>
                )}

                {globalState.selectedLocation.service_box_visible_on_car ? (
                  <Button basic size="large" floated="right" className="-margin-left-10" style={{ fontSize: "1.05rem" }} onClick={this.handleOpenSWidget}>
                    {this.state.button_label}
                  </Button>
                ) : (
                  ""
                )}

                <div className="FileDropDownCarDetail">
                  <FileDropDown
                    resource="cars"
                    position="left"
                    addFileText={t("drag_here_or_browse").message || "Drag here or browse"}
                    noFilesText={t("no_files").message || "No files"}
                    files={carAttachments}
                    trigger={getFilesButton({
                      count: carAttachments?.length > 0 ? carAttachments.filter(attachment => !attachment.failed).length : null,
                    })}
                    onFileAdded={this.handleAddFile}
                    deleteFile={this.handleRemoveFile}
                    showPreview
                    showCount={false}
                    showIcon={true}
                    multiple
                  />
                </div>
              </Grid.Column>
              <Grid.Column width={16}>
                <CarDetailMenu />
              </Grid.Column>
            </Grid>
          </Modal.Header>
          <Modal.Content scrolling id="carDetailModalScrollContainer">
            <Grid className="CarDetailModal__Content">
              <Grid.Column width={16} id="cardetailscrollsection-cardetails">
                <CarDetailHeader car={car} customer={customer} selectedLocation={location} />
              </Grid.Column>
              <Grid.Column width={16} id="cardetailscrollsection-dmsnotes">
                <CarNotes car={car} />
              </Grid.Column>
              <Grid.Column width={16} id="cardetailscrollsection-snoozeditems">
                <CarSnoozed car={car} />
              </Grid.Column>
              <Grid.Column width={16} id="cardetailscrollsection-pinitems">
                <CarPins car={car} />
              </Grid.Column>
              <Grid.Column width={16} id="cardetailscrollsection-appointment_history">
                <AppointmentHistory appointmentHistory={appointmentHistory} isManufacturer={authState.user.role_id === ROLES.MANUFACTURER} />
              </Grid.Column>
              <Grid.Column width={16} id="cardetailscrollsection-drivingtrend">
                <CarDrivingTrend appointmentHistory={appointmentHistory} car_lead_prediction={car.car_lead_prediction} />
              </Grid.Column>
              <Grid.Column width={16} id="cardetailscrollsection-tyres">
                {car.is_truck ? (
                  <TruckTyres truck_tyres={this.truck_tyres} selectedLocation={location} />
                ) : (
                  <CarTyres
                    car={car}
                    selectedLocation={location}
                    lastAppointment={appointmentHistory?.length && appointmentHistory[0]}
                    updateSelectedCar={updateSelectedCar}
                  />
                )}
              </Grid.Column>
            </Grid>
          </Modal.Content>
        </Modal>
      </span>
    );
  }
}

const mapStateToProps = state => {
  return { authState: state.auth, globalState: state.global, carsState: state.cars };
};

const mapDispatchToProps = dispatch => {
  return {
    applyWebsocketUpdate: payload => dispatch(applyWebsocketUpdate(payload)),
    addCarAttachment: attachmentNote => dispatch(addCarAttachment(attachmentNote)),
    deleteCarAttachment: url => dispatch(deleteCarAttachment(url)),
    updateSelectedCar: update => dispatch(updateSelectedCar(update)),
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CarDetail));
