import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Grid, Modal, Button } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";

import { Can } from "../../modules";

import Service from "./service";

import "./PonRecallDetail.scss";

class PonRecallDetail extends Component {
  state = {
    isModalVisible: false,
    ponRecallData: null,
    errorMessage: null,
  };

  componentDidMount() {
    this.getPonRecallData();
  }

  getPonRecallData = () => {
    const { id: car_id } = this.props.car;
    const { id: location_id, is_pon_recall_enabled } = this.props.location;

    if (!is_pon_recall_enabled) return;

    Service.getPonRecall({ location_id, car_id })
      .then(res => {
        this.setState({ ponRecallData: res.data.data });
      })
      .catch(err => {
        console.error("Error getting PON data", err);
        const errorMessage = typeof err?.response?.data !== "string" ? err.response.data.errors[0] : "Failed to get PON data";
        this.setState({ errorMessage });
      });
  };

  handleToggleModal = () => {
    this.setState(({ isModalVisible }) => ({ isModalVisible: !isModalVisible }));
  };

  renderDetail() {
    const { isModalVisible, ponRecallData, errorMessage } = this.state;
    const { t } = this.props;

    if (!isModalVisible) return null;

    return (
      <Modal className="PonRecallDetail" closeOnEscape open={isModalVisible} onClose={this.handleToggleModal} closeOnDimmerClick={false} size="small">
        <Modal.Header>
          <div className="PonRecallDetail__header">
            {t("pon_recall").message || "PON Recall"}
            <div className="PonRecallDetail__header-actions">
              <Button color="green" onClick={this.getPonRecallData}>
                <FontAwesomeIcon icon={faSync} size="sm" />
              </Button>
              <Button color="green" onClick={this.handleToggleModal}>
                {t("close").message || "Close"}
              </Button>
            </div>
          </div>
        </Modal.Header>
        <Modal.Content scrolling>
          <div className="PonRecallDetail__content">
            {!ponRecallData && errorMessage && <div className="PonRecallDetail__error">{errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1)}</div>}

            {ponRecallData?.vehicleRef && (
              <Grid columns={2}>
                <Grid.Row>
                  <Grid.Column>
                    <strong>VIN</strong>
                    <span>{ponRecallData.vehicleRef.vin?.value || ""}</span>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            )}

            {ponRecallData?.serviceCampaigns?.serviceCampaign?.length > 0 && (
              <>
                <h3>{t("service_campaigns").message || "Service Campaigns"}</h3>
                {ponRecallData.serviceCampaigns.serviceCampaign.map((campaign, index) => (
                  <Grid columns={2} key={index} className="PONCampaign">
                    <Grid.Row>
                      <Grid.Column c>
                        <strong>{t("campaign_uid").message || "Campaign UID"}</strong>
                        <span>{campaign.serviceCampaignIdentifier?.serviceCampaignUID?.value || ""}</span>
                      </Grid.Column>
                      <Grid.Column>
                        <strong>{t("campaign_start_date").message || "Start Date"}</strong>
                        <span>{moment(campaign.startDate || "").format("DD-MM-YYYY")}</span>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <strong>{t("campaign_description").message || "Description"}</strong>
                        <span>{campaign.campaignDescription?.value || ""}</span>
                      </Grid.Column>
                      <Grid.Column>
                        <strong>{t("campaign_type").message || "Type"}</strong>
                        <span>{campaign.campaignType?.name || ""}</span>
                      </Grid.Column>
                    </Grid.Row>
                    {index < ponRecallData.serviceCampaigns.serviceCampaign.length - 1 && <div className="PONCampaign__separator"></div>}
                  </Grid>
                ))}
              </>
            )}
          </div>
        </Modal.Content>
      </Modal>
    );
  }

  render() {
    const { ponRecallData } = this.state;
    const { location } = this.props;

    if (!location.is_pon_recall_enabled) return null;

    return (
      <Can I="read" the="pon-recall">
        <Button basic fluid size="large" className={`pon-button ${ponRecallData ? "-red-border" : ""}`} onClick={this.handleToggleModal}>
          PON
        </Button>
        {this.renderDetail()}
      </Can>
    );
  }
}

export default withTranslation()(PonRecallDetail);
