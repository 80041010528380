import React, { Component } from "react";
import { GithubPicker, ChromePicker } from "react-color";

import "./ColorPicker.css";

class ColorPicker extends Component {
  state = {
    displayColorPicker: false,
  };

  handleClick = () => {
    this.setState(state => ({ displayColorPicker: !state.displayColorPicker }));
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChange = color => {
    this.props.onChange(color.hex);
  };

  listenToClick = () => {
    window.addEventListener(
      "click",
      event => {
        if (this.state.displayColorPicker) {
          const path = event.path || event.composedPath();
          if (!path.some(p => p.className === "popover")) this.setState({ displayColorPicker: false });
          else this.listenToClick();
        }
      },
      { capture: true, once: true, passive: true }
    );
  };

  render() {
    const { extraColors } = this.props;
    if (this.state.displayColorPicker) this.listenToClick();

    let colors = [
      "#e91e63",
      "#9c27b0",
      "#673ab7",
      "#3f51b5",
      "#2196f3",
      "#009688",
      "#4caf50",
      "#8bc34a",
      "#ffeb3b",
      "#ffc107",
      "#ff9800",
      "#ff5722",
      "#795548",
      "#607d8b",
      "#FFF",
      "#000",
    ];

    if (extraColors?.length) {
      const uniqueColors = [...new Set(extraColors.filter(c => !colors.includes(c)))];
      colors = colors.concat(uniqueColors);
    }

    return (
      <div className={this.props.className}>
        <div className="swatch" onClick={this.handleClick}>
          <div className="color" style={{ backgroundColor: this.props.color }} />
        </div>
        {this.state.displayColorPicker ? (
          <div className="popover">
            {!this.props.notPicker && <GithubPicker colors={colors} color={this.props.color} onChange={this.handleChange} />}
            {this.props.notPicker && <ChromePicker color={this.props.color} onChange={this.handleChange} />}
          </div>
        ) : null}
      </div>
    );
  }
}

export default ColorPicker;
