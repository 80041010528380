import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import AlertContainer from "react-alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";

import TourGuide from "../TourGuide/index";

import Service from "./service";

import "./NewsStripe.scss";

class NewsStripe extends Component {
  state = {
    unreadArticles: [],
    displayNewsStripe: false,
    isTourGuideOpen: false,
  };
  msg = React.createRef();
  appModuleElement = null;

  componentDidMount() {
    this.getNewsItem();

    this.appModuleElement = document.querySelector(".App__module");
  }

  componentDidUpdate(prevProps) {
    if (prevProps.globalState.selectedLocation.id !== this.props.globalState.selectedLocation.id) this.getNewsItem();
  }

  componentWillUnmount() {
    this.appModuleElement && this.appModuleElement.classList.remove("App_module_news_stripe_shown");
  }

  getNewsItem = () => {
    if (!this.props.globalState.selectedLocation?.id) return;

    if (this.props.globalState.selectedLocation?.id === "all") {
      this.setState({ unreadArticles: [], displayNewsStripe: false });
      return;
    }

    Service.getUnreadArticles({ dealer_location_id: this.props.globalState.selectedLocation.id, language_code: localStorage.getItem("locale") })
      .then(response => {
        if (response?.data?.data?.articles) {
          const unreadArticles = response.data.data.articles.sort((d1, d2) => d2.updated_on.localeCompare(d1.updated_on));
          unreadArticles?.length && this.appModuleElement && this.appModuleElement.classList.add("App_module_news_stripe_shown");

          this.setState({ unreadArticles, displayNewsStripe: !!unreadArticles?.length });
        }
      })
      .catch(error => {
        this.setState({ displayNewsStripe: false }, () => {
          const errorMsg =
            typeof error === "string"
              ? error
              : error.message
              ? error.message
              : error.response?.data?.errors?.length
              ? error.response.data.errors[0]
              : this.props.t("error_getting_news_article").message || "Error getting news article";
          this.msg.show(errorMsg, { type: "error" });
        });
        console.log(error);
      });
  };

  setNewsStripeClicked = article_opened => {
    Service.setNewsStripeClicked({
      article_id: this.state.unreadArticles[0].id,
      article_opened,
      language_code: localStorage.getItem("locale"),
      dealer_location_id: this.props.globalState.selectedLocation.id,
    });
    if (article_opened) this.setState({ displayNewsStripe: false, isTourGuideOpen: true });
    else this.setState({ displayNewsStripe: false, isTourGuideOpen: false });

    this.appModuleElement && this.appModuleElement.classList.remove("App_module_news_stripe_shown");
  };

  closeTourGuide = () => {
    this.setState({ isTourGuideOpen: false });
  };

  renderTourGuide = () => {
    const { unreadArticles, isTourGuideOpen } = this.state;

    if (!unreadArticles.length || !isTourGuideOpen) return null;

    return <TourGuide unreadArticles={unreadArticles} closeTourGuide={this.closeTourGuide} />;
  };

  closeNewsStripe = () => {
    this.setState({ unreadArticles: [], displayNewsStripe: false, isTourGuideOpen: false });
  };

  renderAlert = () => {
    const props = {
      offset: 20,
      position: "top right",
      theme: "light",
      time: 2000,
      transition: "fade",
    };
    return <AlertContainer ref={a => (this.msg = a)} {...props} />;
  };

  render() {
    return (
      <>
        {this.state.unreadArticles.length > 0 && this.state.displayNewsStripe && (
          <div className="NewsStripe">
            <div className="stripe">
              <div>
                <span>{this.state.unreadArticles[0].title}</span>{" "}
                <span
                  className="link"
                  onClick={() => {
                    this.setNewsStripeClicked(true);
                    this.setState({ isTourGuideOpen: true });
                  }}
                >
                  {this.props.t("learn_more").message || "Learn More"}...
                </span>
              </div>
              <div className="close" onClick={() => this.setNewsStripeClicked(false)}>
                <FontAwesomeIcon icon={faTimes} />
              </div>
            </div>
          </div>
        )}
        {this.renderAlert()}
        {this.renderTourGuide()}
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    globalState: state.global,
  };
};
export default withTranslation()(connect(mapStateToProps)(NewsStripe));
