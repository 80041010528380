import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button, Modal, Form, Icon } from "semantic-ui-react";

import { ACSES_COMMUNICATION_EVENT } from ".";
import { renderDynamicValuesTable } from "./common";
import { COMMUNICATION_EVENTS } from "../Appointments/common";
import { UserInput } from "../../components";

class SmsTemplate extends Component {
  state = {
    smsText: this.props.selectedMedium.message,
    carBrand: this.props.selectedMedium.car_brand,
    carModel: this.props.selectedMedium.car_model,
    validationError: false,
  };

  revertToDefault = () => {
    const { defaultTemplates, language_code, selectedMedium } = this.props;
    let defaultTemplate = defaultTemplates.find(
      temp => temp.language_code === language_code && temp.event_type === selectedMedium.event_type && temp.channel === selectedMedium.channel
    );

    if (!defaultTemplate) {
      defaultTemplate = {
        message: "default not found for medium type " + selectedMedium.event_type + ", channel " + selectedMedium.channel + " and language code " + language_code,
      };
    }

    this.setState({ smsText: defaultTemplate.message });
  };

  handleChangeSmsTxt = evt => this.setState({ smsText: evt.target.value });

  handleInputChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  submitSmsText = () => {
    const { smsText, carBrand, carModel } = this.state;

    if (this.props.selectedMedium.car_brand && !carBrand) {
      this.setState({ validationError: true });
      return;
    }

    this.props.handleSMSMediumUpdate({ smsText, carBrand, carModel });
  };

  render() {
    const { smsText, carBrand, carModel, validationError } = this.state;
    const { t, isSubmit, selectedMedium, handleCloseMedium, renderAlert, toggleDeleteConfirmation, renderDeleteConfirmation, settings } = this.props;

    const showKeylockerPlaceholders = COMMUNICATION_EVENTS.ONLINE_CHECKIN_ANSWERED === selectedMedium.event_type;
    const showParkingGatePlaceholder = selectedMedium.event_type === COMMUNICATION_EVENTS.ONLINE_CHECKIN_ANSWERED && settings.send_parking_gate_code;
    const showAcsesPlaceholders = ACSES_COMMUNICATION_EVENT.DROP_BACK === selectedMedium.acses_communication_event_type;

    return (
      <Modal open={true} onClose={handleCloseMedium} closeOnDimmerClick={false} size={"large"}>
        <Modal.Header>
          <h1 style={{ color: "#665e5e", display: "inline-block", margin: "0em" }}>{t("sms_editor").message || "SMS Editor"}</h1>
          <Button floated="right" color="yellow" onClick={this.revertToDefault}>
            {t("revert_to_default").message || "Revert to default"}
          </Button>
        </Modal.Header>
        <Modal.Content scrolling>
          {renderDynamicValuesTable(false, false, false, showKeylockerPlaceholders, showParkingGatePlaceholder, showAcsesPlaceholders, t)}
          <Form className="-margin-bottom-10 -margin-top-10">
            {selectedMedium.car_brand && (
              <>
                <Form.Field required error={validationError && !carBrand}>
                  <label>{t("Make").message || "Make"}</label>
                  <UserInput name="carBrand" value={carBrand} placeholder={t("Make").message || "Make"} onChange={this.handleInputChange} />
                </Form.Field>

                <Form.Field>
                  <label>{t("Model").message || "Model"}</label>
                  <UserInput name="carModel" value={carModel} placeholder={t("Model").message || "Model"} onChange={this.handleInputChange} />
                </Form.Field>
              </>
            )}

            <Form.Field>
              <label>
                {t("sms_text").message || "SMS text"} ({smsText.length}/1600)
              </label>
              <textarea maxLength="1600" value={smsText} onChange={this.handleChangeSmsTxt}></textarea>
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          {selectedMedium.car_brand && (
            <Button color="red" loading={isSubmit} disabled={isSubmit} onClick={toggleDeleteConfirmation} floated="left">
              <Icon name="trash" className="-no-margin" />
            </Button>
          )}
          <Button floated="right" loading={isSubmit} color="green" onClick={this.submitSmsText}>
            {t("save").message || "Save"}
          </Button>
          <Button color="red" onClick={handleCloseMedium}>
            {t("close").message || "Close"}
          </Button>
        </Modal.Actions>
        {renderAlert()}
        {renderDeleteConfirmation()}
      </Modal>
    );
  }
}

export default withTranslation()(SmsTemplate);
