import React, { Component } from "react";
import { Segment, Icon, Popup } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import moment from "moment";

import { getStatusDetail } from "./util";
import { APPOINTMENT_STATUSES, CHANNELS, CHECK_EVENT_TYPE, COMMUNICATION_EVENTS, COMMUNICATION_RECEIVER_STATUSES } from "./common";
import AppService from "../App/service";
import * as Scroll from "../../lib/react-scroll";

const ScrollLink = Scroll.ScrollLink(Segment);

class AppointmentStatusTimeline extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scrollTargeting: null,
      scrollAtBottom: false,
      scrollContainer: {},
      location: this.props.location,
      isLoadingLocation: false,
    };

    this.ticking = false;
  }

  componentDidMount = () => {
    this.setState({ scrollContainer: document.getElementById("appointmentDetailContentScrollContainer") }, () => {
      this.state.scrollContainer.addEventListener("scroll", () => {
        if (!this.ticking) window.requestAnimationFrame(this.handleScroll);

        this.ticking = true;
      });
    });

    if (!this.state.location.statuses) {
      this.setState({ isLoadingLocation: true }, () => {
        AppService.getLocation({ id: this.state.location.id })
          .then(r => {
            this.setState({ location: r.data.data, isLoadingLocation: false }, () => {});
          })
          .catch(error => {
            this.setState({ isLoadingLocation: false });
            console.log("Failed to load location:", error);
          });
      });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const scrollAtBottom = this.state.scrollContainer.scrollHeight - this.state.scrollContainer.offsetHeight === this.state.scrollContainer.scrollTop;

    if (scrollAtBottom) this.setState({ scrollAtBottom });
    else if (this.state.scrollAtBottom) this.setState({ scrollAtBottom: false, scrollTargeting: null });

    this.ticking = false;
  };

  renderStatusText = (status, channel, commType) => {
    const { t } = this.props;

    const isCustomerConfirmation = [COMMUNICATION_EVENTS.ONLINE_CHECKIN_ANSWERED, COMMUNICATION_EVENTS.DIAGNOSE_OVERVIEW_ANSWERED].includes(commType);

    if (channel === CHANNELS.EMAIL) {
      switch (status) {
        case COMMUNICATION_RECEIVER_STATUSES.PENDING_STATUS:
          if (isCustomerConfirmation) return t("confirmation_email_pending").message || "Confirmation email is pending";
          return t("email_pending").message || "Email is pending";

        case COMMUNICATION_RECEIVER_STATUSES.SUCCESS_STATUS:
          if (isCustomerConfirmation) return t("confirmation_email_delivered").message || "Confirmation email is delivered";
          return t("email_delivered").message || "Email is delivered";

        case COMMUNICATION_RECEIVER_STATUSES.FAILED_STATUS:
          if (isCustomerConfirmation) return t("confirmation_email_not_delivered").message || "Confirmation email failed to be delivered";
          return t("email_not_delivered").message || "Email failed to be delivered";

        default:
          return "";
      }
    } else if (channel === CHANNELS.SMS) {
      switch (status) {
        case COMMUNICATION_RECEIVER_STATUSES.PENDING_STATUS:
          if (isCustomerConfirmation) return t("confirmation_sms_pending").message || "Confirmation SMS is pending";
          return t("sms_pending").message || "SMS is pending";

        case COMMUNICATION_RECEIVER_STATUSES.SUCCESS_STATUS:
          if (isCustomerConfirmation) return t("confirmation_sms_delivered").message || "Confirmation SMS is delivered";
          return t("sms_delivered").message || "SMS is delivered";

        case COMMUNICATION_RECEIVER_STATUSES.FAILED_STATUS:
          if (isCustomerConfirmation) return t("confirmation_sms_not_delivered").message || "Confirmation SMS failed to be delivered";
          return t("sms_not_delivered").message || "SMS failed to be delivered";

        default:
          return "";
      }
    } else if (channel === CHANNELS.WEBHOOK) {
      switch (status) {
        case COMMUNICATION_RECEIVER_STATUSES.PENDING_STATUS:
          return t("webhook_pending").message || "Webhook is pending";
        case COMMUNICATION_RECEIVER_STATUSES.SUCCESS_STATUS:
          return t("webhook_succeeded").message || "Webhook succeeded";
        case COMMUNICATION_RECEIVER_STATUSES.FAILED_STATUS:
          return t("webhook_failed").message || "Webhook failed";
        default:
          return "";
      }
    }

    return "";
  };

  renderStatusSegmentInfo = (statusName, time, historyItem, checkUsername) => {
    const { customer, sa_remarks, receivers, box_number, commType } = historyItem;
    const { t } = this.props;

    const events = [];
    if (historyItem.check_events)
      for (const ce of historyItem.check_events) {
        const div = [];
        if (ce.check_index) div.push("#" + ce.check_index);
        if (ce.created_on) div.push(moment(ce.created_on).format("HH:mm"));
        if (ce.checklist_name) div.push(ce.checklist_name);
        div.push(this.getCheckEventTypeName(ce.type));
        if (ce.user_full_name) div.push(ce.user_full_name);

        events.push(<div style={{ fontWeight: 400 }}>{div.join(" - ")}</div>);
      }

    if (events.length > 0) events.unshift(<br />);

    return (
      <>
        <div className="status-segment-info-time">
          {time} {statusName}
          {!events.length && (
            <div style={{ fontWeight: 400 }}>
              {historyItem.identifier === APPOINTMENT_STATUSES.CANCELED && historyItem.is_dms
                ? t("canceled_by_dms").message || "Canceled by DMS"
                : checkUsername || historyItem.last_user}
            </div>
          )}
          {events.length > 0 && events}
        </div>
        {customer && <div className="status-segment-info-name">{`${customer.title} ${customer.firstname} ${customer.surname}`.trim()}</div>}
        {sa_remarks && <div className="AppointmentStatusTimeline__remark">{this.props.t(sa_remarks).message || sa_remarks}</div>}
        {box_number && (
          <div className="AppointmentStatusTimeline__remark">
            {this.props.t("box_nr").message || "Box #"} {box_number}
          </div>
        )}
        {receivers && <br />}
        {receivers?.map((receiver, i) => (
          <div key={i}>
            {this.renderIcon(receiver, 1)} <span className="AppointmentStatusTimeline__remark">{this.renderStatusText(receiver.status, receiver.channel, commType)}</span>{" "}
            {receiver.channel !== CHANNELS.WEBHOOK && <span className="status-segment-info-receiver">{receiver.destination}</span>}
            {receiver.reason && <div className="status-segment-info-receiver -margin-left-5">{receiver.reason}</div>}
          </div>
        ))}
      </>
    );
  };

  renderIcon = (r, i) => {
    if (r.channel === 2 && r.status !== 3) return null;

    switch (r.status) {
      case 1:
        return <Icon key={i} name="hourglass outline" />;

      case 2:
        return <Icon key={i} name="check square outline" />;

      case 3:
        return <Icon key={i} name="times circle outline" />;

      default:
        return null;
    }
  };

  renderIcons = item => {
    if (item.receivers?.length > 0) {
      return item.receivers.map((r, i) => this.renderIcon(r, i));
    } else if (item.sa_remarks) {
      return <Icon name="warning sign" />;
    }

    return "";
  };

  renderTriggerContent = ({ statusColor, historyItem, date, time, checkNumber, checkUsername }) => {
    const username = checkUsername || historyItem.last_user;

    const triggerContent = (
      <>
        {this.renderIcons(historyItem, 1)}
        <div className="AppointmentStatusTimeline__time -text-ellipsis">
          <strong>
            <span className="-date">[{date}]</span> {time}
          </strong>
          {historyItem.identifier === APPOINTMENT_STATUSES.CANCELED && historyItem.is_dms ? (
            <span>{` ${this.props.t("canceled_by_dms").message || "Canceled by DMS"}`}</span>
          ) : (
            username && <span>{` ${username}`}</span>
          )}
        </div>
      </>
    );

    if (checkNumber)
      return (
        <ScrollLink
          style={{ backgroundColor: statusColor, cursor: "pointer" }}
          containerId={"appointmentDetailContentScrollContainer"}
          spy={true}
          smooth={true}
          duration={500}
          offset={30}
          to={`check-scroll-id-${checkNumber}`}
          onClick={() => this.setState({ scrollTargeting: `check-scroll-id-${checkNumber}` })}
        >
          {triggerContent}
        </ScrollLink>
      );

    return <Segment style={{ backgroundColor: statusColor }}>{triggerContent}</Segment>;
  };

  getCheckEventTypeName = type => {
    const baseString = this.props.t("check").message || "Check";

    switch (type) {
      case CHECK_EVENT_TYPE.STARTED:
        return `${baseString} ${this.props.t("started").message || "Started"}`;
      case CHECK_EVENT_TYPE.PAUSED:
        return `${baseString} ${this.props.t("paused").message || "Paused"}`;
      case CHECK_EVENT_TYPE.RESUMED:
        return `${baseString} ${this.props.t("resumed").message || "Resumed"}`;
      case CHECK_EVENT_TYPE.ABORTED:
        return `${baseString} ${this.props.t("aborted").message || "Aborted"}`;
      case CHECK_EVENT_TYPE.COMPLETED:
        return `${baseString} ${this.props.t("completed").message || "Completed"}`;
      case CHECK_EVENT_TYPE.DELETED:
        return `${baseString} ${this.props.t("deleted").message || "Deleted"}`;
      default:
        return baseString;
    }
  };

  render() {
    let displayMode = "-wide";
    if (this.props.history.length > 5) {
      displayMode = "-narrow";
    }

    const { location } = this.state;
    const { history, checks } = this.props;

    return (
      <Segment.Group horizontal className={`AppointmentStatusTimeline ${displayMode}`}>
        {location &&
          history.map((historyItem, k) => {
            const status = getStatusDetail(historyItem.identifier, location.statuses);
            const date = moment(historyItem.created_on).format("DD.MM.YY");
            const time = moment(historyItem.created_on).format("HH:mm");
            let checkNumber = null;

            if (historyItem.check_events)
              for (const ce of historyItem.check_events) {
                if (ce.type === CHECK_EVENT_TYPE.COMPLETED) {
                  ce.check_index = checks?.find(check => check.id === ce.check_id)?._check_index;
                  checkNumber = ce.check_index;
                }
              }

            return (
              <>
                <Popup
                  key={k}
                  basic
                  style={{ backgroundColor: status.color, width: "100%", minWidth: "fit-content", marginLeft: "20px" }}
                  content={this.renderStatusSegmentInfo(status.name, time, historyItem)}
                  trigger={this.renderTriggerContent({ statusColor: status.color, historyItem, date, time, checkNumber })}
                />
              </>
            );
          })}
        {!location.statuses && this.state.isLoadingLocation && <Segment>Loading</Segment>}
        {!location && !this.state.isLoadingLocation && <Segment>Cannot load statuses</Segment>}
      </Segment.Group>
    );
  }
}

export default withTranslation()(AppointmentStatusTimeline);
