import axios from "axios";

import ENV from "../../util/env-config";
import { getSessionCookie } from "../../util/common";

class Service {
  static requestConfig() {
    return {
      headers: {
        Authorization: `Bearer ${getSessionCookie()}`,
        Pragma: "no-cache",
      },
    };
  }

  static getRoles() {
    return axios.get(ENV.authBaseUrl + "/acl/roles", this.requestConfig());
  }

  static getAcl() {
    return axios.get(ENV.authBaseUrl + "/acl/list", this.requestConfig());
  }
}

export default Service;
