import React from "react";
import { withTranslation } from "react-i18next";
import { Grid, Icon, Label } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/pro-solid-svg-icons";

import { round2 } from "../../util/common";
import { INTERVENTION_ELEMENT_STATUS } from "./common";
import { renderInterventionElementType } from "./util";
import { faArrowToRight } from "@fortawesome/pro-light-svg-icons";

const InterventionElements = ({ intervention, includeVAT, vat, elements, dms_price_enabled }) => {
  return (
    <div className="intervention-element-container">
      <Grid>
        {elements.map(
          el =>
            el.status !== INTERVENTION_ELEMENT_STATUS.DELETED && (
              <Grid.Row className="intervention-elements">
                <Grid.Column key={el.id} computer={includeVAT ? 10 : 12} widescreen={includeVAT ? 11 : 12}>
                  <div className="intervention-element-description">
                    {el.parent_dms_nr && (
                      <div className="arrow-to-right">
                        <FontAwesomeIcon icon={faArrowToRight} />
                      </div>
                    )}
                    <span className="intervention-element-icon -margin-right-10">{renderInterventionElementType(el.element_type)}</span>
                    {el.status === INTERVENTION_ELEMENT_STATUS.ON_HOLD && (
                      <Label className="intervention-element-icon">
                        <FontAwesomeIcon icon={faClock} />
                      </Label>
                    )}
                    <div className="intervention-element-text">{el.description}</div>
                  </div>
                </Grid.Column>

                <Grid.Column width={1} className="intervention-element-quantity-container">
                  <div>
                    <strong className="-margin-right-5">{el.quantity}</strong>
                  </div>
                </Grid.Column>

                {dms_price_enabled && (
                  <>
                    <Grid.Column width={1} className="price">
                      <div>
                        <strong className="-margin-right-5">{el.price != null && round2(el.price).toFixed(2)}</strong>
                      </div>
                    </Grid.Column>

                    {includeVAT && (
                      <Grid.Column computer={3} widescreen={2} className="price">
                        <div>
                          <strong className="-margin-right-5">{el.price != null && round2(el.price * (1 + vat / 100)).toFixed(2)}</strong>
                        </div>
                      </Grid.Column>
                    )}
                  </>
                )}
              </Grid.Row>
            )
        )}
        {intervention.internal_note && (
          <Grid.Row className="intervention-elements">
            <Grid.Column>
              <div className="intervention-element-description">
                <span className="intervention-element-icon -margin-right-10">
                  <Label className="intervention-internal-note">
                    <Icon name="desktop" /> DMS
                  </Label>
                </span>
                <div className="intervention-element-text">{intervention.internal_note}</div>
              </div>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </div>
  );
};

export default withTranslation()(InterventionElements);
