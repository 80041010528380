import React, { Component } from "react";
import { Form, Checkbox, Header, Icon, Segment } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

import { APPOINTMENT_NOTE_TYPES } from "../../modules/Appointments/util";

class AppointmentNotesConfiguration extends Component {
  handleCheckboxChange = (_e, { value, checked }) => {
    const { location, onLocationChange } = this.props;

    const enabled_appointment_note_type_ids = checked
      ? [...location.enabled_appointment_note_type_ids, value]
      : location.enabled_appointment_note_type_ids.filter(id => id !== value);

    onLocationChange({
      ...location,
      enabled_appointment_note_type_ids,
    });
  };

  render() {
    const { location, t } = this.props;

    const noteTypeOptions = Object.keys(APPOINTMENT_NOTE_TYPES)
      .filter(
        key =>
          APPOINTMENT_NOTE_TYPES[key] > APPOINTMENT_NOTE_TYPES.CALL_CUSTOMER &&
          ![APPOINTMENT_NOTE_TYPES.DMS, APPOINTMENT_NOTE_TYPES.TIRESCANNER].includes(APPOINTMENT_NOTE_TYPES[key])
      )
      .map(key => {
        const value = APPOINTMENT_NOTE_TYPES[key];
        const text = key.toString().toLowerCase();

        return { text: t(text).message || text.replaceAll("_", " "), value, key: value };
      });

    return (
      <Segment>
        <Header as="h5">
          <Icon name="note sticky" />
          <Header.Content>{t("additional_appointment_note_types").message || "Additional Appointment Note Types"}</Header.Content>
        </Header>
        <Segment>
          <Form>
            {noteTypeOptions.map(option => (
              <Form.Field key={option.key} className="full-width-input">
                <Checkbox
                  value={option.key}
                  checked={location.enabled_appointment_note_type_ids?.includes(option.value)}
                  onChange={this.handleCheckboxChange}
                  label={option.text}
                />
              </Form.Field>
            ))}
          </Form>
        </Segment>
      </Segment>
    );
  }
}

export default withTranslation()(AppointmentNotesConfiguration);
