import React, { Component } from "react";
import { Grid, Icon } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import { SubHeader, UserMenuActionsPortal } from "../../components";
import Service from "./service";
import { setAlert } from "../App/store";
import TireModal from "./TireModal";
import TireTable from "./TireTable";

const newTire = {
  id: "",
  manufacturer: "",
  description: "",
  carry_capacity: 0,
  width: 0,
  height: 0,
  size: 0,
  speed: "",
  season: 0,
  ean: "",
  run_flat_tire: false,
};

class IncompleteTires extends Component {
  state = {
    tire: {},
    tires: [],
    isModalVisible: false,
    isCompletingTire: false,
    isReplacingTire: false,
    isLoadingTires: false,
    errorMessage: "",
    tireBrands: [],
  };

  componentDidMount() {
    this.getListOfTires();
    this.getTireBrands();
  }

  getListOfTires = () => {
    this.setState({ isLoadingTires: true }, () => {
      Service.getTires()
        .then(response => {
          const tires = response?.data?.data?.tyres || [];
          this.setState({ tires, isLoadingTires: false });
        })
        .catch(err => {
          this.props.setAlert({ type: "error", title: err.message });
          console.error("Error getting tires.", err);
          this.setState({ isLoadingTires: false });
        });
    });
  };

  getTireBrands = () => {
    Service.getTireBrands()
      .then(response => {
        const tireBrands = response?.data?.data?.brands?.map(brand => ({ key: brand, value: brand, text: brand })) || [];

        this.setState({ tireBrands, isLoadingBrands: false });
      })
      .catch(err => {
        console.error("Error getting tires.", err);
        this.props.setAlert({ type: "error", title: err.message });
      });
  };

  handleCompleteTire = () => {
    const { tire, tires, isLoadingTires, isCompletingTire, isReplacingTire } = this.state;

    if (!isLoadingTires && !isReplacingTire && !isCompletingTire) {
      this.setState({ isCompletingTire: true, errorMessage: "" }, () => {
        Service.completeTire(tire, tire.id)
          .then(response => {
            const filteredTires = tires.filter(t => t.id !== tire.id);
            this.setState({ tires: filteredTires, tire: newTire, isCompletingTire: false, isTireDetailVisible: false });
          })
          .catch(err => {
            const errorMessage =
              typeof err === "string" ? err : err.message ? err.message : err.response?.data?.errors?.length ? err.response.data.errors[0] : "Error updating tire";

            console.error(errorMessage);
            this.setState({ isCompletingTire: false, errorMessage }, () => {
              this.props.setAlert({ type: "error", title: errorMessage });
            });
          });
      });
    }
  };

  handleReplaceTire = completeTireId => {
    const { tire, tires, isLoadingTires, isReplacingTire, isCompletingTire } = this.state;

    if (!isLoadingTires && !isReplacingTire && !isCompletingTire) {
      this.setState({ isReplacingTire: true }, () => {
        Service.replaceTire(completeTireId, tire.id)
          .then(response => {
            const filteredTires = tires.filter(t => t.id !== tire.id);
            this.setState({ tires: filteredTires, tire: newTire, isReplacingTire: false, isTireDetailVisible: false });
          })
          .catch(err => {
            const errorMessage =
              typeof err === "string" ? err : err.message ? err.message : err.response?.data?.errors?.length ? err.response.data.errors[0] : "Error replacing tire";
            console.error(errorMessage);
            this.setState({ isReplacingTire: false, errorMessage }, () => {
              this.props.setAlert({ type: "error", title: errorMessage });
            });
          });
      });
    }
  };

  handleDeleteTire = id => {
    const { tires } = this.state;
    const removedTires = tires.filter(tire => tire.id !== id);

    this.setState({ tires: removedTires });
  };

  handleTireChange = tire => {
    this.setState({ tire });
  };

  handleShowTireDetail = ({ selectedTire }) => {
    this.setState({ isTireDetailVisible: true, tire: { ...selectedTire }, errorMessage: "" });
  };

  handleCloseTireDetail = () => {
    this.setState({ isTireDetailVisible: false, tire: newTire, errorMessage: "" });
  };

  renderTiresTable = () => {
    const { tires, isLoadingTires } = this.state;
    const { t } = this.props;

    if (isLoadingTires) {
      return (
        <div className="Table__loading Loader-Placeholder">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
          <section>{t("loading_tires").message || "Loading tires"}</section>
        </div>
      );
    } else {
      return <TireTable tires={tires} onRowClick={this.handleShowTireDetail} />;
    }
  };

  render() {
    const { tire, isTireDetailVisible, isCompletingTire, errorMessage, tireBrands } = this.state;
    const { t, setAlert } = this.props;

    return (
      <div>
        <UserMenuActionsPortal>
          <Icon name="refresh" onClick={this.getListOfTires} />
        </UserMenuActionsPortal>

        <SubHeader>
          <Grid.Column>
            <h1>{t("incomplete_tires").message || "Incomplete tires"}</h1>
            <span className="SubHeader_content_total_tires">
              {this.props.t("total").message || "Total"}: {this.state.tires.length}
            </span>
          </Grid.Column>
        </SubHeader>

        {this.renderTiresTable()}

        {isTireDetailVisible && (
          <TireModal
            tire={tire}
            isOpen={isTireDetailVisible}
            onDelete={this.handleDeleteTire}
            onComplete={this.handleCompleteTire}
            onReplace={this.handleReplaceTire}
            onClose={this.handleCloseTireDetail}
            handleChange={this.handleTireChange}
            isLoading={isCompletingTire}
            errorMessage={errorMessage}
            setAlert={setAlert}
            manufacturersList={tireBrands}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    setAlert: alertOptions => dispatch(setAlert(alertOptions)),
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(IncompleteTires));
